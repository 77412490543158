import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FrontendService } from '../../../../services/frontend.service';
import { ArticleGroup } from '../../../../../_classes/ArticleGroup';
import { Prices } from '../../../../../_classes/Prices';
import { CartService } from '../../../../services/cart.service';
import { MainService } from '../../../../services/main.service';
import { MatSnackBar } from '@angular/material';
import { PageScrollService } from 'ngx-page-scroll-core';
var AddArticleComponent = /** @class */ (function () {
    // constructor   - - - - - - - - - - - - - - - -
    function AddArticleComponent(frontend_service, cart_service, main_service, snackBar, pageScrollService, document) {
        this.frontend_service = frontend_service;
        this.cart_service = cart_service;
        this.main_service = main_service;
        this.snackBar = snackBar;
        this.pageScrollService = pageScrollService;
        this.document = document;
        // public stuff  - - - - - - - - - - - - - - - -
        this.men_article_list = [];
        this.show_tab = 0;
        this.cart_item_amount = [];
        this.tempAddedArticlesEmitter = new EventEmitter();
        this.tempRemovedArticlesEmitter = new EventEmitter();
        // private stuff - - - - - - - - - - - - - - - -
        this.subscriptions = [];
        this.cart_items = []; // all current articles in the cart
        /* show the info box for the ingredient */
        this.showInfoBox = false;
    }
    Object.defineProperty(AddArticleComponent.prototype, "artForMenu", {
        set: function (artForMenu) { this.men_article_list = artForMenu; this.initCartItemAmount(); },
        enumerable: true,
        configurable: true
    });
    ;
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    AddArticleComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.initCartItemAmount();
        // if (typeof this.articlesForMenu != undefined) {
        //     this.men_article_list = this.articlesForMenu;
        //     this.initCartItemAmount();
        // } else {
        //     this.subscriptions.push(this.frontend_service.getArticleForMenu().subscribe(
        //         (data: any[]) => {
        //             this.men_article_list = data;
        //             this.initCartItemAmount();
        //         }));
        // }
        this.subscriptions.push(this.cart_service.article_group_list_observable.subscribe(function (data) {
            _this.cart_items = data;
        }));
        this.subscriptions.push(this.cart_service.changedTempArticlesObservable.subscribe(function () {
            _this.initCartItemAmount();
        }));
        this.image_url = this.main_service.image_url;
        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767) {
        //     setTimeout(function () {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500);
        // }
    };
    AddArticleComponent.prototype.ngOnDestroy = function () {
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    AddArticleComponent.prototype.onLog = function () {
    };
    AddArticleComponent.prototype.onSub = function (index_cat, index_article) {
        // find the index
        var index = -1;
        for (var i = 0; i < this.cart_items.length; i++) {
            if (this.cart_items[i].article.id == this.men_article_list[index_cat].article[index_article].id) {
                index = i;
                break;
            }
        }
        if (index == -1 || index >= this.cart_items.length) {
            console.error('Article not Found.');
        }
        this.cart_service.decreseAmount(index);
        this.cart_item_amount[index_cat][index_article]--;
        // show overlay for item
        document.getElementById('item-removed-' + index_article).classList.remove('item-removed-hidden');
        setTimeout(function () {
            document.getElementById('item-removed-' + index_article).classList.add('item-removed-hidden');
        }, 1000);
    };
    AddArticleComponent.prototype.onAdd = function (index_cat, index_article, index_size) {
        var pickupPrice = this.men_article_list[index_cat].article[index_article].price[index_size].price - this.men_article_list[index_cat].article[index_article].price[index_size].pickup_saving;
        var price = new Prices(this.men_article_list[index_cat].article[index_article].price[index_size].price, this.men_article_list[index_cat].article[index_article].price[index_size].happy_hour_price, this.men_article_list[index_cat].article[index_article].price[index_size].lunch_price, pickupPrice, 0, this.men_article_list[index_cat].article[index_article].price[index_size].mega_deal_price, 0);
        var new_group = new ArticleGroup(this.men_article_list[index_cat].article[index_article], price, index_size, -1, []);
        this.cart_service.addNewSingleArticleGroup(new_group);
        this.openSnackBar('in den Warenkorb gelegt: 1x ', this.men_article_list[index_cat].article[index_article].name);
        this.cart_item_amount[index_cat][index_article]++;
        // show overlay for item
        document.getElementById('item-added-' + index_article).classList.remove('item-added-hidden');
        setTimeout(function () {
            document.getElementById('item-added-' + index_article).classList.add('item-added-hidden');
        }, 1000);
    };
    AddArticleComponent.prototype.onAddTempArticle = function (article, index) {
        // emit values to parent component
        this.tempAddedArticlesEmitter.emit({ article: article, index: index });
        this.initCartItemAmount();
        // show overlay for item
        document.getElementById('item-added-' + index).classList.remove('item-added-hidden');
        setTimeout(function () {
            document.getElementById('item-added-' + index).classList.add('item-added-hidden');
        }, 1000);
        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // /*let target = ".article-price-wrap";*/
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767) {
        //     setTimeout( function() {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500 )
        // }
    };
    AddArticleComponent.prototype.onRemoveTempArticle = function (index, categoryID) {
        // emit values to parent component
        this.tempRemovedArticlesEmitter.emit({ index: index, categoryID: categoryID });
        this.initCartItemAmount();
        // show overlay for item
        document.getElementById('item-removed-' + index).classList.remove('item-removed-hidden');
        setTimeout(function () {
            document.getElementById('item-removed-' + index).classList.add('item-removed-hidden');
        }, 1000);
    };
    AddArticleComponent.prototype.onOpenInfoBox = function (id) {
        var _this = this;
        /* request current article form api */
        this.frontend_service.getProductByID(id).then(function (data) {
            /* fill in variables */
            _this.infoBoxIngredient = data;
            // CODE HERE...
            /* open popup with info */
            _this.showInfoBox = true;
        });
    };
    AddArticleComponent.prototype.subIngIsDisabled = function (i_cat, i_ing) {
        return false;
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    AddArticleComponent.prototype.initCartItemAmount = function () {
        // delete all cartitems with added ings, cause they are not relevant
        // const cart_items = this.cart_items;
        var cart_items = this.addedArticles;
        // iterate through men_article categories
        var newList = [];
        for (var i_men_art_cat = 0; i_men_art_cat < this.men_article_list.length; i_men_art_cat++) {
            if (typeof this.men_article_list[i_men_art_cat].article === 'undefined' || !this.men_article_list[i_men_art_cat].article[0]) {
                continue;
            }
            // iterate through articles of article category
            this.cart_item_amount[i_men_art_cat] = [];
            for (var i_men_art = 0; i_men_art < this.men_article_list[i_men_art_cat].article.length; i_men_art++) {
                var no_match = true;
                // ind matching cart_item + save amount
                for (var i_cart_art = 0; i_cart_art < cart_items.length; i_cart_art++) {
                    var men_art_id = this.men_article_list[i_men_art_cat].article[i_men_art].id;
                    var cart_art_id = cart_items[i_cart_art].id;
                    if (men_art_id == cart_art_id) {
                        this.cart_item_amount[i_men_art_cat][i_men_art] += 1;
                        no_match = false;
                    }
                }
                // if no math -> amount = 0
                if (no_match) {
                    this.cart_item_amount[i_men_art_cat][i_men_art] = 0;
                }
            }
        }
    };
    AddArticleComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 2000
        });
    };
    AddArticleComponent.prototype.positionAbfrage = function () {
        var header = this.document.getElementById('header');
        /*console.log("HEADER", header);*/
        var hoehe = header.clientHeight;
        return hoehe;
    };
    return AddArticleComponent;
}());
export { AddArticleComponent };
