<!--<button class="log-button" (click)="onLog()">log</button>-->
<ng-container *ngIf="!ready">
    <div class="text-center color-primary margin">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>
<ng-container *ngIf="ready">
    <div class="row category-img margin">
        <div class="col-12 headerBild-container d-flex flex-row flex-md-column p-0">
            <ng-container *ngIf="article_list[0]">
                <div class="header-bild-container mobile-hidden">
                    <picture>
                        <source *ngIf="article_list[0].master_cat_image.webp" media="(min-width: 120.5em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.webp[0].image}}"
                                type="image/webp">
                        <source *ngIf="article_list[0].master_cat_image.jpg" media="(min-width: 120.5em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.jpg[3].image}}">
                        <source *ngIf="article_list[0].master_cat_image.webp" media="(min-width: 75em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.webp[1].image}}"
                                type="image/webp">
                        <source *ngIf="article_list[0].master_cat_image.jpg" media="(min-width: 75em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.jpg[1].image}}">
                        <source *ngIf="article_list[0].master_cat_image.webp" media="(min-width: 36em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.webp[2].image}}"
                                type="image/webp">
                        <source *ngIf="article_list[0].master_cat_image.jpg" media="(min-width: 36em)"
                                srcset="{{image_url}}{{article_list[0].master_cat_image.jpg[2].image}}">
                        <source *ngIf="article_list[0].master_cat_image.jpg" srcset="{{image_url}}{{article_list[0].master_cat_image.jpg[3].image}}">
                        <img *ngIf="article_list[0].master_cat_image.jpg" src="{{image_url}}{{article_list[0].master_cat_image.jpg[1].image}}"
                             alt="{{master_cat_name}}">

                    </picture>
                </div>
                <div class="desktop-hidden mr-3" id="svg_icon" [innerHtml]="svg_icon|safeHtml"></div>

            </ng-container>
            <div class="category-headline-wrap " *ngIf="article_list[0]">
                <div class="categorie-title {{kategorie_class}}">
                    <h1 class="category-headline static-page-title">{{article_list[0].master_cat_name}}</h1></div>
            </div>
        </div>

    </div>
    <!--Suchleiste-->
    <div class="col-12 p-0 article-list-search mb-3 mt-md-3 mt-0">
        <div class="col-12 p-0">
            <form id="form-articlesearch">
                <label for="articlesearch" class="desktop-flex">
                    <svg-icon src="/assets/icons/icon_mag_glass.svg"
                              [svgStyle] = "{ 'width.px':30, 'height.px':30 }"
                              class="icon_mag_glass"></svg-icon>
                </label>
                <label for="articlesearch" class="mobile-flex">
                    <svg-icon src="/assets/icons/icon_mag_glass.svg"
                              [svgStyle] = "{ 'width.px':20, 'height.px':20 }"
                              class="icon_mag_glass"></svg-icon>
                </label>
                <input type="text" class="form-control" id="articlesearch" required [(ngModel)]="search_string"
                       name="searchbar" placeholder="{{master_cat_name | titlecase }} suchen">
            </form>
        </div>
    </div>
    <!--Producte-->
    <div class="article-list-row row" [ngClass]="{active: show == i_cat || (search_string.length > 0)}">
        <!--Auflistung der Aktionsartikel-->
        <ng-container *ngFor="let cat of article_list, let i_cat = index">
            <ng-container *ngFor="let article of cat.article, let i_art = index">
                <ng-container *ngIf="article.is_active">
                    <!-- for not lunch categories -->
                    <!-- <ng-container *ngIf="(!article.is_lunch_category) || (article.is_lunch_category && onLunch)"> -->
                    <ng-container *ngIf="true">
                        <!--article box-->
                        <div class="col-12 col-sm-6 col-l1700-4 col-xxxl-3 article-box-drinks article-box article-box-{{article.category_id}}"
                            *ngIf="article.name.toLowerCase().includes(search_string.toLowerCase()) || ( article.description && article.description.toLowerCase().includes(search_string.toLowerCase()))">
                            <div class="d-flex flex-column justify-content-between bg-black-dark h-100">
                                <!-- <div class="article-box-heading heading px-2 {{kategorie_class}}">
                                                            <h3 class="mp-font-large-yellow mt-2">{{article.name}}</h3>
                                                            <p *ngIf="article.description" class="mp-font-regular-white">
                                                                <span [innerHTML]="article.description"></span>
                                                            </p>
                                                            <p *ngIf="!article.description" class="mp-font-regular-white">
                                                                <ng-container *ngFor="let ing of article.ingredients, let last = last">
                                                                    {{ing.name}}
                                                                    <ng-container *ngFor="let marking of ing.markings, let last = last">
                                                                        <sup>{{marking.abbreviation}}</sup>
                                                                        <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="!last">,</ng-container>
                                                                </ng-container>
                                                            </p>
                                                        </div> -->
                                <div class="article-box-content flex-fill flex-column d-flex justify-content-between">
                                    <div class="w-100 text-center article-box-heading heading px-2 {{kategorie_class}} ">
                                        <h3 class="mp-font-large-yellow pl-4 mt-3">{{article.name}}</h3>
                                        <p *ngIf="article.description" class="mp-font-regular-white">
                                            <span [innerHTML]="article.description"></span>
                                        </p>
                                        <p *ngIf="!article.description" class="mp-font-regular-white">
                                            <ng-container *ngFor="let ing of article.ingredients, let last = last">
                                                {{ing.name}}
                                                <ng-container *ngFor="let marking of ing.markings, let last = last">
                                                    <sup>{{marking.abbreviation}}</sup>
                                                    <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="!last">,</ng-container>
                                            </ng-container>
                                        </p>
                                    </div>
                                    <span class="infobox-link" (click)="onOpenInfoBox(article.id)">
                                        <svg-icon src="assets/icons/icon_info-circle-solid-orange.svg"
                                            [svgStyle]="{ 'width.px':20,'fill':'currentColor' }"></svg-icon>
                                    </span>
                                    <picture *ngIf="article.image.webp">
                                        <source media="(min-width: 120.5em)" srcset="{{image_url}}{{article.image.webp[6].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 120.5em)" srcset="{{image_url}}{{article.image.png[6].image}}">
                                        <source media="(min-width: 80em)" srcset="{{image_url}}{{article.image.webp[2].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 80em)" srcset="{{image_url}}{{article.image.png[2].image}}">
                                        <source media="(min-width: 60em)" srcset="{{image_url}}{{article.image.webp[4].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 60em)" srcset="{{image_url}}{{article.image.png[4].image}}">
                                        <source media="(min-width: 48em)" srcset="{{image_url}}{{article.image.webp[1].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 48em)" srcset="{{image_url}}{{article.image.png[1].image}}">
                                        <source media="(min-width: 36em)" srcset="{{image_url}}{{article.image.webp[5].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 36em)" srcset="{{image_url}}{{article.image.png[5].image}}">
                                        <source media="(min-width: 30em)" srcset="{{image_url}}{{article.image.webp[3].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 30em)" srcset="{{image_url}}{{article.image.png[3].image}}">
                                        <source media="(min-width: 22.5em)" srcset="{{image_url}}{{article.image.webp[1].image}}"
                                            type="image/webp">
                                        <source media="(min-width: 22.5em)" srcset="{{image_url}}{{article.image.png[1].image}}">
                                        <source srcset="{{image_url}}{{article.image.webp[0].image}}" type="image/webp">
                                        <source srcset="{{image_url}}{{article.image.png[0].image}}">
                                        <img class="img-fluid mx-auto" src="{{image_url}}{{article.image.png[2].image}}" alt="">
                        
                                        <div class="is-new-flag" *ngIf="article.is_new && !article.haveRule">
                                            <svg width="70" height="25.496" viewBox="0 0 70 25.496">
                                                <g transform="translate(-200 -332)">
                                                    <path fill="#b70e0c" d="M261.463,332.045V332H200v25.5h61.463L270,344.771Z"
                                                        transform="translate(0 0)" />
                                                    <g transform="translate(212.687 337.616)">
                                                        <path fill="#fff"
                                                            d="M265.461,369.575h-3.838l-6.2-10.774h-.088q.185,2.854.185,4.072v6.7h-2.7V355.333h3.809L262.821,366h.068q-.146-2.777-.146-3.926v-6.741h2.718Z"
                                                            transform="translate(-252.827 -355.333)" />
                                                        <path fill="#fff"
                                                            d="M335.232,369.575h-8.2V355.333h8.2v2.474H330.05v3.127h4.822v2.474H330.05v3.672h5.182Z"
                                                            transform="translate(-310.811 -355.333)" />
                                                        <path fill="#fff"
                                                            d="M389.392,355.333v9.215a5.317,5.317,0,0,1-.706,2.767,4.6,4.6,0,0,1-2.041,1.821,7.3,7.3,0,0,1-3.156.633,6.042,6.042,0,0,1-4.266-1.408,4.992,4.992,0,0,1-1.52-3.853v-9.176h3.01v8.719a3.649,3.649,0,0,0,.662,2.415,2.752,2.752,0,0,0,2.192.77,2.693,2.693,0,0,0,2.148-.775,3.671,3.671,0,0,0,.667-2.431v-8.7Z"
                                                            transform="translate(-350.408 -355.333)" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                        
                                        <div class="is-Aktion-flag" *ngIf="article.haveRule">
                                            <div class="is-Aktion-flag-deko">
                                                <p class="deal">DEAL</p>
                                                <p class="deal-variable">{{ article.ruleName }}</p>
                                            </div>
                                        </div>
                                    </picture>
                                    <!-- <div *ngIf="!article.image.webp" style="height: 8rem; padding: .625rem;"></div> -->
                                </div>
                                <div class="article-box-cta article-box-btns-{{article.price.length}}">
                                    <ng-container *ngIf="(!article.is_lunch_category) || (article.is_lunch_category && onLunch)">
                                        <ng-container *ngFor="let price of article.real_price, let i_size = index">
                                            <ng-container *ngIf="cat.expandable == 1; then expandable else not_expandable"></ng-container>
                        
                                            <ng-template #expandable>
                                                <ng-container *ngIf="price.price > 0">
                                                    <a (click)="onLinkToDetail(i_cat, i_art, i_size)">
                                                        <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                                        {{price.size}} <br>
                                                        <span><svg-icon src="assets/icons/warenkorb_neu.svg"
                                                                class="d-flex icon mr-2"></svg-icon>{{price.price |
                                                            number: '1.2-5'}}&nbsp;€</span>
                                                    </a>
                                                </ng-container>
                                            </ng-template>
                                            <!--Falls Artikel nicht erweiterbar sind: in Warenkorb-->
                                            <ng-template #not_expandable>
                                                <ng-container *ngIf="price.price > 0">
                                                    <a (click)="show_dialog= true" (click)="onAddArticle(i_art, i_size, i_cat, cat.id)">
                                                        <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                                        {{price.size}} <br>
                                                        <span><svg-icon src="assets/icons/warenkorb_neu.svg"
                                                                class="d-flex icon icon--sm mr-2"></svg-icon>{{price.price | number: '1.2-5'}}
                                                            €</span>
                                                    </a>
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="article.is_lunch_category && !onLunch">
                                        <ng-container *ngFor="let price of article.real_price, let i_size = index">
                                            <ng-container *ngIf="price.price > 0">
                                                <a (click)="onOpenLunchInfoBox()">
                                                    <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                                    {{price.size}} <br>
                                                    <span><svg-icon src="assets/icons/warenkorb_neu.svg"
                                                            class="d-flex icon mr-2"></svg-icon>{{price.price |
                                                        number: '1.2-5'}}&nbsp;€</span>
                                                </a>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- for lunch categories -->
                    <!-- <ng-container *ngIf="article.is_lunch_category && !onLunch">
                        <div class="col-12 col-md-6 col-l1700-4 col-xxxl-3 article-box-drinks article-box article-box-{{article.category_id}}"
                             *ngIf="article.name.toLowerCase().includes(search_string.toLowerCase()) || ( article.description && article.description.toLowerCase().includes(search_string.toLowerCase()))">
                            <div class="d-flex flex-column bg-black-dark h-100">
                                <div class="article-box-heading heading px-2 {{kategorie_class}}">
                                    <h3 class="mp-font-large-yellow mt-2">{{article.name}}</h3>
                                </div>
                                <div class="article-box-content">
                                    <picture *ngIf="article.image.webp">
                                        <source media="(min-width: 120.5em)"
                                                srcset="{{image_url}}{{article.image.webp[6].image}}" type="image/webp">
                                        <source media="(min-width: 120.5em)"
                                                srcset="{{image_url}}{{article.image.png[6].image}}">
                                        <source media="(min-width: 80em)"
                                                srcset="{{image_url}}{{article.image.webp[2].image}}" type="image/webp">
                                        <source media="(min-width: 80em)"
                                                srcset="{{image_url}}{{article.image.png[2].image}}">
                                        <source media="(min-width: 60em)"
                                                srcset="{{image_url}}{{article.image.webp[4].image}}" type="image/webp">
                                        <source media="(min-width: 60em)"
                                                srcset="{{image_url}}{{article.image.png[4].image}}">
                                        <source media="(min-width: 48em)"
                                                srcset="{{image_url}}{{article.image.webp[1].image}}" type="image/webp">
                                        <source media="(min-width: 48em)"
                                                srcset="{{image_url}}{{article.image.png[1].image}}">
                                        <source media="(min-width: 36em)"
                                                srcset="{{image_url}}{{article.image.webp[5].image}}" type="image/webp">
                                        <source media="(min-width: 36em)"
                                                srcset="{{image_url}}{{article.image.png[5].image}}">
                                        <source media="(min-width: 30em)"
                                                srcset="{{image_url}}{{article.image.webp[3].image}}" type="image/webp">
                                        <source media="(min-width: 30em)"
                                                srcset="{{image_url}}{{article.image.png[3].image}}">
                                        <source media="(min-width: 22.5em)"
                                                srcset="{{image_url}}{{article.image.webp[1].image}}" type="image/webp">
                                        <source media="(min-width: 22.5em)"
                                                srcset="{{image_url}}{{article.image.png[1].image}}">
                                        <source srcset="{{image_url}}{{article.image.webp[0].image}}" type="image/webp">
                                        <source srcset="{{image_url}}{{article.image.png[0].image}}">
                                        <img class="img-fluid mx-auto" src="{{image_url}}{{article.image.png[2].image}}"
                                             alt="">
                                        <span class="infobox-link" (click)="onOpenInfoBox(article.id)">
                                                <svg-icon src="assets/icons/icon_info-circle-solid-orange.svg"
                                                          [svgStyle]="{ 'width.px':20,'fill':'currentColor' }"></svg-icon>
                                        </span>
                                        <div class="is-new-flag" *ngIf="article.is_new && !article.haveRule">
                                            <svg width="70" height="25.496" viewBox="0 0 70 25.496">
                                                <g transform="translate(-200 -332)">
                                                    <path fill="#b70e0c"
                                                          d="M261.463,332.045V332H200v25.5h61.463L270,344.771Z"
                                                          transform="translate(0 0)"/>
                                                    <g transform="translate(212.687 337.616)">
                                                        <path fill="#fff"
                                                              d="M265.461,369.575h-3.838l-6.2-10.774h-.088q.185,2.854.185,4.072v6.7h-2.7V355.333h3.809L262.821,366h.068q-.146-2.777-.146-3.926v-6.741h2.718Z"
                                                              transform="translate(-252.827 -355.333)"/>
                                                        <path fill="#fff"
                                                              d="M335.232,369.575h-8.2V355.333h8.2v2.474H330.05v3.127h4.822v2.474H330.05v3.672h5.182Z"
                                                              transform="translate(-310.811 -355.333)"/>
                                                        <path fill="#fff"
                                                              d="M389.392,355.333v9.215a5.317,5.317,0,0,1-.706,2.767,4.6,4.6,0,0,1-2.041,1.821,7.3,7.3,0,0,1-3.156.633,6.042,6.042,0,0,1-4.266-1.408,4.992,4.992,0,0,1-1.52-3.853v-9.176h3.01v8.719a3.649,3.649,0,0,0,.662,2.415,2.752,2.752,0,0,0,2.192.77,2.693,2.693,0,0,0,2.148-.775,3.671,3.671,0,0,0,.667-2.431v-8.7Z"
                                                              transform="translate(-350.408 -355.333)"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>

                                        <div class="is-Aktion-flag" *ngIf="article.haveRule">
                                            <div class="is-Aktion-flag-deko">
                                                <p class="deal">DEAL</p>
                                                <p class="deal-variable">{{ article.ruleName }}</p>
                                            </div>
                                        </div>
                                    </picture>
                                </div>
                                <div class="article-box-cta article-box-btns-{{article.price.length}}">
                                    <ng-container *ngFor="let price of article.real_price, let i_size = index">
                                        <ng-container *ngIf="price.price > 0">
                                            <a (click)="onOpenLunchInfoBox()">
                                                <ng-container *ngIf="price.diameter_sign">&empty;</ng-container>
                                                {{price.size}} <br>
                                                <span><svg-icon src="assets/icons/warenkorb_neu.svg"
                                                                class="d-flex icon mr-2"></svg-icon>{{price.price | number: '1.2-5'}}&nbsp;€</span>
                                            </a>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<!-- begin: lunch category popup -->
<ng-container *ngIf="showLunchInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <h2>Mittagstisch</h2>

            <p class="text-center">Bitte beachte, dass die Kategorie derzeit geschlossen ist.<br>
                Die Produkte sind nur in der Mittagstisch-Zeit aktiv.<br>
                Vielen Dank für dein Verständnis.
            </p>

            <div class="btn-mp-dialog text-center">
                <button class="mp-btn mp-btn-yellow-on-white mt-4" (click)="showLunchInfoBox = false">Ok</button>
            </div>
    </div>
    </div>
</ng-container>

<!-- begin: popup -->
<ng-container *ngIf="showInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <div class="title-mp-dialog">
                Allergene & Zusatzstoffe
            </div>
            <div class="cont-mp-dialog">
                <h3 class="mp-font-large-yellow">{{ infoBoxProduct.name }}</h3>
                <span [innerHTML]="infoBoxProduct.description"></span>
                <ng-container *ngIf="infoBoxProduct.description_addition">
                    <span class="description-addition" [innerHTML]="infoBoxProduct.description_addition"></span>
                </ng-container>
                <br><br>
                <h4>Zusatzstoffe:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Zusatzstoff'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>Allergene:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Allergen'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>BITTE BEACHTE:</h4>
                <p>Die Zusatzstoffe und Allergene können sich beim Hinzufügen oder Wechseln von Zutaten ändern.</p>
                <br>
                <div class="btn-mp-dialog text-center">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="showInfoBox = false">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="added-to-cart added-to-cart-hide">
    <div class="bg-shop-closed">
        <div class="dialog-added">
            <p class="added_header font-weight-normal text-center">
                Deine Artikel wurden in den Warenkorb gelegt.
            </p>
            <div class="col p-0">
                <p class="added_text text-center">Möchtest du zur Kasse oder weiter einkaufen?</p>
                <p class="d-flex justify-content-between font-weight-bolder">
                    <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn button-black" routerLink="/shop">Weiter einkaufen</button>
                    <button class="text-uppercase mp-btn-narrow-sm_pad mp-btn mp-btn-yellow-on-white" routerLink="/shop/bestellen/warenkorb">Zur Kasse</button>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- end: popup -->
