<!--<button class="log-button" (click)="onLog()">Log Dashboard Component</button>-->

<ng-container *ngIf="!ready">
    <div class="text-center color-primary">
        <svg-icon src="/assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>
<ng-container *ngIf="ready">
    <div class="container-full home-content-wrapper" id="home-content">
        <app-element-slider element="sliderDashboard"></app-element-slider>

        <app-element-categories element="categoriesDashboard"></app-element-categories>
<!--         <div class="row dashboard">
            <div class="col-md-4 mb-6 d-elem">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/pizza-party-mega-deal">
                    <picture>
                        <img src="/assets/img/MegaDeal/megaDeal_14.png"
                             class="img-fluid" alt="Meisterpizza MegaDeal">

                    </picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div *ngIf="storeData?.id === 7" class="col-md-4 mb-6 d-elem">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/mittags-angebot">
                    <picture>
                        <img
                                src="/assets/img/mittagsangebote/MittagsAngebot_750_750_c.png"
                                class="img-fluid" alt="Meisterpizza Mittags-Angebot">

                    </picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div *ngIf="storeData?.id === 2" class="col-md-4 mb-6 d-elem">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/newsletter">
                    <picture>
                        <img
                                src="/assets/img/Kategoriebilder_Startseite_750x750px_Newsletter.jpg"
                                class="img-fluid" alt="Meisterpizza Mittags-Angebot">

                    </picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 mb-6 text-center d-elem">
                <picture>
                    <img src="/assets/img/startseite/sparen.png"
                         class="img-fluid" alt="Meisterpizza Sparen">
                    src="/assets/img/startseite/MP-DD_Kategoriebilder_Startseite_1500x1500px_Sparen.jpg"
                    class="img-fluid" alt="">

                </picture>
            </div>
        </div> -->


        <!-- <div class="row boxes-home dashboard">
            <div class="col-md-4 box-news mb-6 d-elem">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/newsletter">
                    <figure class="mb-5 mb-xl-0">
                        <img src="/assets/img/icons/newsletter-icon_homepage.png" class="">
                    </figure>
                    <h4>Newsletter</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 box-jobs mb-6 d-elem">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/jobs">
                    <figure class="mb-5 mb-xl-0">
                        <img src="/assets/img/icons/jobs-icon_homepage.png" class="">
                    </figure>
                    <h4>Stellenangebote</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 box-partner mb-6 d-elem">
                <a *ngIf="storeData?.id === 7" class="mp-box-link d-block position-relative text-center"
                   routerLink="/shop/restaurant">
                    <figure class="mb-5 mb-xl-0">
                        <img src="/assets/img/icons/Button_Restaurant.png" class="">
                    </figure>
                    <h4>Restaurant</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
                <a *ngIf="storeData?.id === 2" href="https://dresden-meisterpizza.de/#/shop/restaurant"
                   class="mp-box-link d-block position-relative text-center">
                    <figure class="mb-5 mb-xl-0">
                        <img src="/assets/img/icons/Button_Restaurant.png" class="">
                    </figure>
                    <h4 id="restaurant">Restaurant in Dresden</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="/assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
        </div> -->
    </div>

</ng-container>
