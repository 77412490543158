<!--<button class="log-button" (click)="onLog()">Log Welcome Component</button>-->

<!-- begin: to top button -->
<!-- <div class="to-top-button show-mobile" (click)="toTop()" *ngIf="showUpButton">
    <button class="mp-btn-up"></button>
</div> -->
<!-- end: to top button -->

<!--<ng-container *ngIf="!ready">
    <div class="text-center color-primary preloader">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>-->

<!-- begin: page ready -->
<ng-container *ngIf="ready">
    <!-- begin: shop open -->
    <div id="wrapper" *ngIf="!storeData.is_closed" class="welcome-content-wrapper container-fluid">
        <!--begin: page content-->
        <div class="row mb-7 welcome-logo-step">
            <div class="col p-0">
                <div class="welcome-logo text-center ">

                    <a routerLink="/willkommen/start" class="logo-link">
                        <svg-icon src="{{logo}}" class="logo-desk"></svg-icon>
                    </a>

                    <h1 class="welcome-header text-center">{{ storeData.welcome_text }}</h1>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>


        <app-element-welcome element="welcome"></app-element-welcome>

        <div class="row feature-box mb-7">
            <div *ngFor="let content of storeData.content" class="col-md-6 col-xl-3 mb-4 mb-xl-0">
                <div class="text-component text-center">
                    <div class="feature-box-headline d-flex flex-column justify-content-start align-items-center mb-4">
                        <div class="svg-container">
                            <svg [innerHTML]="content.icon|safeHtml"></svg>
                        </div>
                        <h3 class="feature-box-h3">{{ content.title }}</h3>
                    </div>
                    <p class="text-white">{{ content.description }}</p>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
        <!-- end: page content -->
    </div>


    <div id="wrapper" *ngIf="storeData.is_closed" class="welcome-content-wrapper container-fluid">
        <!--begin: page content-->
        <div class="row">
            <div class="col">
                <div class="welcome-logo text-center">
                    <a routerLink="/willkommen/start">
                        <svg-icon src="{{logo}}" class="logo-desk"></svg-icon>
                    </a>
                </div>
                <h1 class="welcome-header text-center">Shop geschlossen</h1>
                <p class="text-center">
                    {{ storeData.is_closed_message }}
                </p>
                <app-footer></app-footer>
            </div>
        </div>
        <!-- end: page content -->
    </div>
    <!-- end: shop open -->

    <!-- end: shop is closed duw to maintenance or other issues -->
</ng-container>
<!-- end: page ready -->


<!--   TODO POPUP "Wir haben leider geschlossen... " -->
<!-- begin: popup shop currently closed -->
<ng-container *ngIf="shopClosedAlt && deliveryTypeSelected && ((!seenClosedPopupOnPickup && pickup) || (!seenClosedPopupOnDelivery && !pickup))">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <h2 class=" font-weight-bold text-center">Wir haben {{ textPopap }} geschlossen</h2>
            <p class="text-center mt-0 dialog-text">du kannst aber vorbestellen</p>

            <div class="btn-dialog-shop-closed">
                <button (click)="closePopup()"
                        class="mp-btn mp-btn-yellow-on-white">Jetzt vorbestellen
                </button>
            </div>
            <!-- <div class="cont-dialog-shop-closed pb-3">
                <div class="open-hours row p-0">
                    <table>
                        <tr class="row p-0 mt-0 mx-auto" *ngFor="let day of openingHours, let index = index">
                            <td class="col-12 day" *ngIf="index==0"><b>Montag</b></td>
                            <td class="col-12 day" *ngIf="index==1"><b>Dienstag - Freitag</b></td> -->
                            <!--<td class="col-12 day" *ngIf="index==2"><b>Mittwoch</b></td>
                            <td class="col-12 day" *ngIf="index==3"><b>Donnerstag</b></td>
                            <td class="col-12 day" *ngIf="index==4"><b>Freitag</b></td>-->
                            <!-- <td class="col-12 day" *ngIf="index==5"><b>Samstag</b></td>
                            <td class="col-12 day" *ngIf="index==6"><b>Sonntag & Feiertage</b></td>
                            <div *ngIf="index==0 || index==1 || index==5 || index==6" class="col-12 row justify-content-md-center p-0 m-0">
                                <td class="col-12  p-0 text-center">{{day[1]}}</td>
                            </div>
                        </tr>
                    </table>
                </div>
            </div> -->
        </div>
    </div>
</ng-container>
<!-- end: popup shop currently closed -->


<!--   TODO POPUP-->
<!-- begin: popup shop currently closed -->
<ng-container *ngIf="showSessionExpiredPopup">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Sitzung abgelaufen
            </div>
            <div class="cont-dialog-shop-closed">
                Deine Sitzung ist abgelaufen! Bitte starte den Bestellvorgang erneut.

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white mt-5" (click)="resetPage()">Weiter</button>
                </div>

            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup shop currently closed -->
