import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from '../../../services/main.service';
import { Router } from '@angular/router';
var NewsletterAbmeldenComponent = /** @class */ (function () {
    function NewsletterAbmeldenComponent(http, main_service, router) {
        this.http = http;
        this.main_service = main_service;
        this.router = router;
        // check if form is submitted
        this.formSent = false;
        this.optinSuccess = false;
        this.optoutSuccess = false;
        // headerBilder
        this.headerbild_MP = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_MP.jpg";
        this.headerbild_PB = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_PB.jpg";
        this.headerbild_MP_Desktop = "/assets/img/header/Headerbild_Obergruppen_Newsletter_MP.jpg";
        this.headerbild_PB_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Newsletter.jpg";
        this.sizeSmall = true;
        // create new reactive formgroup for form
        // https://angular.io/guide/reactive-forms
        this.newsletterFormGroup = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
            salutation: new FormControl(null),
            firstName: new FormControl(null),
            lastName: new FormControl(null),
            dataProtect: new FormControl(false, Validators.requiredTrue)
        });
        if (this.router.url === '/shop/newsletter-success') {
            this.optinSuccess = true;
        }
        if (this.router.url === '/shop/newsletter-optout-success') {
            this.optoutSuccess = true;
        }
    }
    NewsletterAbmeldenComponent.prototype.ngOnInit = function () {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        window.onresize = this.setHeaderbild;
        this.setHeaderbild();
    };
    Object.defineProperty(NewsletterAbmeldenComponent.prototype, "email", {
        // getter for form elements
        get: function () {
            return this.newsletterFormGroup.get('email');
        },
        enumerable: true,
        configurable: true
    });
    // submit the newsletter form
    NewsletterAbmeldenComponent.prototype.onSubmit = function () {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        var url = this.main_service.getFrontendUrl() + '/newsletter/subscribe';
        this.http.post(url, JSON.stringify(this.newsletterFormGroup.value), httpOptions)
            .subscribe(function (res) {
            _this.formSent = true;
            window.scroll(0, 0);
        }, function (err) {
        });
    };
    // Headerbild setzen
    NewsletterAbmeldenComponent.prototype.setHeaderbild = function () {
        var small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall)
            return;
        this.sizeSmall = small;
        /*console.log("Kleines Bild = ",small);*/
        if (small === false) {
            this.headerbild_MP = this.headerbild_MP_Desktop;
            this.headerbild_PB = this.headerbild_PB_Desktop;
        }
        else {
        }
    };
    return NewsletterAbmeldenComponent;
}());
export { NewsletterAbmeldenComponent };
