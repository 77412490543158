import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {FrontendService} from '../../../../services/frontend.service';
import {MainService} from '../../../../services/main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CartService} from '../../../../services/cart.service';
import {ArticleGroup} from '../../../../../_classes/ArticleGroup';
import {Prices} from '../../../../../_classes/Prices';
import {SubCat} from '../../../../../_classes/SubCat';
import {User} from '../../../../../_classes/User';
import {UserService} from '../../../../services/user.service';
import {MatSnackBar} from '@angular/material';
declare var jQuery: any;

@Component({
    selector: 'app-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit, OnDestroy {
    // public stuff  - - - - - - - - - - - - - - - -
    article_list: SubCat[] = [];
    show: number = 0;
    image_url: string;
    search_string: string = '';
    master_cat_name: string;
    kategorie_class: string = "article-gesamt";
    ready: boolean = false;

    min_val: number;

    user: User;


    on_pickup: boolean;      // true -> User holt Bestellung im Store ab
    on_happy_hour: boolean;
    onLunch: boolean;

    /* show the info box for the product */
    showInfoBox = false;
    showLunchInfoBox = false;
    /* holds the current infobox product information */
    infoBoxProduct: any;

    havAction: any;
    actionName: any;

    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];
    private master_cat_id: number;
    private article_subscription: Subscription;
    private svg_icon = '';

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        private route: ActivatedRoute,
        private cart_service: CartService,
        public router: Router,
        private user_service: UserService,
        public snackBar: MatSnackBar
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {

        this.cart_service.checkInstore();

        const test = 'Init article list component';

        // Observable: überwacht den Status von on_pickup
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            (data: boolean) => {
                this.on_pickup = data;
            }
        ));

        this.master_cat_id = +this.route.snapshot.paramMap.get('id_mc');
        // this.master_cat_name = this.route.snapshot.paramMap.get('category');

        this.initArticleList(+this.route.snapshot.paramMap.get('id_mc'));

        let self = this;
        setTimeout(function() {
            if (self.main_service.cats_svg_arr[self.master_cat_id] != undefined && self.main_service.cats_svg_arr[self.master_cat_id]) {
                self.svg_icon = self.main_service.cats_svg_arr[self.master_cat_id];
            }
        }, 1000);

        // Observable: holt Artikelliste vom Server
        this.subscriptions.push(this.route.params.subscribe(params => {
            this.master_cat_id = params['id_mc'];
            this.master_cat_name = params['category'];
        //    console.log(this.master_cat_name);
            if (this.main_service.cats_svg_arr[this.master_cat_id] != undefined && this.main_service.cats_svg_arr[this.master_cat_id]) {
                this.svg_icon = this.main_service.cats_svg_arr[this.master_cat_id];
            }
            /*für prod*/

            if (this.master_cat_name.toLowerCase() == "pizza") {

               /* this.kategorie_class = "article-box-content-pizza";*/
                this.kategorie_class = "article-pizza";
            }

            else if (this.master_cat_name.toLowerCase() == "getränke") {

                /*this.kategorie_class = "article-box-content-getraenk";*/
                this.kategorie_class = "article-getraenk";
            }

            else if (this.master_cat_name.toLowerCase() == "burger") {

                /*this.kategorie_class = "article-box-content-getraenk";*/
                this.kategorie_class = "article-burger";
            }

            else if (this.master_cat_name.toLowerCase() == "burger-menü") {

                /*this.kategorie_class = "article-box-content-burger-menue";*/
                this.kategorie_class = "article-burger-menue";
            }

            else if (this.master_cat_name.toLowerCase() == "fingerfood") {

                 /*this.kategorie_class = "article-box-content-fingerfood";*/
                this.kategorie_class = "article-fingerfood";
            }

            else if (this.master_cat_name.toLowerCase() == "salate") {

                /*this.kategorie_class = "article-box-content-fingerfood";*/
                this.kategorie_class = "article-salat";
            }

            else if (this.master_cat_name.toLowerCase() == "pizzabrötchen") {

                /*this.kategorie_class = "article-box-content-fingerfood";*/
                this.kategorie_class = "article-pizzabroetchen";
            }

            else if (this.master_cat_name.toLowerCase() == "dessert") {

                /*this.kategorie_class = "article-box-content-fingerfood";*/
                this.kategorie_class = "article-dessert";
            }

            else if (this.master_cat_name.toLowerCase() == "dressings & dips") {

                /*this.kategorie_class = "article-box-content-dressing";*/
                this.kategorie_class = "article-dressings";
            }

            else {
                this.kategorie_class = "article-gesamt";
            }

            this.search_string = '';
            this.initArticleList(+this.route.snapshot.paramMap.get('id_mc'));
        }));

        this.subscriptions.push(this.user_service.user_observable.subscribe(
            data => {
                this.user = data;
            }
        ));

        // Observable: überwacht den Status von on_happy_our
        this.subscriptions.push(this.cart_service.on_happy_hour_observable.subscribe(
            (data: boolean) => {
                this.on_happy_hour = data;
            }
        ));

        // Observable: observes the status of onLunch
        this.subscriptions.push(this.cart_service.onLunchObservable.subscribe(
            (data: boolean) => {
                this.onLunch = data;
            }
        ));

        this.subscriptions.push(this.cart_service.min_val_observable.subscribe(
            data => {

                this.min_val = data;
            }
        ));

        this.image_url = this.main_service.image_url;

        this.show = 0;
    }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    onLog() {

        // let arr= [1,2,3,4];
    }

    onAddArticle(index: number, size_index: number, sub_cat_index: number, sub_cat_id: number) {
        const pickupPrice = this.article_list[sub_cat_index].article[index].real_price[size_index].price - this.article_list[sub_cat_index].article[index].real_price[size_index].pickup_saving;
        const price: Prices = new Prices(this.article_list[sub_cat_index].article[index].real_price[size_index].price,
            this.article_list[sub_cat_index].article[index].real_price[size_index].happy_hour_price,
            this.article_list[sub_cat_index].article[index].real_price[size_index].lunch_price,
            pickupPrice,
            0,
            this.article_list[sub_cat_index].article[index].real_price[size_index].mega_deal_price,
            0);
        const new_group = new ArticleGroup(this.article_list[sub_cat_index].article[index], price, size_index, sub_cat_id, []);


        this.cart_service.addNewSingleArticleGroup(new_group);
        this.cart_service.recalculatePrices();
        const addToCartMessageDomObject = jQuery('.added-to-cart');
        if ( addToCartMessageDomObject.length) {
            addToCartMessageDomObject.removeClass('added-to-cart-hide');
        }
        // this.openSnackBar('Zum Warenkorb hinzugefügt:', this.article_list[sub_cat_index].article[index].name);

    }

    onLinkToDetail(i_cat, i_art, i_size) {
        const article = this.article_list[i_cat].article[i_art];

        // this.router.navigate(['Shop/4/Salat/34/Chef%20Salat/gro%C3%9Fe%20Portion']);
        this.router.navigate(['shop/bestellen', this.master_cat_id, this.master_cat_name.replace(/ /g, '-'),
            article.id, article.name.replace(/ /g, '-'), article.price[i_size].size.replace(/ /g, '_')]);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getProductByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxProduct = data;

            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }

    onOpenLunchInfoBox() {
        this.showLunchInfoBox = true;
    }

    checkCatWithAlwaysLunch(catMasterId) {
        var catIds = [70, 86, 95];
        if (catIds.includes(catMasterId)) {
            return true;
        }
        return false;
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions


    private initArticleList(master_cat_id: number) {

        this.ready = false;
        if (this.article_subscription) {
            this.article_subscription.unsubscribe();
        }
        var delivType = this.on_pickup ? 'pickup' : 'delivery';
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        if (!loadedDeliveryTime) {
            loadedDeliveryTime = loadedDeliveryDate.getHours().toString().padStart(2, '0') + ':' + loadedDeliveryDate.getMinutes().toString().padStart(2, '0');
        }
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();

        this.article_subscription = this.frontend_service.getArticleListByCategory(master_cat_id, delivType, delivDate).subscribe(
            (data: any[]) => {
                // for (let ix=0; ix < data.length; ix++) {
                for (let ix=0; ix < data.length; ix++) {
                    for (let i=0; i < data[ix].article.length; i++) {
                        if (data[ix].article[i].real_price) {
                            for (let ii=0; ii < data[ix].article[i].real_price.length; ii++) {
                                if (data[ix].article[i].real_price[ii].apllied_rules) {
                                    data[ix].article[i].haveRule = true;
                                    data[ix].article[i].ruleName = data[ix].article[i].real_price[ii].rule_name;
                                    break;
                                } else {
                                    data[ix].article[i].haveRule = false;
                                }
                            }
                        }
                    }
                }
                this.article_list = data;
                this.ready = true;
            });

    }

    isLunchCat(i_cat, i_art) {
        var artcl = this.article_list[i_cat].article[i_art];
        if (artcl.category_master_id == 11 || artcl.category_master_id == 13 || artcl.category_master_id == 14 || artcl.category_master_id == 96 || artcl.category_master_id == 98) {
            return true;
        }
        return false;
    }

}
