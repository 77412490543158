<div class="static-page-layout">
    <ng-container>
        <div class="row">
            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>

                <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_PB + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <h2 class="mp-font-medium-yellow text-center">Vielen Dank für deine Anmeldung! </h2>

                    <p class="text-center spacing font-wight-bold">Du hast dich erfolgreich angemeldet.
                        <!--<a routerLink="/willkommen/start"><strong class="font-italic"> Zur Startseite</strong></a> </p>-->

                    <a  href="https://{{ storeData?.domain}}" target="_blank"><strong class="font-italic"> Zur Startseite</strong></a> </p>
                </div>
            </div>
        </div>
    </ng-container>


</div>
