import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { Article } from '../../../../../_classes/Article';
import { FrontendService } from '../../../../services/frontend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../services/main.service';
import { Prices } from '../../../../../_classes/Prices';
import { CartService } from '../../../../services/cart.service';
import { Ingredient } from '../../../../../_classes/Ingredient';
import { IngredientList } from '../../../../../_classes/IngredientList';
import { ArticleGroup } from '../../../../../_classes/ArticleGroup';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { EventEmitter } from 'events';
declare var jQuery: any;

@Component({
    selector: 'app-article-detail',
    templateUrl: './article-detail.component.html',
    styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent implements OnInit, OnDestroy {

    @ViewChild('stepper') stepper: MatStepper;

    // public stuff  - - - - - - - - - - - - - - - -
    current_article: Article;
    added_ing_list: IngredientList[] = [];                  // beinhaltet alle hinzugefügten Zutaten
    added_ing_list_temp: IngredientList[] = [];
    sub_cat_id: number;                                     // ID der Unterkategorie
    image_url: string;                                      // url zum Bild
    bgImage: string;                                        // url zum Bild
    ready: boolean = false;                                 // true, wenn alle relevanten daten fertig geladen sind
    current_size: number;                                   // index der aktuell gewählten Größe
    current_price: number;                                  // beinhaltet den aktuellen Gesamptpreis des Artikels
    price: Prices = new Prices(null, null, null, null, null, null, null);
    on_pickup: boolean;                                     // true, wenn Kunde Waren abholt
    on_happy_hour: boolean;                                 // true, falls Happy Hour ist (relevant für angezeigten preis)
    onLunch: boolean;                                       // true, falls Happy Hour ist (relevant für angezeigten preis)
    on_edit: boolean = false;                               // true, falls gewählter Artikel schon im WK ist (Artikel soll vom WK aus nun bearbeitet werden)
    ingredient_list: Ingredient[] = [];                     // hinzufügbare Zutaten (sortiert nach Zutatenkategorie)
    current_view: number;                               // aktuell angezeigte Unterseite: 0 Zutaten | 1 Menüoption
    showFreeAddNow = 0;
    show_ing_list: boolean = false;                         // regelt Sichtbarkeit der Liste hinzugefügter Zutaten
    show_dialog: boolean = false;                           // reglet Sichtbarkeit des abschließenden Dialogs
    price_ing_sum: number[] = [];                           // Array mit summierten Preisen für die hinzugefügten Zutaten
    addedToCartMessage: string;
    currentPageId: number;
    tempAddedArticles: any[] = [];
    onAddToCartClicked = false;
    minFreeAddablesReached = true;
    ingredientListLoaded = false;
    addSubIngStart = false;
    show_tab_of_free_addables = 0;
    changeSize = false;
    potatoAdded = true;
    showBtnNextStp = true;
    souceTempIndex: number;
    storeData: any;

    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];
    private amount: any[] = [];                             // für add_ing Komponente, speichert anzahl der hinzugefügten Ings
    private state: number = 1;                              // 1 - regulär; 2 - Aktionsartikel

    /* show the info box for the product */
    showInfoBox = false;
    /* holds the current infobox product information */
    infoBoxProduct: any;

    articleAmmount = 1;

    addIngIsDisabled_var = false;
    addIngIsDisabledTopList_var = false;
    hasFreeIng = true;
    subAddablesReached = true;
    notFreeTabQ = 0;
    tempAddedArticlesSumm = 0;
    articleHasIngridients = 0;
    articleHasFreeIngridients = 0;
    currentStep = 0;
    stepper_ready = false;
    steps = [];
    step_block_style = '';
    changedTempArticles = 0;
    menu_article_list: any[] = [];
    menuDownloaded = false;

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private cart_service: CartService,
        private main_service: MainService,
        private route: ActivatedRoute,
        public router: Router,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        this.changeSize=false;
        this.cart_service.checkInstore();
        // Observable: holt Infors über aktuell gewählten Artikel
        // wenn Daten geladen -> weitere Initialisierungen ausführen
        if (this.cart_service.on_edit) {
            // lade Artikel zur Bearbeitung

            let data = this.cart_service.getEditArticle();

            this.on_edit = true;
            this.current_article = data.article;
            this.articleAmmount = data.amount;
            this.bgImage = data.article.bgimage_detail;

            // this.cart_service.checkCouponProductInCart(data.article);


            this.added_ing_list = data.added_ingredients;

            this.initCurrentSize();
            this.initIngredientList(this.current_article.category_id);

            // init correct view reagrding to free addables
            this.checkIfArticleHasFreeAddables();


            // this.sub_cat_id = data.category_id;
        } else {
            if (this.route.url['value'][0].path == 'Aktion') {
                // lade Aktionsartikel
                this.state = 2;
                this.subscriptions.push(this.frontend_service.getSpArticleByID(+this.route.snapshot.paramMap.get('id_a')).subscribe(
                    (data: Article) => {
                        this.current_article = data;
                        this.initCurrentSize();

                        // init correct view reagrding to free addables
                        if (this.current_article.price[this.current_size].has_free_addables) {
                            this.current_view = -1;
                        } else {
                            this.current_view = 0;
                        }


                        this.initIngredientList(this.current_article.category_id);
                        this.ready = true;
                        this.sub_cat_id = data.category_id;

                    }));
            } else {
                // lade regulärer Artikel
                this.state = 1;
                this.subscriptions.push(this.frontend_service.getArticleByID(+this.route.snapshot.paramMap.get('id_a')).subscribe(
                    (data: Article) => {

                        if( this.cart_service.checkCouponProductInCart(data)){
                            this.router.navigate(['shop']);
                        }
                        this.current_article = data;
                        this.initCurrentSize();

                        // init correct view reagrding to free addables
                        this.checkIfArticleHasFreeAddables();

                        this.initIngredientList(this.current_article.category_id);
                        this.ready = true;
                        this.sub_cat_id = data.category_id;
                    }));
            }
        }

        this.image_url = this.main_service.image_url;
        this.onAddToCartClicked = false;

        // TODO implement htis the right way
        // https://stackoverflow.com/questions/38593515/warning-sanitizing-unsafe-style-value-url
        // this.bgImage = this.image_url + '/admin/products/' + this.current_article.id + '/' + this.current_article.image;

        // Obervable: überwacht den Status von on_pickup
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            (data: boolean) => {
                this.on_pickup = data;
                if (this.current_size != undefined) {
                    this.recalculatePrice();
                }

            }
        ));
        // Obervable: überwacht den Status von happy hour
        this.subscriptions.push(this.cart_service.on_happy_hour_observable.subscribe(
            (data: boolean) => {
                this.on_happy_hour = data;
                if (this.current_size != undefined) {
                    this.recalculatePrice();
                }
            }
        ));

        // Obervable: überwacht den Status von lunch
        this.subscriptions.push(this.cart_service.onLunchObservable.subscribe(
            (data: boolean) => {
                this.onLunch = data;
                if (this.current_size !== undefined) {
                    this.recalculatePrice();
                }
            }
        ));

        this.subscriptions.push(this.main_service.current_page_id_observable.subscribe(
            (data: number) => {
                this.currentPageId = data;
            }
        ));



        this.checkFreeAddablesReached();

        // Preload articles for Menu
        this.initiMenuArticles();

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }

        /**
         * Wenn Größe geändert wird und Bearbeitungsstatus gesetzt ist diesen nicht zurücksetzen
         */
        if( this.changeSize  &&  this.cart_service.on_edit === true ){
        } else {
            this.cart_service.resetEditStat();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {

    }

    /* Setes the current view of the article size (-1 = FREE ADDABLES, 0 = NORMAL) */
    checkIfArticleHasFreeAddables() {
        // if (this.ingredient_list.length < 1) {
        //     this.current_view = 1;
        //     return;
        // }
        if (this.current_article.price[this.current_size].always_show_free_addables || !this.current_article.price[this.current_size].always_show_free_addables && this.onLunch) {
            if (this.current_article.price[this.current_size].has_free_addables) {
                this.current_view = -1;
                this.showFreeAddNow = 1;
            } else {
                this.current_view = 0;
            }
        } else {
            this.current_view = 0;
        }
    }

    selectSize(index: number) {
        if (this.current_size == index) {
            return;
        }
        this.current_size = index;
        this.changeSize=true;
        this.price.regular_price = +this.current_article.price[this.current_size].price;
        this.price.happy_hour_price = +this.current_article.price[this.current_size].happy_hour_price;
        this.price.lunch_price = +this.current_article.price[this.current_size].lunch_price;
        this.price.pickup_price = +this.current_article.price[this.current_size].price - this.current_article.price[this.current_size].pickup_saving;

        if (this.state == 1) {

            this.router.navigate(['shop/bestellen', this.current_article.category_master_id, this.current_article.category,
                this.current_article.id, this.current_article.name.replace(/ /g, '-'), this.current_article.price[this.current_size].size.replace(/ /g, '_')]);
        }
        if (this.state == 2 || this.router.url.match('\/aktion\/')) {

            this.router.navigate(['shop/bestellen', 'aktion', this.current_article.id, this.current_article.name.replace(/ /g, '-'),
                this.current_article.price[this.current_size].size.replace(/ /g, '_')]);
        }


        /* reset all when changing article size */
        const previous_added_ing_list = this.added_ing_list;
        const previous_amount = this.amount;
        this.amount = [];
        this.price_ing_sum = [];
        this.added_ing_list = [];
        this.initAmountArr();

        // for (let i = 0; i < this.added_ing_list.length; i++) {

        //     let max_free_addable = 0;
        //     if (this.ingredient_list[this.added_ing_list[i].ing_cat_index].sizes_free_addables[this.current_size]) {
        //         max_free_addable = this.ingredient_list[this.added_ing_list[i].ing_cat_index].sizes_free_addables[this.current_size].free_addable_amount;
        //     }

        //     this.added_ing_list[i].max_free_addable = max_free_addable;



        //     if ( this.added_ing_list[i].added_as_free_addable === true || this.added_ing_list[i].max_free_addable > 0 ){

        //         this.added_ing_list.splice(i, 1);

        //     } else {
        //         this.amount[this.added_ing_list[i].ing_cat_index] = previous_amount[this.added_ing_list[i].ing_cat_index];
        //     }
        // }

        /* BUGFIX: check if current view is normal "add ingredients view" (0) or "free addable view" (-1)  */


        this.recalculatePrice();
        /* check current view */
        this.checkIfArticleHasFreeAddables();

        if (this.current_view === -1) {
            this.minFreeAddablesReached = false;
        } else {
            /* should always be true, because in this view free addables dont matter */
            this.minFreeAddablesReached = true;
            if (!this.checkNotFreeIngridients()) {
                this.current_view = 1;
            }
        }


        for (let i = 0; i < this.ingredient_list.length; i++) {
            if (this.ingredient_list[i].sizes_free_addables.length > 0) {
                if (this.ingredient_list[i].sizes_free_addables[this.current_size]) {
                    if (!this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch || this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch && this.onLunch) {
                        this.show_tab_of_free_addables = i;
                        break;
                    }
                }
            }
        }

    }

    onAddToCart(next_page: number) {
        let coupon_product_code = '';
        let onlyCoupon = 0;
        if (this.on_edit) {
            this.cart_service.submitEditArticle(this.price, this.current_size, this.added_ing_list, this.articleAmmount);
        } else {
            this.route.queryParams.subscribe(params => {
                    if (params.pc) {
                        coupon_product_code = params.pc;
                        onlyCoupon = params.onlyCoupon;
                    }
                }
            );

            const article_group: ArticleGroup = new ArticleGroup(this.current_article, this.price, this.current_size, this.sub_cat_id, [], coupon_product_code, onlyCoupon);
            let il = JSON.parse(JSON.stringify(this.added_ing_list));
            let ingArr = this.getIngArrByProd(il, this.articleAmmount);
            // article_group.added_ingredients = this.added_ing_list;

            for (let i = 0; i < this.articleAmmount; i++) {
                let tempGroup = JSON.parse(JSON.stringify(article_group));
                tempGroup.added_ingredients = ingArr[i];
                // this.cart_service.addNewSingleArticleGroup(article_group);
                this.cart_service.addNewSingleArticleGroup(tempGroup);
            }

        }

        this.onAddToCartClicked = true;


        // setTimeout(() => {
        //     switch (next_page) {
        //         // back to list view
        //         case 0:
        //             if (this.route.url['value'][0].path === 'aktion') {
        //                 // this.router.navigate(['shop/bestellen', 'aktion']);
        //             } else if (this.route.url['value'][0].path === 'coupon') {
        //                 // this.router.navigate(['shop/bestellen', 'warenkorb']);
        //             } else {
        //                 // this.router.navigate(['shop/bestellen', this.current_article.category_master_id, this.current_article.category_master]);
        //             }
        //             break;
        //         // go to cart
        //         case 1:
        //             // this.router.navigate(['shop/bestellen', 'warenkorb']);
        //             // this.main_service.setCurrentPageID(11);
        //             break;
        //     }
        // }, 2000);

        const temp_articles = this.addTempArticlesToCart(coupon_product_code, onlyCoupon);

        // show overlay for item
        this.cart_service.recalculatePrices();
        const addToCartMessageDomObject = jQuery('.added-to-cart');
        if ( addToCartMessageDomObject.length) {
            addToCartMessageDomObject.removeClass('added-to-cart-hide');

            // let added_articles = '<br />' + this.articleAmmount + ' x ' + this.current_article.name;
            // for ( const temp_article_index in temp_articles ) {
            //     added_articles += '<br />' + temp_articles[temp_article_index].amount + 'x ' + temp_articles[temp_article_index].article.name;
            // }

            // this.addedToCartMessage = 'Deine Artikel wurden in den Warenkorb gelegt.' + added_articles;

            // setTimeout(() => {
            //     addToCartMessageDomObject.addClass('added-to-cart-hide');
            //     this.addedToCartMessage = '';
            // }, 2000);
        }

    }

    addTempArticlesToCart(coupon_product_code, onlyCoupon) {

        const temp_articles = [];

        for (let i = 0; i < this.tempAddedArticles.length; i++) {
            const pickupPrice = this.tempAddedArticles[i].price[0].price - this.tempAddedArticles[i].price[0].pickup_saving;
            const price: Prices = new Prices(this.tempAddedArticles[i].price[0].price,
                this.tempAddedArticles[i].price[0].happy_hour_price,
                this.tempAddedArticles[i].price[0].lunch_price,
                pickupPrice,
                0,
                this.tempAddedArticles[i].price[0].mega_deal_price,
                0);
            const new_group = new ArticleGroup(this.tempAddedArticles[i], price, 0, -1, [], coupon_product_code, onlyCoupon);
            temp_articles.push(new_group);
            this.cart_service.addNewSingleArticleGroup(new_group);
        }

        this.cart_service.calcTipPercentage();

        return temp_articles;
    }

    onAddArt() {
        if (this.ingredientListLoaded && !this.current_article.is_coupon_product) {
            this.articleAmmount++;
            this.initIngredientList(this.current_article.category_id, true, 'addArt');
            // this.initAddedIngArray();
        }
    }

    onSubArt() {
        if (this.articleAmmount > 1) {
            this.articleAmmount--;
            this.initIngredientList(this.current_article.category_id, true, 'subArt');
            // this.initAddedIngArray();
        }
    }

    onAddIng(event: any) {
        this.document.querySelectorAll('.mp-btn-plus').forEach((elem)=>{ elem.disabled = true;});
        this.document.querySelectorAll('.mp-btn-minus').forEach((elem)=>{ elem.disabled = true;});
        this.addSubIngStart = true;
        let index = event.index;
        let ingredientGroupIndex = event.ing_cat_index;
        let ingredientIndex = event.ing_index;
        // event is supposed to be an object; if only number is given overwrite values
        if (typeof (event) === 'number') {
            index = event;
            ingredientGroupIndex = this.added_ing_list[index].ing_cat_index;
            ingredientIndex = this.added_ing_list[index].ing_index;
        }
        // if ingredient was already added
        const now_edited = true;
        if (index >= 0) {
            this.amount[ingredientGroupIndex][ingredientIndex]++;
            this.amount[ingredientGroupIndex]['sum']++;
            this.added_ing_list[index].amount++;
            if (this.current_view == -1) {
                if ((this.added_ing_list[index].max_free_addable - this.added_ing_list[index].free_amount) > 0) {
                    this.added_ing_list[index].free_amount++;
                    this.amount[ingredientGroupIndex]['free_sum']++;
                }
            }
            this.price_ing_sum[index] = (this.added_ing_list[index].amount - this.added_ing_list[index].free_amount) * this.added_ing_list[index].ingredients.price[this.current_size].price;
            this.added_ing_list[index].now_edited = now_edited;
        }
        // count up already added ingredient
        else {
            const ing_master_id = this.ingredient_list[ingredientGroupIndex].ingredient_group_master;
            // if not: add it to the array
            const ing = this.ingredient_list[ingredientGroupIndex].ingredients[ingredientIndex];
            let max_free_addable = 0;
            if (this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size]) {
                max_free_addable = this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size].free_addable_amount;
            }
            let max_addable = 1;
            if (this.ingredient_list[ingredientGroupIndex].max_addable) {
                max_addable = this.ingredient_list[ingredientGroupIndex].max_addable;
            }
            // if current view is free addables view then add item for free
            const added_as_free_addable = (this.current_view === -1) ? true : false;
            const currentTempIndex = this.added_ing_list.push(new IngredientList(ing, ingredientGroupIndex, ingredientIndex, added_as_free_addable, max_free_addable, max_addable, now_edited));

            // if ((this.added_ing_list[currentTempIndex].max_free_addable - this.added_ing_list[currentTempIndex].free_amount) > 0) {
            //     this.added_ing_list[currentTempIndex].free_amount++;
            //     this.amount[ingredientGroupIndex]['free_sum']++;
            // }

            this.amount[ingredientGroupIndex]['free_sum']++;
            if (ing_master_id === 5 || ing_master_id === 20) {
                this.souceTempIndex = currentTempIndex - 1;
            }
            this.amount[ingredientGroupIndex]['sum']++;
            this.amount[ingredientGroupIndex][ingredientIndex]++;
        }

        setTimeout(() => {
            this.initIngredientList(this.current_article.category_id, true, 'addIng');
        }, 10);
        // this.initAddedIngArray();
        // this.calculateFreeAddables(index, true);
        // this.recalculatePrice();


        /*SCROLL TEST*/
        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767)  {
        //     setTimeout( function() {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500 );
        // }
        
    }



    onSubIng(index: number) {
        this.document.querySelectorAll('.mp-btn-plus').forEach((elem)=>{ elem.disabled = true;});
        this.document.querySelectorAll('.mp-btn-minus').forEach((elem)=>{ elem.disabled = true;});
        this.addSubIngStart = true;
        const cat_i = this.added_ing_list[index].ing_cat_index;
        const ing_i = this.added_ing_list[index].ing_index;
        const ing_master_id = this.ingredient_list[cat_i].ingredient_group_master;
        this.amount[cat_i][ing_i]--;
        this.amount[cat_i]['sum']--;
        if (this.added_ing_list[index].free_amount >= this.added_ing_list[index].amount) {
            this.amount[cat_i]['free_sum']--;
        }

        if (this.added_ing_list[index].amount === 1) {
            const free_amount_was_set = (this.added_ing_list[index].free_amount === 1);
            delete (this.added_ing_list[index]);
            delete (this.price_ing_sum[index]);
            this.added_ing_list = this.resetIndexes(this.added_ing_list);
            this.price_ing_sum = this.resetIndexes(this.price_ing_sum);
            if(free_amount_was_set ) {
                this.current_view = -1;
            }
        } else {
            if (this.added_ing_list[index].free_amount < this.added_ing_list[index].amount ) {
                this.added_ing_list[index].amount--;
                this.price_ing_sum[index] = (this.added_ing_list[index].amount - this.added_ing_list[index].free_amount) * this.added_ing_list[index].ingredients.price[this.current_size].price;
            } else {
                this.added_ing_list[index].free_amount--
                this.added_ing_list[index].amount--;
                this.price_ing_sum[index] = (this.added_ing_list[index].amount - this.added_ing_list[index].free_amount) * this.added_ing_list[index].ingredients.price[this.current_size].price;
            }
            this.added_ing_list[index].now_edited = true;
        }
        setTimeout(() => {
            this.initIngredientList(this.current_article.category_id, true, 'subIng');
        }, 10);
    }


    // add articles emitted from child add article component
    onTempArticleAdded($event: any) {
        // add index from list of added article
        $event.article.index = $event.index;
        // push the new article to array
        this.tempAddedArticles.push($event.article);
        this.tempAddedArticlesSumm += $event.article.price[0].price;
    }

    // remove articles emitted from child add article component
    onTempArticleRemoved($event: any) {

        for (let i = this.tempAddedArticles.length - 1; i >= 0; i--) {
            if (this.tempAddedArticles[i].index === $event.index && this.tempAddedArticles[i].category_id === $event.categoryID) {
                this.tempAddedArticlesSumm -= this.tempAddedArticles[i].price[0].price;
                this.tempAddedArticles.splice(i, 1);
                break;
            }
        }
    }

    private subIngIsDisabled(i_cat, i_ing) {

        for ( let i = 0; i <= this.added_ing_list.length - 1; i++ ) {
            if ( this.added_ing_list[i].ing_cat_index === i_cat && this.added_ing_list[i].ing_index === i_ing ) {
                // if ( this.added_ing_list[i].free_amount === this.added_ing_list[i].amount ) {
                //     return true;
                // }
            }
        }
        return false;

    }

    private addIngIsDisabled(i_cat, i_ing) {
        for ( let i = 0; i <= this.added_ing_list.length - 1; i++ ) {
            if ( this.added_ing_list[i].ing_cat_index === i_cat && this.added_ing_list[i].ing_index === i_ing ) {
                return (
                    this.amount[this.added_ing_list[i].ing_cat_index ][this.added_ing_list[i].ing_index] >= this.ingredient_list[this.added_ing_list[i].ing_cat_index ].max_addable ||
                    this.amount[this.added_ing_list[i].ing_cat_index ].sum >= this.ingredient_list[this.added_ing_list[i].ing_cat_index ].max_add_global
                );
            }
        }
    }

    private addIngIsDisabledTopList(i_cat, i_ing) {
        for ( let i = 0; i <= this.added_ing_list.length - 1; i++ ) {
            if ( this.added_ing_list[i].ing_cat_index === i_cat && this.added_ing_list[i].ing_index === i_ing ) {
                return (
                    this.amount[i_cat][i_ing] >= this.added_ing_list[i].max_addable ||
                    this.amount[i_cat].sum >= this.ingredient_list[i_cat].max_add_global
                );
            }
        }
    }

    // add temporary articles from list
    onTempAddArticle(article: Article, index: number) {
        // add index from list of added article
        article.index = index;
        // push the new article to array
        this.tempAddedArticles.push(article);
        this.tempAddedArticlesSumm += article.price[0].price;
        this.cart_service.changeChangedTempArticles();
        /*SCROLL TEST*/
    //     const scroller = this.pageScrollService;
    //     const scrollerDoc = this.document;
    //    /* let target = "#ingredient-box";*/
    //     let target = ".target-oben";
    //     let offset = this.positionAbfrage();
    //     if (window.screen.availWidth > 767) {
    //         setTimeout( function() {
    //             scroller.scroll({
    //                 document: scrollerDoc,
    //                 // scrollTarget: '.article-price-wrap',
    //                 scrollTarget: target,
    //                 scrollOffset: offset,
    //                 duration: 600
    //             });
    //         }, 500 );
    //     }
        
    }

    // remove temporary articles from list
    onTempRemoveArticle(index: number) {
        // iterate backwards to remove the last added article
        this.tempAddedArticlesSumm -= this.tempAddedArticles[index].price[0].price;
        this.tempAddedArticles.splice(index, 1);
        this.cart_service.changeChangedTempArticles();
    }

    // checkt, ob alle gratiszutaten gewählt wurden -> falls nicht, Dialog anzeigen
    onStepContinue(stepId: number) {
        if (stepId > 1) {
            return;
        }
        this.current_view = stepId;
        for (let ind=0; ind<this.steps.length; ind++) {
            if (this.steps[ind].stepIndex < stepId) {
                this.steps[ind].class = 'step-completed';
            } else if (this.steps[ind].stepIndex === stepId) {
                this.steps[ind].class = 'step-active';
            } else {
                this.steps[ind].class = '';
            }
        }

      /*  SCROLL*/
        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // let target = "#target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767)  {
        //     setTimeout( function() {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500 );
        // }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    private initCurrentSize() {
        const size_name = this.route.snapshot.paramMap.get('size').replace(/_/g, ' ');
        for (let i = 0; i < this.current_article.price.length; i++) {
            if (this.current_article.price[i].size == size_name) {
                this.current_size = i;
                this.initPrice();
                break;
            }
        }
    }

    private initIngredientList(sub_cat_id: number, recalculate = false, operationType: any = false) {
        //Observable: holt verfügbare Zutaten für aktuell gewählten Artikel
        // this.subscriptions.push(this.frontend_service.getIngListBySubCat(sub_cat_id).subscribe(
        this.ingredientListLoaded = false;

        this.subscriptions.push(this.frontend_service.getIngListBySubCatReload(sub_cat_id, this.added_ing_list, this.articleAmmount, operationType, this.current_size).subscribe((data: any) => {
                this.ingredient_list = data.ingredientList;
                let adedIn: any = [];
                for (let i = 0; i < data.addedIngredients.length; i++) {
                    if (data.addedIngredients[i].amount > 0) {
                        adedIn.push(data.addedIngredients[i]);
                    }
                }
                // this.added_ing_list = data.addedIngredients;
                this.added_ing_list = adedIn;
                // this.added_ing_list_temp = data.addedIngredients;
                this.added_ing_list_temp = adedIn;
                this.subAddablesReached = data.subAddablesReached;
                    if (this.current_article.price[this.current_size].has_free_addables) {
                        this.minFreeAddablesReached = data.freeAddableReached;
                        this.showBtnNextStp = data.freeAddableReached;
                    } else {
                        this.minFreeAddablesReached = true;
                        this.showBtnNextStp = true;
                    }
                if (this.current_article.price[this.current_size].has_free_addables && !this.minFreeAddablesReached && this.onLunch) {
                    this.current_view = -1;
                }



                this.initAmountArr(false);
                // check free addables; if needed disable next step button
                if (this.ingredient_list.length === 0) {
                    this.minFreeAddablesReached = true;
                }
                this.checkFreeAddablesReached();
                this.ingredientListLoaded = true;
                this.stepper_ready = true;
                this.addSubIngStart = false;
                if (this.ingredientListLoaded) {
                    for (let i = 0; i < this.ingredient_list.length; i++) {
                        if (this.ingredient_list[i].sizes_free_addables.length > 0) {
                            if (typeof this.ingredient_list[i].sizes_free_addables[this.current_size] != 'undefined') {
                                if (!this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch || this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch && this.onLunch) {

                                    this.show_tab_of_free_addables = i;
                                    break;
                                }
                            }
                        }
                    }
                    this.hasFreeIng = this.checkNotFreeIngridients();
                    if ((this.current_view === 0 && !this.hasFreeIng) /* || !this.ingredient_list.length */) {
                        this.current_view = 1;
                    }
                    this.articleHasIngridients = 0;
                    this.articleHasFreeIngridients = 0;
                    for (let i = 0; i < this.ingredient_list.length; i++) {
                        this.ingredient_list[i].ingredients.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                        for (let ii = 0; ii < this.ingredient_list[i].ingredients.length; ii++) {
                            if (typeof this.ingredient_list[i].ingredients[ii].price[this.current_size] != "undefined"  && typeof this.ingredient_list[i].ingredients[ii].price[this.current_size].price != "undefined") {
                                this.articleHasIngridients += 1;
                                if (this.ingredient_list[i].ingredients[ii].is_active 
                                    && this.ingredient_list[i].ingredients[ii].price[this.current_size].is_free_addable 
                                    && (this.ingredient_list[i].is_lunch || !this.ingredient_list[i].ingredients[ii].price[this.current_size].only_at_lunch)
                                )
                                {
                                    this.articleHasFreeIngridients += 1;
                                }
                            }
                        }
                    }
                    let index = 1;
                    this.steps = [];
                    if (this.articleHasFreeIngridients) {
                        let cla = this.current_view === -1 ? 'step-active' : 'step-completed';
                        this.steps.push({label: 'Gratis Zutaten wählen', class: cla, index: index, stepIndex: -1});
                        index++;
                    }

                    if (this.articleHasIngridients - this.articleHasFreeIngridients) {
                        let clb = this.current_view === 0 ? 'step-active' : this.current_view > 0 ? 'step-completed' : '';
                        this.steps.push({label: 'Extra Zutaten wählen', class: clb, index: index, stepIndex: -0});
                        index++;
                    }

                    let clc = this.current_view === 1 ? 'step-active' : '';
                    this.steps.push({ label: 'Menü', class: clc, index: index, stepIndex: 1});
                    index++;

                    this.steps.push({label: 'In den Warenkorb', class: '', index: index, stepIndex: 2});
                    this.step_block_style = '' + (100/this.steps.length).toFixed(2) + '%;';

                    if (recalculate) {
                        this.initAddedIngArray();
                        this.recalculatePrice();
                    }
                    for (let a = 0; a < this.added_ing_list.length; a++) {
                        this.added_ing_list[a].addDisabled = this.addIngIsDisabled(this.added_ing_list[a].ing_cat_index, this.added_ing_list[a].ing_index);
                        this.added_ing_list[a].addDisabledTopList = this.addIngIsDisabledTopList(this.added_ing_list[a].ing_cat_index, this.added_ing_list[a].ing_index);
                    }
                    if (!this.minFreeAddablesReached) {
                        this.onStepContinue(-1);
                    } else if (!this.subAddablesReached) {
                        if (this.articleHasFreeIngridients) {
                            this.onStepContinue(-1);
                        } else {
                            this.onStepContinue(0);
                        }
                    }
                }
            }));

    }

    private initPrice() {
        this.price.regular_price = this.current_article.price[this.current_size].price;
        this.price.lunch_price = this.current_article.price[this.current_size].lunch_price;
        this.price.happy_hour_price = this.current_article.price[this.current_size].happy_hour_price;
        this.price.pickup_price = this.current_article.price[this.current_size].price - this.current_article.price[this.current_size].pickup_saving;
        this.price.price_for_added_ingredients = 0;
        this.price.mega_deal_price = this.current_article.price[this.current_size].mega_deal_price;


        this.recalculatePrice();
    }

    private initAmountArr(initAded = true) {
        this.ready = true;
        for (let i = 0; i < this.ingredient_list.length; i++) {
            this.amount[i] = [];
            for (let j = 0; j < this.ingredient_list[i].ingredients.length; j++) {
                this.amount[i][j] = 0;
            }
            this.amount[i]['sum'] = 0;
            this.amount[i]['free_sum'] = 0;
        }
        if (initAded) {
            this.initAddedIngArray();
        }
    }

    private recalculatePrice() {
        let ing_price: number = 0;
        let ing_price_for_free_addables: number = 0;

        this.current_price = this.on_pickup ? this.price.pickup_price : this.price.regular_price;

        if (this.on_happy_hour && this.price.happy_hour_price) {
            this.current_price = this.current_article.price[this.current_size].happy_hour_price;
        }
        if (this.onLunch && this.price.lunch_price) {
            /**
             * Bei Abholung => Abholpreis günstiger als Angebotspreis
             */
            if (this.on_pickup) {
                if ( this.price.pickup_price <  this.price.lunch_price && this.on_pickup && this.current_article.category_id !== 4 ) {
                    this.current_price = this.price.pickup_price;
                } else {
                    this.current_price = this.current_article.price[this.current_size].lunch_price;
                }
            } else {
                if ( this.current_price > this.price.lunch_price && this.frontend_service.storeData.master == 1) {
                    this.current_price = this.price.lunch_price;
                    // this.current_price = this.price.lunch_price + 1;
                }
            }


        }

        // recalculation for ingredients
        for (let i = 0; i < this.added_ing_list.length; i++) {
            ing_price += (this.added_ing_list[i].amount - this.added_ing_list[i].free_amount) * this.added_ing_list[i].ingredients['price'][this.current_size].price;
        }

        for (let i = 0; i < this.added_ing_list.length; i++) {
            ing_price_for_free_addables += this.added_ing_list[i].free_amount * this.added_ing_list[i].ingredients['price'][this.current_size].price;
        }

        // recalc price ing sum
        for (let index = 0; index < this.added_ing_list.length; index++) {
            this.price_ing_sum[index] = (this.added_ing_list[index].amount - this.added_ing_list[index].free_amount) * this.added_ing_list[index].ingredients.price[this.current_size].price;
        }

        this.price.price_for_added_ingredients = ing_price;
        this.price.price_for_added_free_addables = ing_price_for_free_addables;
        this.current_price = this.current_price * this.articleAmmount + ing_price * 1;

        // check if the minimm of free addables was added to product; first view only
        if (this.current_view === -1) {

            this.checkFreeAddablesReached();
        }

    }

    // set value to true when free addables are reached, so that user can go on
    checkFreeAddablesReached() {
        for (let i = 0; i < this.ingredient_list.length; i++) {
            if (this.ingredient_list[i].sizes_free_addables[this.current_size]) {
                if (!this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch || this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch && this.onLunch) {
                if (this.ingredient_list[i].sizes_free_addables[this.current_size].free_addable_amount > 0) {
                    if (this.amount[i]['free_sum'] >= this.ingredient_list[i].sizes_free_addables[this.current_size].free_addable_amount) {
                        this.current_article.price[this.current_size].free_addables_reached = 1;
                        // break;
                    } else {
                        this.current_article.price[this.current_size].free_addables_reached = 0;
                        this.show_tab_of_free_addables = i;
                        break;
                    }
                }
            }
            } else {
                this.current_article.price[this.current_size].free_addables_reached = 1;
                // break;
            }
        }
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getProductByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxProduct = data;

            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }

    private resetIndexes(array: any[]) {
        let temp: any[] = [];
        for (let item of array) {
            if (item != undefined) {
                temp.push(item);
            }
        }
        return temp;
    }

    private initAddedIngArray() {

        if (!this.added_ing_list.length) {
            return;
        }
        let temp: any[] = [];
        for (let i = 0; i < 30; i++) {

            if (this.added_ing_list[i] == undefined) {
                break;
            }
            temp.push(this.added_ing_list[i]);
            this.amount[this.added_ing_list[i].ing_cat_index][this.added_ing_list[i].ing_index] = this.added_ing_list[i].amount;
            this.amount[this.added_ing_list[i].ing_cat_index]['free_sum'] += this.added_ing_list[i].free_amount;
        }
        for (let i = 0; i < this.amount.length; i++) {
            let sum = 0;
            for (let j = 0; j < this.amount[i].length; j++) {
                sum = sum + this.amount[i][j];
            }
            this.amount[i]['sum'] = sum;
            if (this.amount[i]['free_sum'] > this.amount[i]['sum']) {
                this.amount[i]['free_sum'] = this.amount[i]['sum'];
            }
        }

        this.added_ing_list = temp;
        this.added_ing_list_temp = temp;

        this.ready = true;
    }

    checkNotFreeIngridients() {
        let hasIngridient = false;
        this.notFreeTabQ = 0;
        for (let i = 0; this.ingredient_list.length > i; i++) {
            if (!this.ingredient_list[i].sizes_free_addables || this.ingredient_list[i].sizes_free_addables.length == 0) {
                hasIngridient = true;
                this.notFreeTabQ++;
                continue;
            }
            if (!this.ingredient_list[i].sizes_free_addables[this.current_size] || this.ingredient_list[i].sizes_free_addables[this.current_size].length == 0) {
                hasIngridient = true;
                this.notFreeTabQ++;
                continue;
            }
            if (this.ingredient_list[i].sizes_free_addables[this.current_size].only_at_lunch == 1 && !this.onLunch) {
                hasIngridient = true;
                this.notFreeTabQ++;
                continue;
            }
        }
        return hasIngridient;
    }
    isLunchCat() {
        if (this.current_article.category_master_id == 11 || this.current_article.category_master_id == 13 || this.current_article.category_master_id == 14 || this.current_article.category_master_id == 96 || this.current_article.category_master_id == 98) {
            return true;
        }
        return false;
    }

    positionAbfrage() {
        let header = this.document.getElementById('header');
        let hoehe = header.clientHeight;
        return hoehe;
    }

    getIngArrByProd(ingArr, artAmmount) {
        let freeArr: any = [];
        let notFreeArr: any = [];
        for (let i = 0; i < ingArr.length; i++) {
            if (ingArr[i].amount < 1) {
                continue;
            }
            if (ingArr[i].added_as_free_addable) {
                for (let e = 0; e < ingArr[i].free_amount; e++) {
                    if (!freeArr[ingArr[i].ing_cat_index]) {
                        freeArr[ingArr[i].ing_cat_index] = [];
                    }
                    freeArr[ingArr[i].ing_cat_index].push(ingArr[i]);
                }
                if (ingArr[i].free_amount < ingArr[i].amount) {
                    for (let o = 0; o < ingArr[i].amount - ingArr[i].free_amount; o++) {
                        if (!notFreeArr[ingArr[i].ing_cat_index]) {
                            notFreeArr[ingArr[i].ing_cat_index] = [];
                        }
                        notFreeArr[ingArr[i].ing_cat_index][ingArr[i].ing_index] = ingArr[i];
                    }
                }
            } else if (ingArr[i].isfree_and_not_lunch) {
                for (let e = 0; e < ingArr[i].amount; e++) {
                    if (!freeArr[ingArr[i].ing_cat_index]) {
                        freeArr[ingArr[i].ing_cat_index] = [];
                    }
                    freeArr[ingArr[i].ing_cat_index].push(ingArr[i]);
                }
                // if (ingArr[i].free_amount < ingArr[i].amount) {
                //     for (let o = 0; o < ingArr[i].amount - ingArr[i].free_amount; o++) {
                //         if (!notFreeArr[ingArr[i].ing_cat_index]) {
                //             notFreeArr[ingArr[i].ing_cat_index] = [];
                //         }
                //         notFreeArr[ingArr[i].ing_cat_index][ingArr[i].ing_index] = ingArr[i];
                //     }
                // }
            } else {
                if (!notFreeArr[ingArr[i].ing_cat_index]) {
                    notFreeArr[ingArr[i].ing_cat_index] = [];
                }
                notFreeArr[ingArr[i].ing_cat_index][ingArr[i].ing_index] = ingArr[i];
            }
        }
        freeArr = this.resetIndexes(freeArr);
        notFreeArr = this.resetIndexes(notFreeArr);
        let notFreeArrTmp: any = [];
        for (let ab of notFreeArr) {
            notFreeArrTmp.push(this.resetIndexes(ab));
        }
        notFreeArr = notFreeArrTmp;
// console.log(freeArr);
// console.log(notFreeArr);
        let ingArrByProd: any = [];
        for (let d = 0; d < artAmmount; d++) {
            ingArrByProd[d]=[];
            for (let f of notFreeArr) {
                // let nfaSub = this.resetIndexes(notFreeArr[f]);
                for (let g of f) {
                    let gc = JSON.parse(JSON.stringify(g));
                    // gc.amount = gc.amount / artAmmount;

                    if (gc.amount <= 0 || (artAmmount > gc.amount && (d+1) > gc.amount)) {
                        continue;
                    } else if(gc.amount / artAmmount == 1) {
                        gc.amount = 1;
                    } else if (artAmmount > gc.amount && (d+1) <= gc.amount) {
                        gc.amount = 1;
                    } else if (Math.floor(gc.amount / artAmmount) == (gc.amount / artAmmount)) {
                        gc.amount = gc.amount / artAmmount;
                    } else if (artAmmount < gc.amount) {
                        let multipl = Math.floor(gc.amount / artAmmount);
                        let rest = gc.amount - (artAmmount * multipl);
                        let extra = 0;
                        if (d < rest) {
                            extra = 1;
                        }
                        gc.amount = multipl + extra;
                    }


                    ingArrByProd[d].push(gc);
                }
            }
            for (let h of freeArr) {
                if (typeof h[d] === 'undefined') {
                    continue;
                }
                // let resetedArr = this.resetIndexes(freeArr[h]);
                let hc = JSON.parse(JSON.stringify(h));
                hc[d].amount = 1;
                ingArrByProd[d].push(hc[d]);
            }
        }
        // console.log(ingArrByProd);
        return ingArrByProd;
    }

    zuButton() {
        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767)  {
        //     setTimeout( function() {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500 );
        // }
        
    }

    initiMenuArticles() {
        this.subscriptions.push(this.frontend_service.getArticleForMenu().subscribe(
            (data: any[]) => {
                this.menu_article_list = data;
                this.menuDownloaded = true;
            }));
    }
}
