<div class="row mb-4 pb-4" *ngIf="ready">
    <div class="col-12 d-flex justify-content-center align-items-center desktop-flex">
        <ng-container *ngIf="data.image_desktop.webp">
            <picture>
                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{data.image_desktop.webp[0].image}}" type="image/webp">
                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{data.image_desktop.jpg[0].image}}">
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image_desktop.webp[1].image}}" type="image/webp">
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image_desktop.jpg[1].image}}">
                <source media="(min-width: 60em)" srcset="{{image_url}}{{data.image_desktop.webp[2].image}}" type="image/webp">
                <source media="(min-width: 60em)" srcset="{{image_url}}{{data.image_desktop.jpg[2].image}}">
                <source media="(min-width: 48em)" srcset="{{image_url}}{{data.image_desktop.webp[3].image}}" type="image/webp">
                <source media="(min-width: 48em)" srcset="{{image_url}}{{data.image_desktop.jpg[3].image}}">
                <source media="(min-width: 36em)" srcset="{{image_url}}{{data.image_mobile.webp[4].image}}" type="image/webp">
                <source media="(min-width: 36em)" srcset="{{image_url}}{{data.image_mobile.jpg[4].image}}">
                <source media="(min-width: 30em)" srcset="{{image_url}}{{data.image_mobile.webp[5].image}}" type="image/webp">
                <source media="(min-width: 30em)" srcset="{{image_url}}{{data.image_mobile.jpg[5].image}}">
                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{data.image_mobile.webp[6].image}}" type="image/webp">
                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{data.image_mobile.jpg[6].image}}">
                <source srcset="{{image_url}}{{data.image_mobile.webp[7].image}}" type="image/webp">
                <source srcset="{{image_url}}{{data.image_mobile.jpg[7].image}}">
                <img src="{{image_url}}{{data.image_mobile.jpg[1].image}}" alt="" class="img-fluid">
            </picture>
        </ng-container>
    </div>
    <div class="headerBild-container mobile-flex text-center">
        <div class="category-headline-wrap w-100">
            <div class="category-title d-flex flex-row justify-content-center align-items-center w-100">
                <div class="mr-3" id="svg_icon">
                    <svg-icon src="assets/icons/icon_aktion.svg" [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                </div>
                <h1 class="static-page-title"><span>Aktion</span></h1>
            </div>
        </div>
    </div>
</div>
