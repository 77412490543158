
/**
 * @license
 * Copyright Meisterpizza Freiberg All Rights Reserved.
 */
import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { forkJoin, of } from 'rxjs';
import { FrontendService } from 'src/app/services/frontend.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { DateService } from '../../../services/date.service';
import { UserService } from 'src/app/services/user.service';
import { Anmeldung } from './welcomenDaten';
import { SessionService } from '../../../services/session.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';

/* Decorator */
@Component({
    selector: 'app-step-5',
    templateUrl: './step-5.component.html',
    encapsulation: ViewEncapsulation.None
})

/* Component Logic */
export class Step5Component implements OnInit, OnDestroy {

    /* emit true or false to show popup */
    // @Output() showPriceDifferencesPopupEmitter: EventEmitter<any> = new EventEmitter();

    /* 'addressValid' is set to true when given address inside address input component is correct and was saved to current session; then is emitted fomr child <app-address-input> */
    // addressValid = false;
    addressValid = true;
    streetValid = false;
    zipValid = false;

    /* Collect store data from api backend */
    storeData: any;
    /* Get the opening hour of current time */
    openingHoursNow: any;
    /* Get the store opening hours for display */
    openingHours: any;
    /* Get the opening hours of future date, when slecting delviery later */
    openingHoursDeliveryLater: any;
    /* Set true if the customer would like to postpone delivery to certain time or date */
    deliveryLater: boolean;
    /* Time of the actual delivery */
    deliveryTime: any;
    /* Minimum date for delivery set to today */
    minDeliveryDate: Date;
    /* Initialize deilivery date */
    deliveryDate: any;
    /* Set the delivery time to "at lunch time" - at lunch the products have different prices */
    onLunch: boolean;
    /* Set true if the customer would like to pickup order */
    pickup: boolean;
    /* Check if service has loaded api call from service and render page on success */
    ready = false;
    /* init the array which holds the possible time values for the delivery */
    deliveryLaterTimeValues: any = [];

    /* Set if shop is closed at the moment the users loads page  */
    shopClosed = false;
    shopClosedUntilTomorrow: boolean;
    /* get all cart items from service */
    cartItems = [];
    /* total price of order */
    priceCart: number;
    /* temporary cart price of order */
    priceCartTemp: number;
    /* set true if no time sleection was made to disable 'next'-button */
    noTimeSelected = true;
    /* show the popup */
    showPriceDifferencesPopup: boolean;
    showPriceDifferencesPopupOpened: boolean = false;
    no_delivery_anymore: boolean;
    no_delivery_anymore_popup: boolean;
    no_delivery_until_closed_popup: any = null;
    no_delivery_until_closed_next_time: string = '';
    next_closed_time: string;
    initDataInterval: any;
    closedInThisDate: any;
    next_delivery_later_time: any = false;
    editStep = false;
    showFieldsV = false;
    private subscriptions: Subscription[] = [];

    // anmeldung: Anmeldung = {
    //     edit: false,
    //     showBtnBestellen: false,
    //     showBtnWarenkorb: false,
    //     disableBestellen: true,
    //     disableWarenkorb: true,
    //     valueLiefern: "null",
    //     valueHolen: "null",
    //     isLiefern: false,
    //     isHolen: false,
    // };
    anmeldung: Anmeldung;

    buttonValue = "null";
    showPopup_no_delivery_until_closed_popup = false;
    seenPopup_no_delivery_until_closed_popup = false;
    noMoreDeliveryLunch = false;
    noMoreDelivery = false;
    noMoreDeliveryLunchToday = false;
    noMoreDeliveryToday = false;
    showPopupNow = false;
    timeValuesCreated = false;
    isDelivDateNow = false;
    devVar = false;
    bttnVal = false;


    /**
     * Component constructor which provides service classes
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    constructor(
        private userService: UserService,
        private frontendService: FrontendService,
        private cartService: CartService,
        private location: Location,
        private router: Router,
        private dateService: DateService,
        private pageScrollService: PageScrollService,
        public sessionService: SessionService,
        @Inject(DOCUMENT) private document: any
    ) {

        this.initData();
        this.initDataInterval = setInterval(data => {
            this.cartService.checkShopOpenOrClosed().then(() => {
                this.updateTimes();
            });


        }, 60000);
    }

    initData() {
        this.no_delivery_anymore_popup = false;
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            this.frontendService.getStoreData(),
            this.frontendService.getOpeningHoursNow(),
            this.frontendService.getOpeningHours(1),
            of(this.cartService.deliveryLaterObservable),
            of(this.cartService.on_pickup_observable),
            of(this.cartService.priceCartObservable),
            of(this.cartService.shopClosedObservable),
            of(this.cartService.article_group_list_observable),
            of(this.cartService.deliveryLaterTimeValuesObservable),
            of(this.cartService.onLunchObservable),
            of(this.cartService.deliveryDateObservable),
            of(this.cartService.deliveryTimeObservable),
            of(this.cartService.showPriceDifferencesPopupObservable),
            of(this.cartService.priceCartTempObservable),
            of(this.cartService.shopClosedUntilTomorrowObservable),
            of(this.userService.addressValidObservable),
            of(this.userService.streetValidObservable),
            of(this.userService.zipValidObservable),
            of(this.cartService.noMoreDeliveryObservable),
            of(this.cartService.noMoreDeliveryLunchObservable)
        ])
        .subscribe(
            data => {
                this.distributeServiceDataOnSuccess(data);
            },
            err => console.error(err)
        );

    }

    /**
     * Call angular lifecycle hook on init
     */
    ngOnInit() {
        const today: Date = this.dateService.newDate();
        const dateArr = { 'year': today.getFullYear(), 'month': today.getMonth() + 1, 'day': today.getDate() };
            const todayDate = dateArr.day + '-' + dateArr.month + '-' + dateArr.year;
        this.frontendService.getOpeningHoursArr('delivery', todayDate).then((data) => {
            this.noMoreDeliveryToday = data['noMoreDelivery'] ? data['noMoreDelivery'] : false;
            this.noMoreDeliveryLunchToday = data['noMoreDeliveryLunch'] ? data['noMoreDeliveryLunch'] : false;
        });
        this.showFieldsV = false;
        this.subscriptions.push(this.cartService.deliveryDateObservable.subscribe(data => {
            this.deliveryDate = data;
        }));
        /* get delivery time form service */
        this.subscriptions.push(this.cartService.deliveryTimeObservable.subscribe(data => {
            this.deliveryTime = data;
        }));

        this.getAnmeldung();
        if (this.anmeldung.edit) {
            var visible = this.anmeldung.isHolen || this.anmeldung.isLiefern;
            if (visible) {
                this.sessionService.showBtnWarenkorb();
            }
        } else {
            this.initDeliveryDateAndTime();
            this.sessionService.showBtnBestellen();
        }

        if (this.router.url.includes('edit')) {
            this.editStep = true;
            // this.anmeldung.valueHolen = '';
            // this.anmeldung.valueLiefern = '';
            const scroller = this.pageScrollService;
            const scrollerDoc = this.document;
            setTimeout( function() {
                scroller.scroll({
                    document: scrollerDoc,
                    scrollTarget: '#datum_datum',
                    scrollOffset: 40,
                    duration: 500
                });
            }, 1500 );
        } else {
            this.editStep = false;
        }
        this.subscriptions.push(this.cartService.timeValuesCreatedObservable.subscribe(data => {
            this.timeValuesCreated = data;
        }));
        this.subscriptions.push(this.cartService.isDelivDateNowObservable.subscribe(data => {
            this.isDelivDateNow = data;
        }));
        this.cartService.checkShopOpenOrClosed().then(() => {
            this.updateTimes();
        });
    }

    /**
     * Call angular lifecycle hook on destroy
     */
    ngOnDestroy() {

        clearInterval(this.initDataInterval);
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }

    }

    // async getIngredients() {
    //     const ingredientsByProductSubCategory = await this.frontendService.getIngredientsByProductSubCategory(4);
    //     return ingredientsByProductSubCategory;
    // }

    /**
     * Distribute the service data to the corresponding attributes
     */
    distributeServiceDataOnSuccess(data: any) {

        /* distribute the api call response to the corresponding vars */
        this.storeData = data[0];
        this.openingHoursNow = data[1];
        this.openingHours = data[2];
        /* subscribe to observables */
        data[3].subscribe((event: boolean) => this.deliveryLater = event);
        data[4].subscribe((event: boolean) => this.pickup = event);
        data[5].subscribe((event: number) => this.priceCart = event);
        data[6].subscribe((event: boolean) => this.shopClosed = event);
        data[7].subscribe((event: any) => this.cartItems = event);
        data[8].subscribe(data => {
            if (typeof data !== 'undefined') {
                this.subscriptions.push(this.frontendService.getOpeningHoursNow(this.deliveryDate).subscribe((dataOT: any) => {

                    this.openingHoursNow = dataOT;
                    let queryDate = null;
                    if (typeof dataOT[0] != 'undefined') {
                        queryDate = dataOT[0].queryDate;
                    }
                    if (this.pickup && data.constructor === Array && data.length === 0) {
                        this.setDeliveryTomorrow(true, queryDate);
                        return;
                    }
                    let oldTimes = JSON.stringify(this.deliveryLaterTimeValues);
                    let newTimes = data;
                    if (oldTimes != newTimes) {
                        this.deliveryLaterTimeValues = data;
                        if (this.noTimeSelected && this.deliveryLaterTimeValues && this.deliveryLaterTimeValues.length && this.deliveryLaterTimeValues[0].time && !this.editStep) {
                            this.deliveryTime =  this.deliveryLaterTimeValues[0].time;
                        }
                        const today: Date = this.dateService.newDate();
                        today.setHours(0);
                        today.setMinutes(0);
                        today.setSeconds(0);
                        today.setMilliseconds(0);

                        const deliveryDate: Date = new Date(this.deliveryDate.getTime());
                        deliveryDate.setHours(0);
                        deliveryDate.setMinutes(0);
                        deliveryDate.setSeconds(0);
                        deliveryDate.setMilliseconds(0);

                        /**
                         * Keine Zeiten für die gewählte Lieferzeit
                         */
                        if (data.length > 0) {

                            /**
                             * Vorbestellzeiten sind heute noch verfügbar
                             */

                            if (today.getTime() === deliveryDate.getTime()) {

                                this.no_delivery_anymore_popup = false;
                                if (this.shopClosed && !this.shopClosedUntilTomorrow) {
                                    this.next_delivery_later_time = data[0].time.substring(0, 5);
                                    this.cartService.next_delivery_later_time = this.next_delivery_later_time;
                                }
                                for (const openingHour in this.openingHoursNow) {
                                    const open_from = this.dateService.newDate();
                                    // tslint:disable-next-line: radix
                                    open_from.setHours(parseInt(this.openingHoursNow[openingHour].time_from.substring(0, 2)));
                                    // tslint:disable-next-line: radix
                                    open_from.setMinutes(parseInt(this.openingHoursNow[openingHour].time_from.substring(3, 5)));

                                    const open_to = this.dateService.newDate();
                                    // tslint:disable-next-line: radix
                                    open_to.setHours(parseInt(this.openingHoursNow[openingHour].time_to.substring(0, 2)));
                                    // tslint:disable-next-line: radix
                                    open_to.setMinutes(parseInt(this.openingHoursNow[openingHour].time_to.substring(3, 5)));

                                    const now = this.dateService.newDate();
                                    const next_delivery_later_date = this.dateService.newDate();
                                    // tslint:disable-next-line: radix
                                    next_delivery_later_date.setHours(parseInt(data[0].time.substring(0, 2)));
                                    // tslint:disable-next-line: radix
                                    next_delivery_later_date.setMinutes(parseInt(data[0].time.substring(3, 5)));

                                    this.no_delivery_until_closed_next_time = data[0].time;

                                    if (now > open_from && now < open_to && (next_delivery_later_date > open_to) && this.no_delivery_until_closed_popup === null) {
                                        this.no_delivery_until_closed_popup = true;
                                        this.no_delivery_until_closed_next_time = data[0].time;
                                    }
                                    if (now > open_from && now < open_to) {
                                        this.next_closed_time = this.openingHoursNow[openingHour].time_to.substring(0, 5);
                                    }
                                }
                            }

                        } else {
                            this.onLunch = false;

                            /**
                             * Die Lieferzeit ist nicht für heute oder der Shop ist heute nicht mehr geöffnet
                             */

                            if (today.getTime() !== deliveryDate.getTime()) {
                                this.no_delivery_anymore_popup = false;
                                this.setDeliveryTomorrow(false, queryDate);
                                /**
                                 * Dem Kunden anbieten, dass er noch sofort bestellen kann oder ab morgen
                                 */
                            } else {

                                if (this.shopClosedUntilTomorrow) {
                                    this.setDeliveryTomorrow(false, queryDate);
                                    this.no_delivery_anymore_popup = false;
                                } else {

                                    if (today.getTime() === deliveryDate.getTime()) {

                                        for (const openingHour in this.openingHoursNow) {

                                            if (!this.openingHoursNow[openingHour].time_from) {
                                                this.openingHoursNow[openingHour].time_from = '00:01';
                                            }
                                            if (!this.openingHoursNow[openingHour].time_to) {
                                                this.openingHoursNow[openingHour].time_to = '00:02';
                                            }

                                            const open_from = this.dateService.newDate();
                                            // tslint:disable-next-line: radix
                                            open_from.setHours(parseInt(this.openingHoursNow[openingHour].time_from.substring(0, 2)));
                                            // tslint:disable-next-line: radix
                                            open_from.setMinutes(parseInt(this.openingHoursNow[openingHour].time_from.substring(3, 5)));

                                            const open_to = this.dateService.newDate();
                                            // tslint:disable-next-line: radix
                                            open_to.setHours(parseInt(this.openingHoursNow[openingHour].time_to.substring(0, 2)));
                                            // tslint:disable-next-line: radix
                                            open_to.setMinutes(parseInt(this.openingHoursNow[openingHour].time_to.substring(3, 5)));

                                            const now = this.dateService.newDate();
                                            const next_delivery_later_date = this.dateService.newDate();


                                            if (now > open_from && now < open_to) {
                                                this.next_closed_time = this.openingHoursNow[openingHour].time_to.substring(0, 5);
                                            }
                                        }
                                    }

                                    this.no_delivery_anymore_popup = true;
                                }

                            }

                        }
                    }
                }));
            }

        }, err => console.error(err));
        data[9].subscribe((event: any) => this.onLunch = event);
        data[10].subscribe((event: any) => this.deliveryDate = event);
        data[11].subscribe((event: any) => this.deliveryTime = event);
        data[12].subscribe((event: any) => {
            this.showPriceDifferencesPopup = event;

            if (event === true && this.showPriceDifferencesPopupOpened === false) {
                this.showPriceDifferencesPopupOpened = true;
            }


        });
        data[13].subscribe((event: any) => this.priceCartTemp = event);
        data[14].subscribe((event: any) => this.shopClosedUntilTomorrow = event);

        data[15].subscribe((event: boolean) => this.updateAddressValid(event));
        data[16].subscribe((event: boolean) => this.updateStreetValid(event));
        data[17].subscribe((event: boolean) => this.updateZipValid(event));
        data[18].subscribe((event: boolean) => {
            this.noMoreDelivery = event;
            if (this.noMoreDelivery && !this.seenPopup_no_delivery_until_closed_popup) {
                this.showPopup_no_delivery_until_closed_popup_f(true);
            }
        });
        data[19].subscribe((event: boolean) => {
            this.noMoreDeliveryLunch = event;
            if (this.noMoreDeliveryLunch && !this.seenPopup_no_delivery_until_closed_popup) {
                this.showPopup_no_delivery_until_closed_popup_f(true);
            }
        });
        /** set page ready when finished */


        this.ready = true;

        this.closedInThisDate = this.openingHoursNow[0].closedInThisDate;

        this.updateTimes();

        // this.initDataInterval = setInterval(data => {
        //     this.cartService.checkShopOpenOrClosed().then(() => {
        //         this.updateTimes();
        //     });
        // }, 5000);

    }

    updateTimes() {

        if (this.shopClosed) {
            this.onSetDeliveryLater(true);
        }

        const now = this.dateService.newDate();
        let time: any = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');

        if (sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.cartService.setDeliveryDateAndTime(now, time);
        }


        if (this.anmeldung.edit) {
            time = sessionStorage.getItem('deliveryTime');
        }

        const tomorrow = this.dateService.newDate();
        tomorrow.setDate(this.dateService.newDate().getDate() + 1);
        tomorrow.setHours(0,0,0,1);

        if (this.shopClosedUntilTomorrow && this.deliveryDate.getTime() < tomorrow.getTime()) {
        // if (this.shopClosedUntilTomorrow) {
            this.minDeliveryDate = tomorrow;
            if (!sessionStorage.getItem('deliveryTime')) {
                this.cartService.setDeliveryDateAndTime(this.minDeliveryDate, time);
            }
        } else {
            this.minDeliveryDate = this.dateService.newDate();
            if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
                this.cartService.setDeliveryDateAndTime(this.minDeliveryDate, time);
            } else {
                const selectedDate = new Date(sessionStorage.getItem('deliveryDate'));
                if (typeof this.deliveryTime == 'undefined' || this.deliveryTime == '') {
                    this.cartService.setDeliveryDateAndTime(selectedDate, time);
                } else {
                    this.cartService.setDeliveryDateAndTime(selectedDate, this.deliveryTime);
                }

            }
            if (!this.deliveryLaterTimeValues) {
                this.minDeliveryDate = tomorrow;
            }
        }
    }

    getAnmeldung(): void {
        this.sessionService.getAnmeldung().subscribe(
            objTmp => this.updateAnmeldung(objTmp)
        )
    }

    updateAnmeldung(obj: Anmeldung) {
        this.anmeldung = obj;
    }

    updateStreetValid(value: boolean) {
        this.streetValid = value;
        this.checkDisableButtons();
    }

    updateZipValid(value: boolean) {
        this.zipValid = value;
        this.checkDisableButtons();
    }

    updateAddressValid(value: boolean) {
        // this.addressValid = value;
        this.addressValid = true;
        this.checkDisableButtons();
    }

    checkDisableButtons() {
        // var wohinValid = this.addressValid && this.zipValid;
        // wohinValid = wohinValid && this.streetValid;
        var wohinValid = true;
        var enable = wohinValid || this.anmeldung.isHolen;
        this.sessionService.setDisableButtons(!enable);
    }

    initDeliveryDateAndTime() {

        const now = this.dateService.newDate();
        const time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        this.cartService.setDeliveryDateAndTime(now, time);

        // this.cartService.setDeliveryDateAndTime(now, time).then(() => {
            // if (this.anmeldung.edit) {
                // if (!this.showPriceDifferencesPopup) {
                //     this.router.navigate(['/shop/bestellen/warenkorb']);
                // }
            // }
        // });

    }



    // onSetDeliveryLater(deliveryLater: boolean) {
    //     this.deliveryLater = deliveryLater;
    //     this.cartService.setDeliveryLater(deliveryLater);
    //     sessionStorage.setItem('deliveryLater', deliveryLater === true ? '1' : '0');
    // }


    /**
     * Set the delivery date for the current order
     * @param date The given date object
     */
    onChangeDeliveryDate(date: any) {
        if (!this.showFieldsV) {
            this.showFieldsV = true;
        }
        if (!this.deliveryLater) {
            this.onSetDeliveryLater(true);
        }
        this.timeValuesCreated = false;
        this.deliveryDate = date;
        if (!this.deliveryTime) {
            this.deliveryTime = '00:00';
        }
        this.cartService.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime).then(() => {
            this.cartService.checkLunchTime();
        });
        sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
    }

    /**
     * Set the delivery time for the current order
     */
    onChangeDeliveryTime() {
        this.cartService.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime);
        this.noTimeSelected = false;
    }

    /**
     * Go back in in page history
     */
    onGoBack() {
        this.location.back();
    }

    setDeliveryTomorrow(setSeen = false, dateSetTo = '') {
        if (!dateSetTo) {
            dateSetTo = this.deliveryDate;
        }
        if (setSeen) {
            this.showPopup_no_delivery_until_closed_popup_f(true);
        }
        this.no_delivery_anymore_popup = false;

        const now = this.dateService.newDate();
        now.setSeconds(0);
        now.setMinutes(0);
        now.setMilliseconds(0);
        now.setHours(0);
        const today = this.dateService.newDate();
        const tomorrow = new Date(dateSetTo);
        tomorrow.setSeconds(0);
        tomorrow.setMinutes(0);
        tomorrow.setMilliseconds(0);
        tomorrow.setHours(0);
        if (tomorrow.getTime() - now.getTime() > 86400000 * 7) {
            return;
        }
        // const tomorrow = new Date(today);
        tomorrow.setTime(tomorrow.getTime() + 86400000);
        this.minDeliveryDate = tomorrow;
        this.cartService.setDeliveryDateAndTime(tomorrow, '00:00');
        this.showFieldsV = true;
    }

    setDeliveryNow() {
        this.onSetDeliveryLater(true);
        this.no_delivery_anymore_popup = false
        if (this.pickup) {
            this.router.navigate(['/shop']);
        } else {
            this.router.navigate(['/willkommen/start']);
        }
    }

    confirmPriceDifference() {
        this.router.navigate(['/shop/bestellen/warenkorb']);
        this.showPriceDifferencesPopup = false;
    }

    /**
     * Log component for debugging purposes
     */
    onLog() {
    }

    onChangePickup(pickup: boolean) {
        if (!pickup && (this.noMoreDeliveryLunchToday || this.noMoreDeliveryToday)) {
            this.bttnVal = true;
        }
        // let bttnVal = this.bttnVal;
        this.cartService.setLunch(false);
        this.devVar = true;
        sessionStorage.setItem('pickupChanged', '0');
        const scroller = this.pageScrollService;
        const scrollerDoc = this.document;
        this.showFieldsV = false;
        const now = this.dateService.newDate();
        let time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        if (this.editStep) {
            time = this.deliveryTime;
        } else {
            this.deliveryTime = time;
        }

        // this.pickup = pickup;

        sessionStorage.setItem('pickup', pickup === true ? '1' : '0');
        sessionStorage.setItem('deliveryTime', time);
        sessionStorage.setItem('deliveryTypeSelected', '1');
        this.noTimeSelected = true;
        this.cartService.setPickup(pickup);
        this.cartService.setDeliveryDateAndTime(now, time).then(() => {
            this.cartService.checkLunchTime();
            if (!this.anmeldung.edit) {
                this.sessionService.showBtnBestellen();
            }
            if (!pickup) {
                this.sessionService.setLiefern();
                this.onSetPickup(false);
                // this.showFields();
                setTimeout( function() {
                    scroller.scroll({
                        document: scrollerDoc,
                        scrollTarget: '.target-scroll-pikup',
                        scrollOffset: 40,
                        duration: 500
                    });
                    // if (bttnVal) {
                    //     this.document.getElementById('spt').value = "aktiv";
                    // }
                }, 500 );
            } else {
                this.sessionService.setHolen();
                this.onSetPickup(true);
                this.showFields();
                setTimeout( function() {
                    scroller.scroll({
                        document: scrollerDoc,
                        scrollTarget: '.target-scroll-pikup',
                        scrollOffset: 40,
                        duration: 500
                    });
                }, 500 );
            }
            this.checkDisableButtons();
        });
    }

    onSetPickup(pickup: boolean) {
        // this.pickup = pickup;
        // this.cartService.setPickup(pickup);
        // sessionStorage.setItem('pickup', pickup === true ? '1' : '0');
        // 0-liefern; 1-selbst

        if (this.pickup) {
            sessionStorage.removeItem('tempUser');
        }

        this.frontendService.getOpeningHoursNow().subscribe( data => {
            this.cartService.openingHoursNow = data;
            this.cartService.checkShopOpenOrClosed().then(() => {
                this.updateTimes();
            });
        });
    }

    get getEnviorment() {
        if (window.location.href.indexOf('pizza.awag') > 0 || window.location.href.indexOf('pizza-dd.awag') > 0) {
            return 'dev';
        }

        if (window.location.href.indexOf('stage') > 0) {
            return 'stage';
        }

        if (window.location.href.indexOf('meisterpizza.de') > 0) {
            return 'prod';
        }
    }

    weiter() {
        const scroller = this.pageScrollService;
        const scrollerDoc = this.document;
        setTimeout( function() {
            scroller.scroll({
                document: scrollerDoc,
                scrollTarget: '.step3-mp-container',
                scrollOffset: 40,
                duration: 500
            });
        }, 500 );
    }

    onSetDeliveryLater(deliveryLater: boolean) {
        this.deliveryLater = deliveryLater;
        this.cartService.setDeliveryLater(deliveryLater);
        sessionStorage.setItem('deliveryLater', deliveryLater === true ? '1' : '0');

        if (!deliveryLater) {
            const now = this.dateService.newDate();
            const time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
            this.deliveryDate = now;
            this.deliveryTime = time;
            this.cartService.setDeliveryDateAndTime(now, time).then(() => {
                if (this.editStep) {
                    if (!this.showPriceDifferencesPopup) {
                        this.router.navigate(['/shop/bestellen/warenkorb']);
                    }
                }
            });
        }
    }

    showFields(popap = false, sofort = false) {
        if (popap) {
            this.showPopup_no_delivery_until_closed_popup_f(true, true);
        }
        if (sofort) {
            sessionStorage.setItem('sofortAfterPopapClick', '1');
            clearInterval(this.initDataInterval);
            this.onSetDeliveryLater(false);
            this.router.navigate(['/shop']);
            return;
        }
        /*this.onSetDeliveryLater(deliveryLater);*/
        this.showFieldsV = true;
        const scroller = this.pageScrollService;
        const scrollerDoc = this.document;
        setTimeout( function() {
            scroller.scroll({
                document: scrollerDoc,
                scrollTarget: '.stpZt',
                scrollOffset: 40,
                duration: 500
            });
        }, 500 );
    }

    showPopup_no_delivery_until_closed_popup_f(state, show = false) {
        this.showPopup_no_delivery_until_closed_popup = state;
        if (show) {
            this.showPopupNow = show;
            // this.document.getElementById('spt').value = "aktiv";
        }
        if (!state) {
            this.seenPopup_no_delivery_until_closed_popup = true;
        }
        if (this.noMoreDeliveryToday && !this.pickup) {
            // this.setDeliveryTomorrow();
        }
    }

    selectedDateClass = (dateFull): MatCalendarCellCssClasses => {
        let delivType = 'closedDatesDelivery';
        if (this.pickup) {
            delivType = 'closedDatesPickup';
        }
        let closedDates = JSON.parse(sessionStorage.getItem(delivType));
        let dateToCompire = dateFull.getFullYear() + '-' + ('0' + (dateFull.getMonth() + 1)).slice(-2) + '-' + ('0' + (dateFull.getDate())).slice(-2);
        let isset = closedDates.includes(dateToCompire);
        return isset ? 'closedDay' : '';
    };

    dayFilter = (d: Date): boolean => {
        let dateToCompire = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2);
        let delivType = 'closedDatesDelivery';
        if (this.pickup) {
            delivType = 'closedDatesPickup';
        }
        let closedDates = JSON.parse(sessionStorage.getItem(delivType));
        let isset = closedDates.includes(dateToCompire);
        return !isset;
    }

}
