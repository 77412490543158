import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {url} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MainService {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Local Environment
    // frontend_url = 'http://maho.meisterpizza.local/api';
    // image_url = 'http://maho.meisterpizza.local/images';
    // submit_url = 'http://maho.meisterpizza.local/direct-us/server/order_server.php';
    // adress_url = 'http://maho.meisterpizza.local/api';

    // SPaces Server
    // frontend_url: string = 'https://maho-gastronomie.de/api';
    // image_url: string = 'https://maho-gastronomie.de/images';
    // submit_url: string = 'https://maho-gastronomie.de/direct-us/server/order_server.php';
    // adress_url: string = 'https://maho-gastronomie.de/api';

    // Mittwald Staging Server
    frontend_url = url.frontend;
    image_url = url.image;
    submit_url = url.submit;
    adress_url = url.adress;

    // config stuff - locale (old)
    // frontend_url: string = 'http://localhost/directus/server/shop_server.php';
    // submit_url: string = 'http://localhost/directus/server/order_server.php';
    // image_url: string = 'http://localhost/directus/';
    // adress_url: string ='http://localhost/directus/server/adress-server.php';

    on_pickup: boolean = true;

    // Zeitspanne: wenn shop weniger als x min offen hat, dann kommt dialog
    preorder_span: number = 14;

    // active Effekt Navigation
    private current_page_id: number = -1;
    private current_page_id_subject = new ReplaySubject<number>(1);
    current_page_id_observable = this.current_page_id_subject.asObservable();
    public cats_svg_arr = [];

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // constructor
    constructor() {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // functions stuff

    setCurrentPageID(new_page_id: number) {
        this.current_page_id = new_page_id;
        this.current_page_id_subject.next(this.current_page_id);
    }

    getFrontendUrl() {
        return this.frontend_url;
    }

    getSubmitUrl() {
        return this.submit_url;
    }




}
