<div class="static-page-layout lunch-page">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="data && data.image && data.image.slice(-6) != '/admin'" class="header-background-img header-wrapper headerBild d-flex justify-content-center align-items-center desktop-flex"
                 [style.backgroundImage]="'url(' + data.image + ')'">
                <h1 class="static-page-title">{{data.title ? data.title : "Mittags-Angebot"}}</h1>
            </div>
            <div *ngIf="!data || !data.image || data.image.slice(-6) == '/admin'" class="header-background-img header-wrapper headerBild d-flex justify-content-center align-items-center desktop-flex"
                [style.backgroundImage]="'url(' + getHeaderBild() + ')'">
                <h1 class="static-page-title">{{data.title ? data.title : "Mittags-Angebot"}}</h1>
            </div>
            <div class="headerBild-container mobile-flex text-center">
                <div class="category-headline-wrap w-100">
                    <div class="category-title d-flex flex-row justify-content-center align-items-center w-100">
                        <div class="mr-3" id="svg_icon">
                            <svg-icon src="assets/icons/icon_clock.svg" [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                        </div>
                        <h1 class="static-page-title">{{(data && data.title) ? data.title : "Mittags-Angebot"}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="center-content">
                    <h2 *ngIf="data && data.description" style="margin: 40px 0" class="text-center" [innerHtml]="data.description|safeHtml "></h2>
                    <h2 *ngIf="!data || !data.description"  style="margin: 40px 0" class="text-center">Das Mittags-Angebot ist bestellbar von: <br>
                        <strong>Dienstag - Freitag, 10:30 - 13:30 Uhr (außer an Feiertagen)</strong>                    
                    </h2>
                </div>
            </div>

            <div class="article-list-row row" *ngIf="data && data.products && data.products.length > 0">                
                    <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box" *ngFor="let product of data.products, let i_p = index" >
                        <div *ngIf="product.image && product.image.slice(-6) != '/admin'" class="article-box-inner-wrap" [style.backgroundImage]="'url(' + product.image + ')'"></div>
                        <div *ngIf="!product.image || product.image.slice(-6) == '/admin'" class="article-box-inner-wrap" ></div>
                        <div *ngIf="product.prices && product.prices.length > 0" class="article-box-cta article-box-btns-{{product.prices.length}} bg-black-dark">   
                            <a *ngFor="let price of product.prices" routerLink="{{product.link}}"> 
                                {{price.name ? price.name : '&nbsp;'}}<br>
                                <strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg" class="d-flex icon {{product.prices.length < 2 ? 'icon--sm' : ''}} mr-2"></svg-icon> {{price.price ? price.price :'12.50'}} €</strong>
                            </a>
                        </div>
                        <div class="d-flex flex-column justify-content-between py-2">
                            <div class="article-list-sub-content">
                                <h4 class="mp-font-large-yellow text-center article-name"><span>{{ product.title ? product.title : 'Leckere Produkte' }}</span></h4>
                                <p class="sub mp-font-regular-white">{{product.description? product.description :''}}</p>
                            </div>
                            <a routerLink="{{product.link}}" class="mp-btn mp-btn-narrow bestellen-btn">{{ product.button_text? product.button_text :'lecker bestellen' }}</a>
                        </div>
                        
                    </div>      
                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box discount_img" >
                    <img class="mw-100 mh-100" src="/assets/img/MP_dashbord/Kategoriebilder_Startseite_Desktop_375x520px_10Prozent.jpg">
                </div>
            </div>

            <div class="article-list-row row" *ngIf="!data || !data.products || data.products.length < 1">
                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box ">

                     <!-- <div class="article-box-inner-wrap pizza">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/Pizza"> jede Pizza
                                <br><strong>12,99 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/Pizza"> jede Pizza
                                <br><strong>12,99 €</strong></a>
                        </div>
                    </div>  -->
                    <div class="article-box-inner-wrap pizza"></div>
                    <div class="article-box-cta article-box-btns-1 bg-black-dark">                         
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/Pizza">jede Pizza
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg" class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/Pizza">jede Pizza
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg" class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                    </div>
                    <div class="d-flex flex-column justify-content-between py-2">
                        <div class="article-list-sub-content">
                            <h4 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Pizza</span></h4>
                            <p class="sub mp-font-regular-white">- Aus unserem Pizzasortiment -</p>
                        </div>
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/Pizza"
                           class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/Pizza"
                           class="mp-btn mp-btn-narrow bestellen-btn ">lecker bestellen</a>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box">

                    <!-- <div class="article-box-inner-wrap burger">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/13/Burger"> jeder Burger
                                <br><strong>13,49 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/98/Burger"> jeder Burger
                                <br><strong>13,49 €</strong></a>
                        </div>

                    </div> -->
                    <div class="article-box-inner-wrap burger"></div>
                    <div class="article-box-cta article-box-btns-1 bg-black-dark">
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/13/Burger"> jeder Burger
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/98/Burger"> jeder Burger
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                    </div>
                    <div class="d-flex flex-column justify-content-between py-2">
                        <div class="article-list-sub-content">
                            <h4 class="mp-font-large-yellow text-center article-name"><span
                                    class="text-uppercase">Burger</span> + gratis Fries mit Dip</h4>
                            <p class="sub mp-font-regular-white">- Aus unserem Burgersortiment -</p>
                        </div>
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/13/Burger"
                        class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/98/Burger"
                        class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                    </div>
                    
                </div>

                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box">
                    <!-- <div class="article-box-inner-wrap salad">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/14/Salat"> jeder Salat
                                <br><strong>12,99 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/99/Salat"> jeder Salat
                                <br><strong>12,99 €</strong></a>
                        </div>
                    </div>  -->
                    <div class="article-box-inner-wrap salad"></div>
                    <div class="article-box-cta article-box-btns-1 bg-black-dark">
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/14/Salat"> jeder Salat
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/99/Salat"> jeder Salat
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                    </div>
                    <div class="d-flex flex-column justify-content-between py-2">
                        <div class="article-list-sub-content">
                            <h4 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Salat</span> +
                                Salatbrötchen & Dressing</h4>
                            <p class="sub mp-font-regular-white">- Aus unserem Salatsortiment -</p>
                        </div>
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/14/Salat" class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/99/Salat" class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                    </div>
                    
                </div>

                
                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box ">

                    <!-- <div class="article-box-inner-wrap currywurst">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jede Currywurst
                                <br><strong>12,99 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jede Currywurst
                                <br><strong>12,99 €</strong></a>
                        </div>
                    </div> -->
                    <div class="article-box-inner-wrap currywurst"></div>
                    <div class="article-box-cta article-box-btns-1 bg-black-dark">
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jede Currywurst
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jede Currywurst
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                    </div>
                    <div class="d-flex flex-column justify-content-between py-2">
                        <div class="article-list-sub-content">
                            <h4 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Riesen Currywurst</span>
                            </h4>
                            <p class="sub mp-font-regular-white">- Aus unserem Currywurstsortiment -</p>
                        </div>
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"
                        class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"
                        class="mp-btn mp-btn-narrow bestellen-btn ">lecker bestellen</a>
                    </div>
                    
                </div>

                
                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box ">

                    <!-- <div class="article-box-inner-wrap schnitzel">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jeder Schnitzel
                                <br><strong>12,99 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jeder Schnitzel
                                <br><strong>12,99 €</strong></a>
                        </div>
                    </div>  -->
                    <div class="article-box-inner-wrap schnitzel"></div>
                    <div class="article-box-cta article-box-btns-1 bg-black-dark">
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jeder Schnitzel
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jeder Schnitzel
                            <br><strong class="d-flex flex-row justify-content-between align-items-center"><svg-icon src="assets/icons/warenkorb_neu.svg"
                                class="d-flex icon icon--sm mr-2"></svg-icon> 12,50 €</strong></a>
                    </div>
                    <div class="d-flex flex-column justify-content-between py-2">
                        <div class="article-list-sub-content">
                            <h4 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Meister-Schnitzel</span>
                                </h4>
                            <p class="sub mp-font-regular-white">- Aus unserem Schnitzelsortiment -</p>
                        </div>
                        <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"
                        class="mp-btn mp-btn-narrow bestellen-btn">lecker bestellen</a>
                        <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"
                        class="mp-btn mp-btn-narrow bestellen-btn ">lecker bestellen</a>
                    </div>
                    
                </div>

                <div class="col-12 col-sm-6 col-lg-4 col-xxl-4 col-centered article-list-wrap article-box discount_img">
                    <img class="mw-100 mh-100" src="/assets/img/MP_dashbord/Kategoriebilder_Startseite_Desktop_375x520px_10Prozent.jpg">
                </div>
            </div>
        </div>

    </div>
</div>
