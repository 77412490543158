import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from "rxjs/internal/Subscription";
import { FrontendService } from "../../../../services/frontend.service";
import { IngredientList } from "../../../../../_classes/IngredientList";
import { Ingredient } from "../../../../../_classes/Ingredient";
import { MainService } from 'src/app/services/main.service';
import {DOCUMENT} from '@angular/common';
import {PageScrollService} from 'ngx-page-scroll-core';

@Component({
    selector: 'app-add-free-ingredients',
    templateUrl: './add-free-ingredients.component.html',
    styleUrls: ['./add-free-ingredients.component.scss']
})
export class AddFreeIngredientsComponent implements OnInit, OnDestroy {
    //public stuff  - - - - - - - - - - - - - - - -
    @Input() sub_cat_id: number;                                // ID der Unterkategorie
    @Input() ingredient_list: any[];                            // hinzufügbare Zutaten (sortiert nach Zutatenkategorie)
    @Input() current_size_index: number;
    @Input() added_ing_list: IngredientList[] = [];             // beinhaltet alle hinzugefügten Zutaten
    @Input() price_ing_sum: number[] = [];                     // Array mit summierten Preisen für die hinzugefügten Zutaten
    @Input() amount: any[];                                     //[ing_cat][ing_index] - datenstruktur der ingredient_list, beinhaltet Anzehl + Summe
    @Input() ingredientListLoaded: boolean;
    @Input() onLunch: boolean;
    @Input() show_tab_of_free_addables: number;

    @Output() changed_ing_emitter = new EventEmitter();         // informiert über hinzugefügte Zutat
    @Output() sub_ing_emitter = new EventEmitter<number>();     // informiert über dezimierung Anzahl Zutat
    @Output() add_ing_emitter = new EventEmitter<any>();     // informiert über dezimierung Anzahl Zutat
    @Input() potatoAdded: boolean;


    ready = false;                                              // true, wenn alle relevanten Daten geladen
    isAdded: boolean = false;
    image_url: string;
    not_loaded_div_height = '';

    /* show the info box for the ingredient */
    showInfoBox = false;
    /* holds the current infobox ingredient information */
    infoBoxIngredient: any;


    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //init functions
    ngOnInit() {
        this.image_url = this.main_service.image_url;
        this.ready = true;

        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767) {
        //     setTimeout(function () {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500);
        // }
    }

    ngOnDestroy() {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {

    }

    onAddIng(ing_cat_index: number, ing_index: number) {
        let divIngs = this.document.querySelector('body');
        this.not_loaded_div_height = divIngs.offsetHeight + 'px';
        // check if item is already in array
        const index = this.includeIngredient(this.ingredient_list[ing_cat_index].ingredients[ing_index]);

        // show overlay for item when added
        document.getElementById('item-added-' + ing_index).classList.remove('item-added-hidden');
        setTimeout(() => {
            document.getElementById('item-added-' + ing_index).classList.add('item-added-hidden')
        }, 1000);
        this.add_ing_emitter.emit({index: index, ing_cat_index: ing_cat_index, ing_index: ing_index});
    }

    // sub ing -> calls function in parent class
    onSubIng(ing_cat_index: number, ing_index: number) {
        let divIngs = this.document.querySelector('body');
        this.not_loaded_div_height = divIngs.offsetHeight + 'px';
        const index = this.includeIngredient(this.ingredient_list[ing_cat_index].ingredients[ing_index]);


        // show overlay for item when removed
        document.getElementById('item-removed-' + ing_index).classList.remove('item-removed-hidden');
        setTimeout(() => {
            document.getElementById('item-removed-' + ing_index).classList.add('item-removed-hidden')
        }, 1000);
        // call method of parent to remove ingredient
        this.sub_ing_emitter.emit(index);
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getIngredientByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxIngredient = data;
            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

    // no usage
    // private resetIndexes(array: any[]) {
    //     let temp: any[] = [];
    //     for (let item of array) {
    //         if (item != undefined) {
    //             temp.push(item);
    //         }
    //     }
    //     return temp;
    // }
    //
    // private sumArr(arr: number[]) {
    //     let temp: number = 0;
    //     for (let item of arr) {
    //         if (item != undefined) {
    //             temp += item;
    //         }
    //     }
    //     return temp;
    // }

    private includeIngredient(ing: Ingredient) {

        if (this.added_ing_list.length == 0) return -2;
        for (let i = 0; i < this.added_ing_list.length; i++) {

            if (ing.id === this.added_ing_list[i].ingredients.id) {
                return i;
            }
        }


        return -1;
    }


    positionAbfrage() {
        let header = this.document.getElementById('header');
        /*console.log("HEADER", header);*/
        let hoehe = header.clientHeight;
        return hoehe;
    }
}

