<div class="static-page-layout">
    <ng-container *ngIf="optinSuccess">
        <div class="row">
            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung ist abgeschlossen</h2>
                    <p class="text-center spacing">Du erhältst zukünftig tolle Aktionen und leckere Gutscheine direkt in
                        Dein Postfach.</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="optoutSuccess">
        <div class="row">
            <div class="col-md-12">
                <div class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Schade, dass du gehst!</h2>
                    <p class="text-center spacing">

                    <p>
                        Du hast dich erfolgreich vom Meisterpizza Newsletter abgemeldet.
                    </p>
                    <p>
                        Wenn du Deinen Entschluss doch bereust, dann kannst du dich auf www.meisterpizza.de jederzeit
                        wieder anmelden. Uns würde es freuen.
                    </p>
                    <p>

                        Dein Meisterpizza-Team Freiberg</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!formSent && !optinSuccess && !optoutSuccess">
        <div class="row">
            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <p class="highlight text-center spacing">Meisterhaft informiert!
                        Abonniere unseren Newsletter,
                        wenn du regelmäßig alle Infos zu unseren Produkten, Aktionen und Gutscheinen erhalten
                        möchtest.</p>
                    <p class="text-center spacing">Nach Absenden des Formulars bekommst du eine E-Mail mit einem
                        Bestätigungslink.
                        Nach Anklicken des Links ist die Anmeldung bestätigt. </p>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <!-- <iframe *ngIf="storeData?.id === 2" width="540" height="1180"
                            src="https://faba87c5.sibforms.com/serve/MUIEAOlzZm2Wxy0BaBtVZsZABICqzFbZD5Wqwma_iZaoD-6SKAIlxFD-6McVb3gw_K4d1skUYxr7EWQuc05h04Z996BepISehae5MojtghGEzqsgXMSoVJer97M7vG_OX1Q-bBdd7AULjiC0mIXi3KNJfdfWT9Of9lsCC_JqSLIRHhSnSaqh-_jZcu-ClXh7cmrskcDJ7elU3KbX" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe> -->
                    <iframe *ngIf="storeData?.id === 2" width="540" height="950" src="https://faba87c5.sibforms.com/serve/MUIEAGZ4e6tRxplzNG_pcyri-tobEug3mVPE9Y7ahPjYI4HCyq5O0WDahiwtZll06QyuONmdMYuqmoYulTqK_svZfwcrvY98Y-Cs6qTPhb34HlFchG7zrS0CrMb_5bFvjNDBqp3ATIE9BVYfurRB_wrwrgoDhIUMkL95IgDMzeS8_K3NkTfIwdOOoQDUKV9THxdVKtT_S9lnNf0R" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>

                    <!-- <iframe *ngIf="storeData?.id === 7" width="540" height="1180"
                            src="https://faba87c5.sibforms.com/serve/MUIEAC1x6O4kxj1Zt6mf0UBZjQoTUoU6VijDsjL_OEd3Y5DfFtrbeTpYhrH1iy3kB1yDzeYVKFpFzYe5G-r1FG2v09sALKn41RdBfdtn9k5pmhAi5i1mRkoNvpjtj-baIDZBcL-u53EB8Ts6ox7c4rS6ERURdqmUjMyvxu73-3agQrlTYzqiX-C3fn6FhrWobLuGbKLpHP2cowtX" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe> -->
                    <iframe *ngIf="storeData?.id === 7" width="540" height="950" src="https://faba87c5.sibforms.com/serve/MUIEALF5wN0SQd4alhQhBhtzDftgWBkMJLhxfHTcMcxiT4i8EquL9sCeFuLZLvZCX9LmpQrhinR0GbsKEHifeWFuBsokKXrcpt7OvSmPPbt5qXa4EDmgLOeSZtigJPVPyWiHCC0hg08ZUZ3Yjh9YN7BBJV9E1A-3oUM80CIOvvRH1YtvyeXafVMVI6JNaFpS_9WUxq4-LySlq44S" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>

                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="formSent">
        <div class="row">
            <div class="col-md-12">
                <div  class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung wurde erfolgreich abgesendet.</h2>
                    <p class="text-center spacing">Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Nach
                        Anklicken ist die Newsletteranmeldung bestätigt.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h2 class="mp-font-medium-white text-center mt-7 mb-7">Und jetzt...Hunger?</h2>
            </div>
        </div>

        <div class="row sortiment center-content">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/1/pizza">
                    <div>
                        <img src="assets/img/startseite/pizza-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Pizza-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/3/burger">
                    <div>
                        <img src="assets/img/startseite/burger-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Burger-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/4/salat">
                    <div>
                        <img src="assets/img/startseite/salat-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Salat-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/6/fingerfood">
                    <div>
                        <img src="assets/img/startseite/fingerfood-sortiment_kategorie-startseite.jpg"
                             class="img-fluid">
                        <h2>Fingerfood-<br>Sortiment</h2>
                    </div>
                </a>
            </div>
            <button class="margin-center mb-7 mt-7 mp-btn mp-btn-yanone-on-black" routerLink="/shop">lecker bestellen
            </button>
        </div>
    </ng-container>
</div>
