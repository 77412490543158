<div class="cat_dash_container mx-auto w-100" *ngIf="ready">
    <div class="row">
        <div class="w-100 sortiment">
            <ng-container *ngFor="let category of  data.section, let slideIndex = index">
                <div class="category col-md-3 {{ category.mobile_image ? 'col-12 col-sm-6' : 'col-6' }}">
                    <a class="mp-box-link d-block position-relative text-center" routerLink="{{category.url}}" [class.disabled]="!category.url ? true : null">
                        <picture class="{{category.mobile_image ? 'dashboard-img-desktop' : ''}}"><img src="{{image_url}}{{category.image.jpg[0].image}}" class="up-big-100 img-fluid" alt=""></picture>
                        <picture *ngIf="category.mobile_image && category.mobile_image.jpg" class="dashboard-img-mobile"><img src="{{image_url}}{{category.mobile_image.jpg[0].image}}" class="w-100 img-fluid" alt=""></picture>
                        <h2 *ngIf="category.title">{{category.title}}</h2>
                        <!-- <button class="mp-btn mp-btn-icon"><svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon></button> -->
                    </a>
                </div>
                <!-- <div class="category col-6 " [ngClass]="data.section.length >= 8 ? 'col-md-3 w-c33' : 'col-md-4 w-c25'"></div> -->
                </ng-container>
        </div>    
    </div>    
</div>
