/**
 * @license
 * Copyright Meisterpizza Freiberg All Rights Reserved.
 */
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, forkJoin, of, interval } from 'rxjs';
/* Import Services */
import { FrontendService } from 'src/app/services/frontend.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { SettingsService } from '../../services/settings.service';
import {DateService} from '../../services/date.service';


/* Decorator */
@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    encapsulation: ViewEncapsulation.None
})

/* Component Logic */
export class WelcomeComponent implements OnInit, OnDestroy {

    /* Collect component subscriptions */
    private subscriptions: Subscription[] = [];
    /* Set if shop is closed at the moment the users loads page  */
    shopClosed = false;
    /* Show popup info that shop is closed */
    showClosedPopup = false;
    seenClosedPopupOnPickup = false;
    seenClosedPopupOnDelivery = false;
    textPopap = 'leider';
    /* Show popup when the time change rsults in different total price */
    showPriceDifferencesPopup = false;
    showSessionExpiredPopup: boolean;
    /* Check if service has loaded api call from service and render page on success */
    ready = false;
    /* Collect store data from api backend */
    storeData: any;
    /* Get the opening hour of current time */
    /* Get the store opening hours for display */
    openingHours: any;
    openingHoursPickup: any;
    openingHoursDelivery: any;
    openingHoursPickupForClose: any = [7];
    openingHoursDeliveryForClose: any = [7];
    openingHoursPickupForCloseToday: any = [];
    openingHoursDeliveryForCloseToday: any = [];
    days: object = ["Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag","Sonntag"];
    /* Set true if the customer would like to postpone delivery to certain time or date */
    deliveryLater: boolean;
    /* Set true if the customer would like to pickup order */
    pickup: any = null;
    deliveryTypeSelected = false;
    /* total price of order */
    priceTotal: number;
    /* store shop setting array */
    /* store global image url */
    imageUrl: string;
    logo: any;
    welcomeText: any;
    shopClosedAlt = false;

    subscription: Subscription;

    /**
     * Component constructor which provides service classes
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    constructor(
        private frontendService: FrontendService,
        private cartService: CartService,
        private router: Router,
        private location: Location,
        private mainService: MainService,
        private settings: SettingsService,
        private dateService: DateService
    ) {
        const date = this.dateService.newDate();
        const send_date = { 'year': date.getFullYear(), 'month': date.getMonth() + 1, 'day': date.getDate() };
        const query = send_date.year + '-' + send_date.month + '-' + send_date.day;
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            /* make api calls */
            // this.frontendService.getSettingsFromBackend(),
            this.frontendService.getStoreData(),
            this.frontendService.getOpeningHours(1, 'delivery'),
            this.frontendService.getOpeningHours(1, 'pickup'),
            of(this.cartService.shopClosedObservable),
            of(this.cartService.showSessionExpiredPopupObservable),
            this.frontendService.getOpeningHours(2),
            this.frontendService.onGetOpeningHoursByDate(query, 'delivery'),
            this.frontendService.onGetOpeningHoursByDate(query, 'pickup')
        ])
            .subscribe(
                data => {
                    this.distributeServiceDataOnSuccess(data);
                },
                err => console.error(err)
            );
            sessionStorage.setItem('deliveryTypeSelected', '0');
    }

    /**
     * Call angular lifecycle hook on init
     */
    ngOnInit() {
        if (this.showClosedPopup) {
            sessionStorage.setItem('deliveryTypeSelected', '0');
            this.deliveryTypeSelected = false;
        }
        this.imageUrl = this.mainService.image_url;
        const source = interval(60000);
        this.subscription = source.subscribe(val => this.cartService.checkSession());
        this.settings.get('theme.logo').then( setting => {
            this.logo = setting;
        });

        this.subscriptions.push(this.cartService.on_pickup_observable.subscribe(data => {
            this.pickup = data;
            if (this.pickup && typeof this.openingHoursPickup !== 'undefined') {
                this.openingHours = JSON.parse(JSON.stringify(this.openingHoursPickup));
            } else if (!this.pickup && typeof this.openingHoursDelivery !== 'undefined') {
                this.openingHours = JSON.parse(JSON.stringify(this.openingHoursDelivery));
            }
            this.checkClosed();
            if (sessionStorage.getItem('deliveryTypeSelected') && sessionStorage.getItem('deliveryTypeSelected') == '1' && !this.deliveryTypeSelected) {
                this.deliveryTypeSelected = true;
            }
            if (this.shopClosedAlt) {
                if (data) {
                    this.textPopap = ' für Abholung';
                } else {
                    this.textPopap = 'für Lieferung';
                }
            }
            this.showClosedPopup = this.shopClosed;
        }));
        
    }

    /**
     * Call angular lifecycle hook on destroy
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /* redirect to welcome step 1 and reset all session data */
    resetPage() {
        sessionStorage.clear();
        this.showSessionExpiredPopup = false;
        this.router.navigate(['willkommen', 'start']);
        setTimeout(function() {
            location.reload();
        }, 500);
    }

    /**
     * Distribute the service data to the corresponding attributes
     */
    distributeServiceDataOnSuccess(data: any) {
        /* distribute the api call response to the corresponding vars */
        this.storeData = data[0];
        this.openingHoursPickup = [8];
        for(var i=0; i<data[2].length; i++){
            var text01 = data[2][i][0];
            var text02 = data[2][i][1];
            this.openingHoursPickupForClose[i] = [2];
            if (text02.length > 0) {
                this.openingHoursPickupForClose[i][0]= {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                this.openingHoursPickupForClose[i][1] = {'from':text02.substring(0, 5), 'to':text02.substring(8, 13)};
                text01 = text01 + " Uhr / " + text02 + " Uhr";
            } else {
                this.openingHoursPickupForClose[i][0]= {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                this.openingHoursPickupForClose[i][1] = {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                text01 = text01 + " Uhr";
            }
            this.openingHoursPickup[i] = [this.days[i], text01];
        }
        var z: any = [];
        if (data[5].length > 0) {
            for(var i=0; i<data[5].length; i++) {
                if (data[5][i].feiertags == 1 && data[5][i].open_from) {
                    var from = data[5][i].open_from.slice(0, 5);
                    var to = data[5][i].open_to.slice(0, 5);
                    z = ["Feiertags", from + " - " + to + " Uhr"];
                } else if (data[5][i].feiertags == 1 && data[5][i].time_from) {
                    var from = data[5][i].time_from.slice(0, 5);
                    var to = data[5][i].time_to.slice(0, 5);
                    z = ["Feiertags", from + " - " + to + " Uhr"];
                }
            }
        }
        this.openingHoursPickup[7] = z;

        this.openingHoursDelivery= [8];
        for(var i=0; i<data[1].length; i++){
            var text01 = data[1][i][0];
            var text02 = data[1][i][1];
            this.openingHoursDeliveryForClose[i] = [2];
            if (text02.length > 0) {
                this.openingHoursDeliveryForClose[i][0]= {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                this.openingHoursDeliveryForClose[i][1] = {'from':text02.substring(0, 5), 'to':text02.substring(8, 13)}
                text01 = text01 + " Uhr / " + text02 + " Uhr";
            } else {
                this.openingHoursDeliveryForClose[i][0]= {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                this.openingHoursDeliveryForClose[i][1]= {'from':text01.substring(0, 5), 'to':text01.substring(8, 13)};
                text01 = text01 + " Uhr";
            }
            this.openingHoursDelivery[i] = [this.days[i], text01];
        }
        this.openingHoursDelivery[7] = z;

        if (this.pickup) {
            this.openingHours = JSON.parse(JSON.stringify(this.openingHoursPickup));
        } else {
            this.openingHours = JSON.parse(JSON.stringify(this.openingHoursDelivery));
        }

        /* subscribe to observables */
        // data[4].subscribe((event: number) => this.priceTotal = event);
        data[3].subscribe((event: boolean) => this.shopClosed = event);
        data[4].subscribe((event: boolean) => this.showSessionExpiredPopup = event);

        /* set same value for popup */
        if(!sessionStorage.getItem('showClosedPopup')) {
            this.showClosedPopup = this.shopClosed;
        }

        if (data[7] && typeof data[7][0] != 'undefined' && typeof data[7][0].time_from != 'undefined' && data[7][0].time_from) {
            this.openingHoursPickupForCloseToday[0] = {'from':data[7][0].time_from.substring(0, 5), 'to':data[7][0].time_to.substring(0, 5)};
        } else {
            this.openingHoursPickupForCloseToday[0] = {'from':'00:00', 'to':'00:00'};
        }

        if (data[6] && typeof data[6][0] != 'undefined' && typeof data[6][0].time_from != 'undefined' && data[6][0].time_from) {
            this.openingHoursDeliveryForCloseToday[0] = {'from':data[6][0].time_from.substring(0, 5), 'to':data[6][0].time_to.substring(0, 5)};
        } else {
            this.openingHoursDeliveryForCloseToday[0] = {'from':'00:00', 'to':'00:00'};
        }

        if (typeof data[7][1] != 'undefined' && typeof data[7][1].time_from != 'undefined' && data[7][1].time_from) {
            this.openingHoursPickupForCloseToday[1] = {'from':data[7][1].time_from.substring(0, 5), 'to':data[7][1].time_to.substring(0, 5)};
        }
        if (typeof data[6][1] != 'undefined' && typeof data[6][1].time_from != 'undefined' && data[6][1].time_from) {
            this.openingHoursDeliveryForCloseToday[1] = {'from':data[6][1].time_from.substring(0, 5), 'to':data[6][1].time_to.substring(0, 5)};
        }

        

        /** set page ready when finished */
        this.ready = true;
    }

    closePopup() {
        this.showClosedPopup = false;
        this.shopClosedAlt = false;
        sessionStorage.setItem('showClosedPopup', '0');
        if (this.pickup === true || this.pickup == '1') {
            this.seenClosedPopupOnPickup = true;
        } else if (this.pickup === false || this.pickup == '0') {
            this.seenClosedPopupOnDelivery = true;
        }
    }

    /**
     * Log component for debugging purposes
     */
    onLog() {
        // console.log('WELCOME COMPONENT LOG: %o', this);
    }

    checkClosed() {
        const currentDate = this.dateService.newDate();
        const currentTime = currentDate.getHours().toString().padStart(2, '0') + ':' + currentDate.getMinutes().toString().padStart(2, '0') + ':' + currentDate.getSeconds().toString().padStart(2, '0');
        const currentD = this.dateService.newDate();
        currentD.setHours(0,0,0,0);
        const delivD = new Date(this.cartService.getdeliveryDate());
        delivD.setHours(0,0,0,0);
        // var wd = delivD.getDay();
        var wd = currentD.getDay();
        wd = wd == 0 ? 6 : wd - 1;
        if (typeof this.openingHoursPickup === 'undefined' || typeof this.openingHoursPickup[wd] === 'undefined') {
            this.shopClosedAlt = false;
            return;
        }
        if (1/* currentD.getTime() == delivD.getTime() */) {
            const times = this.pickup ? this.openingHoursPickupForCloseToday : this.openingHoursDeliveryForCloseToday;
            for (const oh of times) {
                if (currentTime >= oh.from && currentTime < oh.to) {
                    this.shopClosedAlt = false;
                    break;
                } else {
                    this.shopClosedAlt = true;
                }
            }
        } else {
            this.shopClosedAlt = false;
        }
    }
}
