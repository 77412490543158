import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MainService} from '../../../services/main.service';
import {FrontendService} from '../../../services/frontend.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    // public stuff  - - - - - - - - - - - - - - - -
    current_page_id = 10;
    opening_hours_array: any[] = [];
    special_opening_hours_array: any[] = [];
    opening_hours_array_lif: any[] = [];
    deliveryAreas: any[] = [];
    storeData: any[] = [];
    storeDeliveryFileLink = '';
    storePickupFileLink = '';
    ready = false;
    newsletterSubscribed = false;

    // Icons für Liefergebiete
    linkPfeilSvg = "assets/icons/arrowUmod.svg";
    linkPfeilSvgPB = "assets/icons/arrowUwhitemod.svg";

    // Links für soziale Medien
    link_facebook = '';
    link_instagram = '';
    showCalendar = false;
    calendDays: any[] = [];
    opening_hours_array_new: any[] = [];

    // private stuff - - - - - - - - - - - - - - - -

    private subscriptions: Subscription[] = [];

    newsletterFormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        dataProtect: new FormControl(false, Validators.requiredTrue)
    });


    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private main_service: MainService,
        private frontend_service: FrontendService,
        private http: HttpClient,
        private router: Router,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {
        registerLocaleData(localeDe, 'de');

        // Observable: überwacht page ID
        this.subscriptions.push(this.main_service.current_page_id_observable.subscribe(
            (data: number) => {
                this.current_page_id = data;

            }
        ));

        // get opening hours for opening type "standard week day"
        this.subscriptions.push(this.frontend_service.getOpeningHours(1).subscribe(
            (data: any[]) => {
                this.opening_hours_array = data;
            }));

        // get opening hours for special opening dates
        this.subscriptions.push(this.frontend_service.getOpeningHours(2).subscribe(
            (data: any[]) => {
                // this.special_opening_hours_array = data;
                let special_opening_hours_array_TEMP = [];
                for (let i=0; i<data.length; i++) {
                    if (typeof data[i].is_virtual != 'undefined' && data[i].is_virtual == true) {
                        continue;
                    }
                    special_opening_hours_array_TEMP.push(data[i]);
                }
                this.special_opening_hours_array = special_opening_hours_array_TEMP;

                let closedDates = [];
                let closedDatesDelivery = [];
                let closedDatesPickup = [];
                for (let i=0; i<data.length; i++) {
                    if (data[i].closed == 1) {
                        if (data[i].type == 'delivery') {
                            closedDatesDelivery.push(data[i].special_opening_date);
                        } else {
                            closedDatesPickup.push(data[i].special_opening_date);
                        }
                        closedDates.push(data[i].special_opening_date);
                    }
                }
                sessionStorage.setItem('closedDates', JSON.stringify(closedDates));
                sessionStorage.setItem('closedDatesDelivery', JSON.stringify(closedDatesDelivery));
                sessionStorage.setItem('closedDatesPickup', JSON.stringify(closedDatesPickup));
            }));

        this.subscriptions.push(this.frontend_service.getOpeningHours(3).subscribe(
            (data: any[]) => {
                this.opening_hours_array_lif = data;
                /*console.log("THIS.SPECIAL-OPENING-HOURS-3", this.opening_hours_array_lif);*/
            }));

        this.subscriptions.push(this.frontend_service.getDeliveryAreas().subscribe(
            (data: any[]) => {
                this.deliveryAreas = data;
            }));

        this.subscriptions.push(this.frontend_service.getStoreData().subscribe(
            (data: any[]) => {
                this.storeData = data;
                if (data['files'] != undefined) {
                    for (let itr = 0; itr < data['files'].length; itr++) {
                        if (data['files'][itr].file_key == 'restaurant_menu') {
                            this.storePickupFileLink = data['files'][itr].path;
                        } else if (data['files'][itr].file_key == 'delivery_menu') {
                            this.storeDeliveryFileLink = data['files'][itr].path;
                        }
                    }
                }
                if (data['openTimes'] != undefined) {
                    if (data['openTimes'].constructor === Object) {
                        this.opening_hours_array_new = Object.entries(data['openTimes']);
                    } else {
                        this.opening_hours_array_new = data['openTimes'];
                    }
                }
                this.ready = true;
            }));
        // this.showAndDrawCalendar();
    }

    onSubmit() {


        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            // responseType: 'text' as 'json'
            // responseType: 'json'
        };

        const url = this.main_service.getFrontendUrl() + '/newsletter/subscribe';

        this.http.post(url, JSON.stringify(this.newsletterFormGroup.value), httpOptions)
            .subscribe(
                (res: any) => {
                    this.newsletterSubscribed = true;
                },
                err => {


                }
            );

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {
        // console.log(this, "THIS");
    }

    onClick(event: any) {
        var sichtbar = document.getElementById("collapse-button").getAttribute("aria-expanded");
        if (sichtbar == "false") {
            this.linkPfeilSvg = "assets/icons/arrowO.svg";
            /*this.linkPfeilSvgPB = "assets/icons/arrowOred.svg";*/
            this.linkPfeilSvgPB = "assets/icons/arrowOwhite.svg";
        } else {
            this.linkPfeilSvg = "assets/icons/arrowU.svg";
           /* this.linkPfeilSvgPB = "assets/icons/arrowUred.svg";*/
            this.linkPfeilSvgPB = "assets/icons/arrowUwhite.svg";
        }
    }

    setPageID(id: number) {
        this.main_service.setCurrentPageID(id);
    }

    closeCalendar() {
        this.showCalendar = false;
    }

    showAndDrawCalendar() {
        let days = [
            ["1", 0, ""],
            ["2", 0, ""],
            ["3", 0, ""],
            ["4", 0, ""],
            ["5", 0, ""],
            ["6", 0, ""],
            ["7", 0, ""],
            ["8", 0, ""],
            ["9", 0, ""],
            ["10", 0, ""],
            ["11", 0, ""],
            ["12", 0, ""],
            ["13", 0, ""],
            ["14", 0, ""],
            ["15", 0, ""],
            ["16", 0, ""],
            ["17", 0, ""],
            ["18", 0, ""],
            ["19", 0, ""],
            ["20", 0, ""],
            ["21", 0, ""],
            ["22", 0, ""],
            ["23", 0, ""],
            ["24", 0, ""]
        ]

        // randomize days
        days.sort(() => Math.random() - 0.5);

        this.calendDays = days;
        this.showCalendar = true;
    }

    activateDayOfCalendar(dayClicked) {
        let dateNow = new Date();
        let dayNow = dateNow.getDate();
        if (dayNow != dayClicked) {
            return;
        }
        for (let it=0; it < this.calendDays.length; it++) {
            if(this.calendDays[it][0] == dayNow) {
                this.calendDays[it][1] = 1;
            }
        }
    }

    getCalendarProduct() {
        this.showCalendar = false;
        if (this.storeData['calendarProduct'] !== undefined) {
            let link = this.storeData['calendarProduct'];
            this.router.navigate([link]);
        } else {
            this.router.navigate(['/shop/bestellen/13/Burger/372/Burger-Hot-Chili/Classic-Burger']);
        }
    }


    // setSocLink() {
    //     if (this.storeData.id == 2) {
    //         this.link_facebook = 'https://www.facebook.com/meisterpizza';
    //         this.link_instagram = 'https://www.instagram.com/meisterpizzafreiberg/';
    //     } else if (this.storeData.id == 4) {
    //         this.link_facebook = 'https://www.facebook.com/meisterpizza';
    //         this.link_instagram = 'https://www.instagram.com/meisterpizzafreiberg/';
    //     }
    // }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
}
