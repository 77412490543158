<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12">
            <div class="header-wrapper-small">
                <h1 class="static-page-title">Datenschutzerklärung</h1>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="row">

            <div class="col-md-12">

                <div class="center-content">

                    <h2>1. Datenschutz auf einen Blick</h2>
                    <h3>Allgemeine Hinweise</h3>
                    <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
                        personenbezogenen
                        Daten
                        passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit
                        denen
                        Sie
                        persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz
                        entnehmen Sie
                        unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>

                    <h3>Datenerfassung auf unserer Website</h3>
                    <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>
                    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
                        Kontaktdaten
                        können
                        Sie dem Impressum dieser Website entnehmen.</p>
                    <p><strong>Wie erfassen wir Ihre Daten?</strong></p>
                    <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
                        sich
                        z.B. um
                        Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                    <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                        sind
                        vor
                        allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
                        Seitenaufrufs).
                        Die
                        Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
                    <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>
                    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
                        gewährleisten.
                        Andere
                        Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
                    <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>
                    <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
                        Berichtigung,
                        Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                        Datenschutz
                        können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des
                        Weiteren
                        steht
                        Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>


                    <p>Außerdem haben Sie das Recht, unter bestimmten Umständen die
                        Einschränkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
                        unter „Recht
                        auf Einschränkung der Verarbeitung“.</p>

                    <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>

                    <h3>Datenschutz</h3>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
                        behandeln
                        Ihre
                        personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                        Datenschutzvorschriften
                        sowie
                        dieser Datenschutzerklärung.</p>
                    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                        Personenbezogene
                        Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
                        erläutert
                        auch,
                        wie und zu welchem Zweck das geschieht.</p>
                    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation
                        per
                        E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                        Dritte
                        ist
                        nicht möglich.</p>


                    <h3>Hinweis zur verantwortlichen Stelle</h3>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>

                    <p>
                        <span *ngIf="storeData?.id === 2">Markus Hoffmann</span>
                        <span *ngIf="storeData?.id === 7">Markus Hoffmann</span>
                        <span *ngIf="storeData?.id === 4">Tim Gowitzke</span>
                        <span *ngIf="storeData?.id === 5">David Krieger</span>
                        <span *ngIf="storeData?.id === 6">Tim Gowitzke</span>
                        <span *ngIf="storeData?.id === 8">Tim Gowitzke</span> <br/>
                        {{ storeData?.name }}<br/>
                        {{ storeData?.street }}<br/>
                        {{ storeData?.zip }} {{ storeData?.city }}
                    </p>

                    <p>Telefon: {{ storeData?.phone }}<br/>
                        E-Mail: {{ storeData?.email }}</p>


                    <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
                        gemeinsam mit
                        anderen
                        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                        E-Mail-Adressen o.
                        Ä.) entscheidet.</p>


                    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                    <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
                        Sie
                        können eine
                        bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
                        per
                        E-Mail an
                        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                        Widerruf
                        unberührt.</p>

                    <div>
                        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen
                            Fällen
                            sowie
                            gegen Direktwerbung (Art.
                            21
                            DSGVO)</h3>
                        <p><strong>Wenn die Datenverarbeitung auf Grundlage von Art.
                            6 Abs.
                            1
                            lit. e oder f DSGVO erfolgt,
                            haben Sie
                            jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen
                            die
                            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für
                            ein auf
                            diese
                            Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
                            Verarbeitung
                            beruht,
                            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
                            Ihre
                            betroffenen
                            personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
                            schutzwürdige
                            Gründe
                            für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
                            oder die
                            Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                            (Widerspruch nach
                            Art. 21 Abs. 1 DSGVO).</strong></p>
                        <p><strong>Werden Ihre personenbezogenen Daten verarbeitet,
                            um
                            Direktwerbung zu betreiben, so haben
                            Sie das
                            Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener
                            Daten zum
                            Zwecke
                            derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                            Direktwerbung in
                            Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
                            anschließend nicht
                            mehr
                            zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).</strong>
                        </p>
                    </div>


                    <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>

                    <p>Im Falle von Verstößen gegen die DSGVO steht den
                        Betroffenen ein
                        Beschwerderecht bei einer
                        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts,
                        ihres
                        Arbeitsplatzes
                        oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
                        anderweitiger
                        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>


                    <h3>Recht auf Datenübertragbarkeit</h3>
                    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
                        eines
                        Vertrags
                        automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
                        maschinenlesbaren
                        Format
                        aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
                        Verantwortlichen
                        verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                    <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                    <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
                        Inhalte,
                        wie zum
                        Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                        SSL-bzw.
                        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                        Adresszeile des
                        Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
                        Browserzeile.</p>
                    <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
                        übermitteln, nicht
                        von Dritten mitgelesen werden.</p>


                    <div>
                        <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
                        <p>Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
                            Verpflichtung, uns Ihre
                            Zahlungsdaten
                            (z.B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur
                            Zahlungsabwicklung
                            benötigt.</p>
                        <p>Der Zahlungsverkehr über die gängigen Zahlungsmittel
                            (Visa/MasterCard, Lastschriftverfahren)
                            erfolgt
                            ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte
                            Verbindung
                            erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://"
                            wechselt
                            und
                            an dem
                            Schloss-Symbol in Ihrer Browserzeile.</p>
                        <p>Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie
                            an uns übermitteln, nicht
                            von
                            Dritten mitgelesen werden.</p>
                    </div>

                    <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>

                    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
                        unentgeltliche
                        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
                        und den
                        Zweck der
                        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser
                        Daten.
                        Hierzu sowie
                        zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der
                        im
                        Impressum
                        angegebenen Adresse an uns wenden.</p>

                    <div>
                        <h3>Recht auf Einschränkung der Verarbeitung</h3>
                        <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu
                            verlangen.
                            Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                            wenden.
                            Das
                            Recht auf
                            Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
                        <ul>
                            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                                bestreiten,
                                benötigen
                                wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                                das
                                Recht,
                                die
                                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah /
                                geschieht,
                                können
                                Sie
                                statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                            </li>
                            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
                                Ausübung,
                                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das
                                Recht,
                                statt
                                der
                                Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                                verlangen.
                            </li>
                            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
                                Abwägung
                                zwischen
                                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht,
                                wessen
                                Interessen
                                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                                personenbezogenen
                                Daten zu
                                verlangen.
                            </li>
                        </ul>
                        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
                            haben, dürfen diese Daten –
                            von
                            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                            Ausübung
                            oder
                            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
                            oder
                            juristischen
                            Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
                            oder
                            eines
                            Mitgliedstaats verarbeitet werden.</p>
                    </div>

                    <h3>Widerspruch gegen Werbe-E-Mails</h3>
                    <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
                        Übersendung
                        von
                        nicht
                        ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                        widersprochen.
                        Die
                        Betreiber
                        der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                        Zusendung
                        von
                        Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>


                    <h2>3. Datenerfassung auf unserer Website</h2>

                    <h3>Kontaktformular</h3>
                    <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                        Anfrageformular
                        inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
                        den
                        Fall
                        von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
                        weiter.</p>
                    <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich
                        auf
                        Grundlage
                        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
                        widerrufen.
                        Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
                        Widerruf
                        erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
                    <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
                        Löschung
                        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
                        Datenspeicherung
                        entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                        Bestimmungen –
                        insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>

                    <div>
                        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
                        <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                            Ihre Anfrage inklusive aller
                            daraus
                            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
                            Anliegens bei
                            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
                            weiter.</p>
                        <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                            Abs. 1 lit. b DSGVO, sofern Ihre
                            Anfrage
                            mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung
                            (Art. 6
                            Abs. 1
                            lit. a DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO),
                            da
                            wir ein
                            berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen
                            haben.</p>
                        <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                            verbleiben bei uns, bis Sie uns zur
                            Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
                            Datenspeicherung
                            entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                            Bestimmungen –
                            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
                    </div>

                    <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
                    <p>Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die
                        Begründung,
                        inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind
                        (Bestandsdaten). Dies
                        erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                        Erfüllung eines
                        Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die
                        Inanspruchnahme
                        unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
                        erforderlich
                        ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.</p>
                    <p>Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der
                        Geschäftsbeziehung
                        gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>

                    <h3>Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und Warenversand</h3>
                    <p>Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
                        Vertragsabwicklung
                        notwendig ist, etwa an die mit der Lieferung der Ware betrauten Unternehmen oder das mit der
                        Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten
                        erfolgt
                        nicht
                        bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe
                        Ihrer
                        Daten an
                        Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.</p>
                    <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
                        Daten zur
                        Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

                    <div>
                        <h2>4. Newsletter</h2>
                        <h3>Newsletterdaten</h3>
                        <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen
                            eine
                            E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der
                            Inhaber
                            der
                            angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind.
                            Weitere
                            Daten
                            werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
                            ausschließlich
                            für den
                            Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.</p>
                        <p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
                            ausschließlich
                            auf
                            Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
                            Speicherung der
                            Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie
                            jederzeit
                            widerrufen, etwa über den "Austragen"-Link im Newsletter. Die Rechtmäßigkeit der bereits
                            erfolgten
                            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
                        <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
                            zu
                            Ihrer
                            Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters
                            gelöscht.
                            Daten, die
                            zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.</p>
                    </div>

                    <div>
                        <h2>5. Plugins und Tools</h2>
                        <h3>Google Web Fonts</h3>
                        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts,
                            die von
                            Google
                            bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts
                            in
                            ihren
                            Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
                        <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von
                            Google
                            aufnehmen.
                            Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
                            aufgerufen
                            wurde.
                            Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und
                            ansprechenden
                            Darstellung
                            unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs.
                            1 lit.
                            f DSGVO
                            dar.</p>
                        <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem
                            Computer
                            genutzt.</p>
                        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                                href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener">https://developers.google.com/fonts/faq</a>
                            und in der Datenschutzerklärung von Google: <a
                                    href="https://policies.google.com/privacy?hl=de"
                                    target="_blank" rel="noopener">https://policies.google.com/privacy?hl=de</a>.
                        </p>

                        <h3>Verwendung von GoogleMaps</h3>
                        <p>Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der
                            Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; „Google“).
                            Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und
                            interaktiven Landkarten. Dabei werden von Google bei Aufrufen der Seiten, in die
                            GoogleMaps-Karten eingebunden sind, auch Daten der Besucher der Websites erhoben,
                            verarbeitet und genutzt.</p>
                        <p>Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für die USA ist kein
                            Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf
                            Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz der
                            personenbezogenen Daten, einsehbar unter: <a
                                    href="https://policies.google.com/privacy/frameworks" target="_blank"
                                    rel="noopener">https://policies.google.com/privacy/frameworks</a>.
                            Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit.
                            f DSGVO aus unserem überwiegenden berechtigten Interesse an der bedarfsgerechten und
                            zielgerichteten Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus Ihrer
                            besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender
                            personenbezogener Daten zu widersprechen.</p>
                        <p>Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den
                            Datenschutzhinweisen von Google unter <a
                                    href="https://www.google.com/privacypolicy.html" target="_blank" rel="noopener">https://www.google.com/privacypolicy.html</a>.
                            Dort haben
                            Sie auch im Datenschutzcenter die Möglichkeit, Ihre Einstellungen zu verändern, so dass Sie
                            Ihre von Google verarbeiteten Daten verwalten und schützen können.
                        </p>

                        <h3>Verwendung von Google invisible reCAPTCHA</h3>
                        <p>Wir verwenden auf unserer Website den Dienst invisible reCAPTCHA der Google Ireland Limited
                            (Gordon House, Barrow Street, Dublin 4, Irland; "Google").</p>
                        <p>Dies dient dem Zweck der Unterscheidung der Eingabe durch einen Menschen oder durch
                            automatisierte, maschinelle Verarbeitung. Im Hintergrund sammelt und analysiert Google
                            Nutzungsdaten, die von Invisible reCaptcha dazu verwendet werden, um reguläre Benutzer von
                            Bots zu unterscheiden. Dazu wird Ihre Eingabe an Google übermittelt und dort
                            weiterverwendet. Zusätzlich werden die IP-Adresse und gegebenenfalls weitere von Google für
                            den Dienst Invisible reCAPTCHA benötigte Daten an Google übertragen.</p>
                        <p>Diese Daten werden von Google innerhalb der Europäischen Union und gegebenenfalls auch in
                            der USA verarbeitet. Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission
                            vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als
                            geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter:
                            <a
                                    href="https://policies.google.com/privacy/frameworks" target="_blank"
                                    rel="noopener">https://policies.google.com/privacy/frameworks</a>.</p>
                        <p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit.
                            f DSGVO aus unserem überwiegenden berechtigten Interesse unsere Website vor automatisierter
                            Ausspähung, Missbrauch und SPAM zu schützen. Sie haben das Recht aus Gründen, die sich aus
                            Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO
                            beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</p>
                        <p>Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden
                            Sie unter: <a
                                    href="https://www.google.com/recaptcha/intro/android.html" target="_blank"
                                    rel="noopener">https://www.google.com/recaptcha/intro/android.html</a> sowie
                            https://www.google.com/privacy
                        </p>


                        <h2>6. Zahlungsanbieter und Reseller</h2>
                        <h3>PayPal</h3>
                        <p>Auf unserer Website bieten wir u.a. die Bezahlung via PayPal an. Anbieter dieses
                            Zahlungsdienstes
                            ist die
                            PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im
                            Folgenden
                            “PayPal”).</p>
                        <p>Wenn Sie die Bezahlung via PayPal auswählen, werden die von Ihnen eingegebenen
                            Zahlungsdaten an
                            PayPal
                            übermittelt.</p>
                        <p>Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a
                            DSGVO
                            (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO (Verarbeitung zur Erfüllung eines
                            Vertrags). Sie
                            haben die
                            Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Ein
                            Widerruf wirkt
                            sich
                            auf die Wirksamkeit von in der Vergangenheit liegenden Datenverarbeitungsvorgängen nicht
                            aus.</p>
                        <h2>7. Eigene Dienste</h2>
                        <h3>Bewerbungen</h3>
                        <p>Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per E-Mail, postalisch
                            oder via
                            Online-Bewerberformular). Im Folgenden informieren wir Sie über Umfang, Zweck und
                            Verwendung
                            Ihrer im
                            Rahmen des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir versichern, dass
                            die
                            Erhebung,
                            Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht
                            und allen
                            weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
                            werden.</p>
                        <p><strong>Umfang und Zweck der Datenerhebung </strong></p>
                        <p>Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen
                            personenbezogenen
                            Daten (z. B. Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen
                            von
                            Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die Begründung eines
                            Beschäftigungsverhältnisses erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG-neu
                            nach
                            deutschem
                            Recht (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO
                            (allgemeine
                            Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben – Art. 6 Abs. 1 lit.
                            a
                            DSGVO. Die
                            Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb
                            unseres
                            Unternehmens ausschließlich an Personen weitergegeben, die an der Bearbeitung Ihrer
                            Bewerbung
                            beteiligt
                            sind.</p>
                        <p>Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf
                            Grundlage von
                            § 26
                            BDSG-neu und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
                            Beschäftigungsverhältnisses in
                            unseren Datenverarbeitungssystemen gespeichert.</p>
                        <p><strong>Aufbewahrungsdauer der Daten</strong></p>
                        <p>Wenn wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot ablehnen, Ihre
                            Bewerbung
                            zurückziehen, Ihre Einwilligung zur Datenverarbeitung widerrufen oder uns zur Löschung
                            der Daten
                            auffordern, werden die von Ihnen übermittelten Daten inkl. ggf. verbleibender physischer
                            Bewerbungsunterlagen für maximal 6 Monate nach Abschluss des Bewerbungsverfahrens
                            gespeichert
                            bzw.
                            aufbewahrt (Aufbewahrungsfrist), um die Einzelheiten des Bewerbungsprozesses im Falle
                            von
                            Unstimmigkeiten nachvollziehen zu können (Art. 6 Abs. 1 lit. f DSGVO).</p>
                        <p>DIESER SPEICHERUNG KÖNNEN SIE WIDERSPRECHEN, SOFERN IHRERSEITS BERECHTIGTE INTERESSEN
                            VORLIEGEN,
                            DIE
                            UNSERE INTERESSEN ÜBERWIEGEN.</p>
                        <p>Nach Ablauf der Aufbewahrungsfrist werden die Daten gelöscht, sofern keine gesetzliche
                            Aufbewahrungspflicht oder ein sonstiger Rechtsgrund zur weiteren Speicherung vorliegt.
                            Sofern
                            ersichtlich ist, dass die Aufbewahrung Ihrer Daten nach Ablauf der Aufbewahrungsfrist
                            erforderlich sein
                            wird (z. B. aufgrund eines drohenden oder anhängigen Rechtsstreits), findet eine
                            Löschung erst
                            statt,
                            wenn die Daten gegenstandslos geworden sind. Sonstige gesetzliche Aufbewahrungspflichten
                            bleiben
                            unberührt.</p>
                    </div>

                </div>
            </div>
        </div>

    </ng-container>
</div>
