import { ReplaySubject } from 'rxjs';
import { url } from 'src/environments/environment';
import * as i0 from "@angular/core";
var MainService = /** @class */ (function () {
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // constructor
    function MainService() {
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        // Local Environment
        // frontend_url = 'http://maho.meisterpizza.local/api';
        // image_url = 'http://maho.meisterpizza.local/images';
        // submit_url = 'http://maho.meisterpizza.local/direct-us/server/order_server.php';
        // adress_url = 'http://maho.meisterpizza.local/api';
        // SPaces Server
        // frontend_url: string = 'https://maho-gastronomie.de/api';
        // image_url: string = 'https://maho-gastronomie.de/images';
        // submit_url: string = 'https://maho-gastronomie.de/direct-us/server/order_server.php';
        // adress_url: string = 'https://maho-gastronomie.de/api';
        // Mittwald Staging Server
        this.frontend_url = url.frontend;
        this.image_url = url.image;
        this.submit_url = url.submit;
        this.adress_url = url.adress;
        // config stuff - locale (old)
        // frontend_url: string = 'http://localhost/directus/server/shop_server.php';
        // submit_url: string = 'http://localhost/directus/server/order_server.php';
        // image_url: string = 'http://localhost/directus/';
        // adress_url: string ='http://localhost/directus/server/adress-server.php';
        this.on_pickup = true;
        // Zeitspanne: wenn shop weniger als x min offen hat, dann kommt dialog
        this.preorder_span = 14;
        // active Effekt Navigation
        this.current_page_id = -1;
        this.current_page_id_subject = new ReplaySubject(1);
        this.current_page_id_observable = this.current_page_id_subject.asObservable();
        this.cats_svg_arr = [];
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // functions stuff
    MainService.prototype.setCurrentPageID = function (new_page_id) {
        this.current_page_id = new_page_id;
        this.current_page_id_subject.next(this.current_page_id);
    };
    MainService.prototype.getFrontendUrl = function () {
        return this.frontend_url;
    };
    MainService.prototype.getSubmitUrl = function () {
        return this.submit_url;
    };
    MainService.ngInjectableDef = i0.defineInjectable({ factory: function MainService_Factory() { return new MainService(); }, token: MainService, providedIn: "root" });
    return MainService;
}());
export { MainService };
