<!--<button class="log-button" (click)="onLog()">Log Footer Component</button>-->
<ng-container *ngIf="ready">
    <footer>
        <!--ShopInfo-->
        <div *ngIf="storeDeliveryFileLink || storePickupFileLink" class="bigscreen row d-flex justify-content-center text-center mx-1 mb-5 pb-5">
            <h3 class="col-12 m-0 mb-5 mp-font-large-yellow">Downloads</h3>
            <div *ngIf="storeDeliveryFileLink" class="p-0 mb-5 col-12 text-center"><a class="mp-btn mp-btn-newsletter bigscreen_href" href="{{ storeDeliveryFileLink }}" download target="_blank">Lieferkarte</a></div>
            <div *ngIf="storePickupFileLink" class="p-0 col-12 text-center"><a class="mp-btn mp-btn-newsletter bigscreen_href" href="{{ storePickupFileLink }}" download target="_blank">Restaurant-Karte</a></div>
        </div>
        <div class="row d-flex justify-content-center align-items-start my-0 mx-1">
            <div id="open-footer-container" class="special-opening-container text-center px-0 col-12 py-2 mb-7 d-flex justify-content-center align-items-center"
                 *ngIf="special_opening_hours_array.length >0">
                <div class="col-xl-6 mx-xl-auto special-opening-hours row p-0 my-sm-0 mx-sm-auto  d-flex justify-content-center align-items-center">
                    <h3 class="col-12 m-0 mb-2 mp-font-large-yellow">Gesonderte Öffnungszeiten</h3>
                    <div *ngFor="let day of special_opening_hours_array, let index = index"
                         class="open-hours col-12 col-sm-7col-md-12 my-0 p-0 d-flex justify-content-center align-items-center">
                        <table *ngIf="!day.closed">
                            <tr class="d-flex">
                                <td class="d-block col-4">{{ day.special_opening_date | date:
                                        'dd.MM.yyyy':'':'de'}}
                                </td>
                                <td class="d-block col-4">{{ day.type == 'pickup' ? 'Abholung' : 'Lieferung'}}</td>
                                <td class="d-block col-4 px-0">{{ day.time_from | slice:0:5 }} - {{ day.time_to |
                                slice:0:5 }}
                                </td>
                            </tr>
                        </table>
                        <table *ngIf="day.closed">
                            <!--<td class="d-block mr-2">{{ day.special_opening_date | date: 'dd.MM.yyyy':'':'de'
                                }}
                            </td>
                            <td>geschlossen!</td>-->
                            <tr class="d-flex">
                                <td class="d-block col-4 font-weight-bold">
                                    {{ day.special_opening_date | date: 'dd.MM.yyyy':'':'de'
                                    }}
                                </td>
                                <td class="d-block col-4 font-weight-bold">{{ day.type == 'pickup' ? 'Abholung' : 'Lieferung'}}</td>
                                <td class="d-block col-4 px-0 font-weight-bold">Geschlossen!</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <!-- <div id="open-footer" class="col-md-6 col-lg-6 col-xxl-3 mb-7 mb-xxl-0 ">
                 <div class="row">
                     &lt;!&ndash;<div class="col-lg-5 col-xxl-12 mb-4">&ndash;&gt;
                     <div class="mb-4  text-center my-0 mx-auto">
                         <h3 class="mp-font-large-yellow">Öffnungszeiten</h3>
                         <div class="open-hours">
                             <table>
                                 <tr *ngFor="let day of opening_hours_array, let index = index">
                                     <td class="d-block mr-2" *ngIf="index == 0">Mo</td>
                                     <td class="d-block mr-2" *ngIf="index == 1">Di</td>
                                     <td class="d-block mr-2" *ngIf="index == 2">Mi</td>
                                     <td class="d-block mr-2" *ngIf="index == 3">Do</td>
                                     <td class="d-block mr-2" *ngIf="index == 4">Fr</td>
                                     <td class="d-block mr-2" *ngIf="index == 5">Sa</td>
                                     <td class="d-block mr-2" *ngIf="index == 6">So</td>
                                     &lt;!&ndash;&lt;!&ndash;iterieren durch spalten -> öffnungszeitenintervalle&ndash;&gt;&ndash;&gt;
                                     <td *ngFor="let item of day">
                                         {{item}}
                                     </td>
                                     <br>
                                 </tr>
                             </table>
                         </div>
                     </div>

                 </div>
             </div>-->


            <!-- /*Abholung*/-->

            <!-- new open times -->

            <div id="open-footer-abholung" class="p-0 col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2" *ngFor="let delivType of opening_hours_array_new, let delivName = index">
                <div class="row">
                    <div class="mb-md-4 col-12 text-center my-0 mx-auto">

                        <h3 class="mp-font-large-yellow">{{ delivType[0] }}</h3>
                        <div class="open-hours d-flex justify-content-center align-items-center">
                            <table>
                                <tr *ngFor="let day of delivType[1] | keyvalue">
                                    <td class="d-block mr-2" *ngIf="day.key == 1">Mo</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 2">Di</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 3">Mi</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 4">Do</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 5">Fr</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 6">Sa</td>
                                    <td class="d-block mr-2" *ngIf="day.key == 7">So</td>
                                    <td *ngFor="let item of day.value">
                                        {{item}}
                                    </td>
                                    <br>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <!-- END new open times -->

            <!-- <div id="open-footer-abholung" class="p-0 col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2">
                <div class="row">
                    <div class="mb-md-4 col-12 text-center my-0 mx-auto">

                        <h3 *ngIf="storeData?.id === 2" class="mp-font-large-yellow">Abholung</h3>
                        <h3 *ngIf="storeData?.id === 7" class="mp-font-large-yellow">Abholung / Restaurant</h3>
                        <div class="open-hours d-flex justify-content-center align-items-center">
                            <table>
                                <tr *ngFor="let day of opening_hours_array, let index = index">
                                    <td class="d-block mr-2" *ngIf="index == 0">Mo</td>
                                    <td class="d-block mr-2" *ngIf="index == 1">Di</td>
                                    <td class="d-block mr-2" *ngIf="index == 2">Mi</td>
                                    <td class="d-block mr-2" *ngIf="index == 3">Do</td>
                                    <td class="d-block mr-2" *ngIf="index == 4">Fr</td>
                                    <td class="d-block mr-2" *ngIf="index == 5">Sa</td>
                                    <td class="d-block mr-2" *ngIf="index == 6">So</td>
                                    <td *ngFor="let item of day">
                                        {{item}}
                                    </td>
                                    <br>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div> -->

            <!-- /**Lieferung**/-->
            <!-- <div id="open-footer-lieferung" class="p-0 col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2 ">
                <div class="row">
                    <div class="mb-md-4 col-12 text-center my-0 mx-auto">
                        <h3 class="mp-font-large-yellow">Lieferung</h3>
                        <div class="open-hours d-flex justify-content-center align-items-center">
                            <table>
                                <tr *ngFor="let day of opening_hours_array_lif, let index = index">
                                    <td class="d-block mr-2" *ngIf="index == 0">Mo</td>
                                    <td class="d-block mr-2" *ngIf="index == 1">Di</td>
                                    <td class="d-block mr-2" *ngIf="index == 2">Mi</td>
                                    <td class="d-block mr-2" *ngIf="index == 3">Do</td>
                                    <td class="d-block mr-2" *ngIf="index == 4">Fr</td>
                                    <td class="d-block mr-2" *ngIf="index == 5">Sa</td>
                                    <td class="d-block mr-2" *ngIf="index == 6">So</td>
                                    <td *ngFor="let item of day, let in = index">
                                        {{item}}
                                    </td>
                                    <br>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div> -->

            <div id="kontakt" class=" text-center col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2">
                <h3 class="mp-font-large-yellow">Kontakt</h3>
                <ul class="list-unstyled mb-0">
                    <li>
                        Noch Fragen?
                    </li>
                    <li class="hotline">
                        <a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a>
                    </li>
                    <li>
                        {{ storeData.street }}
                    </li>
                    <li>
                        {{ storeData.zip }} {{ storeData.city }}
                    </li>

                    <li class="mt-2">


                        <a *ngIf="storeData?.id === 7" href="https://www.facebook.com/meisterpizzadresden"
                           target="_blank" class="mr-2">

                            <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                                 viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                            </svg>
                        </a>

                        <a *ngIf="storeData?.id === 2" href="https://www.facebook.com/meisterpizza" target="_blank"
                           class="mr-2">

                            <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                                 viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                            </svg>
                        </a>


                        <a *ngIf="storeData?.id === 7" href="https://www.instagram.com/meisterpizzadresden/"
                           target="_blank">

                            <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                                 viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                            </svg>
                        </a>
                        <a *ngIf="storeData?.id === 2" href="https://www.instagram.com/meisterpizzafreiberg/"
                           target="_blank">

                            <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                                 viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                            </svg>
                        </a>

                    </li>
                </ul>
            </div>

            <div id="payments" class=" text-center col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2">
                <h3 class="mp-font-large-yellow">Zahlungsarten</h3>

                <ul class="list-unstyled mb-0">
                    <li>


                        <picture class="mx-2">
                            <source srcset="assets/img/payments/master1.jpg" type="image/png">
                            <img src="assets/img/payments/master1.jpg" alt="MasterCard">
                        </picture>

                        <picture class="mx-2">
                            <source srcset="assets/img/payments/visa1.jpg" type="image/png">
                            <img src="assets/img/payments/visa1.jpg" alt="Visa">
                        </picture>

                        <picture class="mx-2">
                            <source srcset="assets/img/payments/amex1.jpg" type="image/png">
                            <img src="assets/img/payments/amex1.jpg" alt="American Express">
                        </picture>

                    </li>
                    <li>
                        <picture class="mx-2">
                            <source srcset="assets/img/payments/girocard1.jpg" type="image/png">
                            <img src="assets/img/payments/girocard1.jpg" alt="Girocard">
                        </picture>

                        <picture class="mx-2">
                            <source srcset="assets/img/payments/klarna_rose.jpg" type="image/png">
                            <img src="assets/img/payments/klarna1.jpg" alt="Klarna">
                        </picture>
                        <picture class="m-2">
                            <source srcset="assets/img/payments/gpay.jpg" type="image/png">
                            <img src="assets/img/payments/gpay.jpg" alt="Google Pay">
                        </picture>
                    </li>
                    <li>
                        <picture class="mx-2">
                            <source srcset="assets/img/payments/paypal1.jpg" type="image/png">
                            <img src="assets/img/payments/paypal1.jpg" alt="PayPal">
                        </picture>
                        
                        <!-- <picture class="mx-2">
                            <source srcset="assets/img/payments/giro_pay1.jpg" type="image/png">
                            <img src="assets/img/payments/giro_pay1.jpg" alt="GiroPay">
                        </picture> -->
                        
                        <picture class="mx-2">
                            <source srcset="assets/img/payments/pay1.jpg" type="image/png">
                            <img src="assets/img/payments/pay1.jpg" alt="ApplePay">
                        </picture>
                        
                        <picture class="m-1">
                            <source srcset="assets/img/payments/barzahl1.jpg" type="image/png">
                            <img src="assets/img/payments/barzahl1.jpg" alt="Barzahlung">
                        </picture>
                    </li>

                </ul>
            </div>
            <!-- <div *ngIf="storeDeliveryFileLink || storePickupFileLink" id="kontakt" class="smallscreen text-center col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2">
                <h3 class="mp-font-large-yellow">Downloads</h3>
                <ul class="list-unstyled mb-0">
                    <li *ngIf="storeDeliveryFileLink">
                        <a class="mp-btn mp-btn-newsletter bigscreen_href" href="{{ storeDeliveryFileLink }}" download target="_blank">Lieferkarte</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="storeDeliveryFileLink || storePickupFileLink" id="kontakt" class="smallscreen text-center col-12 mb-3 col-md-6 col-xxl1412-3 col-xxxl-2">
                <ul class="list-unstyled mb-0">
                    <li *ngIf="storePickupFileLink">
                        <a class="mp-btn mp-btn-newsletter bigscreen_href" href="{{ storePickupFileLink }}" download target="_blank">Restaurant-Karte</a>
                    </li>
                </ul>
            </div> -->
            <div id="newsletter" class=" text-center col-12 mb-7 col-xl-6 col-xxxl-2">
                <h3 class="mp-font-large-yellow">Newsletter</h3>
                <a routerLink="/shop/newsletter" class="mp-btn mp-btn-newsletter">Jetzt kostenlos abonnieren</a>
            </div>

        </div>
        <!--Liefergebiete-->
        <div class="row delivery-areas mb-7 testLG mx-1">
            <div class="col-xxl-12 text-center  container-btn-LG">

                <button class=" pb-0 btn collapsed col-xxl-12  btn-LG" type="button"
                        (click)="onClick($event)" data-toggle="collapse"
                        id="collapse-button" data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample">

                    <div class="col-12 text-center containerH3">
                        <h3>Liefergebiete</h3>
                    </div>

                    <div class="containerSVG">
                        <img alt="arrow" src={{linkPfeilSvg}} width="30" height="16">
                    </div>

                </button>

            </div>
            <div class="card card-body liefergebite">
                <div class="col-xxl-12">
                    <div class="collapse" id="collapseExample">
                        <ul class="d-flex flex-wrap justify-content-center justify-content-xxl-start list-unstyled mb-0">
                            <li *ngFor="let deliveryArea of deliveryAreas">
                                {{ deliveryArea.district }}
                                <span *ngIf="storeData?.id === 7">({{ deliveryArea.plz }})</span></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <!--End Liefergebiete-->

        <!--Footer-->
        <div class="row">
            <!--  <div class="offset-xxl-3 col-xxl-9 footer-border" id="service">-->
            <div class="col  footer-border" id="service">
                <ul class="list-unstyled mb-0">
                    <!-- <li [class.menu-active-0]="current_page_id == 13">
                         <a routerLink="/shop/ueberuns" (click)="setPageID(13)">Über uns</a>
                     </li>-->
                    <li [class.menu-active-0]="current_page_id == 140">
                        <a routerLink="/shop/newsletter" (click)="setPageID(140)">Newsletter</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 160">
                        <a routerLink="/shop/jobs" (click)="setPageID(160)">Jobs</a>
                    </li>
                    <li *ngIf="storeData?.id === 7" [class.menu-active-0]="current_page_id == 230">
                        <a routerLink="/shop/restaurant" (click)="setPageID(230)">Restaurant</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 180">
                        <a routerLink="/shop/allergene" (click)="setPageID(180)">Allergene & Zusatzstoffe</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 190">
                        <a routerLink="/shop/agb" (click)="setPageID(190)">AGB</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 200">
                        <a routerLink="/shop/impressum" (click)="setPageID(200)">Impressum</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 210">
                        <a routerLink="/shop/datenschutz" (click)="setPageID(210)">Datenschutz</a>
                    </li>
                    <li [class.menu-active-0]="current_page_id == 220">
                        <a onclick="showCookie()">
                            Cookie-Einstellungen </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Calendar -->
        <!-- <ng-container *ngIf="showCalendar">
            <div class="bg-shop-closed">
                <div class="dialog-shop-closed">
                    <div class="title-dialog-shop-closed">
                        Adventskalender
                    </div>
                    <div class="cont-dialog-shop-closed">
                        <ul id="adventDoors">
                            <li  *ngFor="let calendDay of calendDays, let index = index">
                                <div  (click)="activateDayOfCalendar(calendDay[0])" class="adwcontainer">
                                    <span class="adwday">{{calendDay[0]}}</span>
                                    <img *ngIf="calendDay[1]" src="assets/img/door_open.svg" class="adwdoor">
                                    <img *ngIf="!calendDay[1]" src="assets/img/door.svg" class="adwdoor">
                                </div>
                                <div class="btn-dialog-shop-closed" *ngIf="calendDay[1]">
                                    <button class="mp-btn mp-btn-yellow-on-white mt-5" (click)="getCalendarProduct()">Ihr Geschenk abholen</button>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-dialog-shop-closed">
                            <button class="mp-btn mp-btn-yellow-on-white mt-5" (click)="closeCalendar()">Weiter</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container> -->
        <!-- End calendar -->

        <!--End Footer-->
    </footer>


</ng-container>


<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
        integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
        crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns"
        crossorigin="anonymous"></script>

<script>
    let collapse = $('#collapse-button');
    collapse.on('shown.bs.collapse', function () {
        // console.log("Opened")
    });

    collapse.on('hidden.bs.collapse', function () {
        // console.log("Closed")
    });
</script>
