<!--<div class="row mb-7" *ngIf="ready">
    <div class="col-12 p-0">
            <div class="category-img">-->
            <!-- <picture>
                    <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                    <source  media="(min-width: 48em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                    <source  srcset="{{image_url}}{{data.image_mobile.jpg[0].image}}">
                    <img  src="{{image_url}}{{data.image_mobile.jpg[0].image}}" alt="" class="img-fluid" />
            </picture> -->
           <!--     <picture>
                   &lt;!&ndash; <source media="(min-width: 62em)" srcset="assets/img/startseite/Produktrange_1950x400px.webp" type="image/webp">&ndash;&gt;
                    <source media="(min-width: 62em)" srcset="assets/img/startseite/Produktrange_1950x400px(mai).jpg" type="image/jpeg">
                    <source media="(min-width: 36em)" srcset="assets/img/startseite/Produktrange_1100x850px.webp" type="image/webp">
                    <source media="(min-width: 36em)" srcset="assets/img/startseite/Produktrange_1100x850px.jpg" type="image/jpeg">
                    <source srcset="assets/img/startseite/Produktrange_330x330px.webp" type="image/webp">
                    <source srcset="assets/img/startseite/Produktrange_330x330px.jpg" type="image/jpeg">
                    <img src="assets/img/startseite/Produktrange_330x330px.jpg" alt="Meisterpizza" width="330" height="330" class="img-fluid col-12 px-0">
                </picture>
            <div class="category-title">
                <p class="category-title-inner">
                </p>
            </div>
        </div>
    </div>-->
    <!-- <div class="col-12 d-flex flex-column justify-content-center text-center">
        <h2>{{data.headline}}</h2>
        <div [innerHTML]="data.text|safeHtml"></div>
    </div> -->
<!--</div>-->



<!-- ************ Для Задачи 292 ************-->

<div class="row mb-7" *ngIf="ready">
    <div class="col-12 p-0">
        <div class="category-img" *ngIf="dataHasImg">
            <picture>
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image.jpg[0].image }}">
                <source  media="(min-width: 48em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                <source  srcset="{{image_url}}{{data.image_mobile.jpg[0].image}}">
                <img  src="{{image_url}}{{data.image_mobile.jpg[0].image}}" alt="" class="img-fluid" />
            </picture>
            <div class="category-title">
                <p class="category-title-inner">
                   <!--Aktion -->
                </p>
            </div>
        </div>
    </div>
 <!--   <div class="col-xl-6 d-flex flex-column justify-content-center text-center">
        <h2>{{data.headline}}</h2>
        <div [innerHTML]="data.text|safeHtml"></div>
    </div>-->
</div>
