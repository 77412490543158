import { Component, OnDestroy, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FrontendService } from '../../services/frontend.service';
import { CartService } from '../../services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { CartPageComponent } from '../2-content/cart-page/cart-page.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { interval } from 'rxjs';
import { timer } from 'rxjs';
import { DateService } from '../../services/date.service';
import { AllergeneComponent } from '../4-footer/allergene/allergene.component';

// import { window } from 'rxjs/operators';

@Component({
    selector: 'app-wrapper',
    templateUrl: './wrapper.component.html',
    styleUrls: ['./wrapper.component.scss'],
    // directives: [ChildCmp]
})

export class WrapperComponent implements OnInit, OnDestroy {

    // public stuff  - - - - - - - - - - - - - - - -
   /* isJobs: boolean = false;*/
    is_open: boolean = false;
    closing_diff = 15;
    master_cat_name = '';

    shop_schliesst_bald: boolean = false;
    shop_closed: boolean = false;

    // if activated the whole store is under maintenance and nothing will be rendered (critical option!!)
    storeIsClosed: boolean = false;
    // check if requests are done
    ready = false;

    open_end: number;
    pickup: boolean = false;
    delivery_time: any;
    delivery_date: any;
    delivery_later: boolean;

    opening_hours_array: any[] = [];
    special_opening_hours_array: any[] = [];
    storeData: any;
    openingHours: any;
    shop_close_countdown: number = 0;
    shop_close_countdown_show: boolean = false;
    relocateDeliveryTimePopup: boolean = false;

    subscription: Subscription;
    showSessionExpiredPopup: boolean;
    masterCategories: any = [];

    // valid_time = true;

    showUpButton = false;

    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];
    private interval: any

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private cartService: CartService,
        private route: ActivatedRoute,
        private router: Router,
        private user_service: UserService,
        private auth_service: AuthService,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any,
        private dateService: DateService,
    ) { }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        if (window.pageYOffset >= 600) {
            this.showUpButton = true;
        } else if (window.pageYOffset < 600) {
            this.showUpButton = false;
            // this.showDownButton = true;
        }
    }




    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {

      /*  if (this.router.url.includes('jobs')) {
            this.isJobs = true;
        } else {
            this.isJobs = false;
        }*/


        registerLocaleData(localeDe, 'de');


        this.cartService.on_pickup_observable.subscribe(val => {
            this.pickup = val;
        });

        this.cartService.deliveryTimeObservable.subscribe(val => {
            this.delivery_time = val;
        });

        this.cartService.deliveryLaterObservable.subscribe(val => {
            this.delivery_later = val;
        });

        this.subscriptions.push(this.frontend_service.getStoreData().subscribe(
            (data: any) => {
                this.storeData = data;
                this.ready = true;
            }));

        this.subscriptions.push(this.frontend_service.getOpeningHoursNow().subscribe(
            (data: any) => {

                let deliveryLater = false;
                this.cartService.deliveryLaterObservable.subscribe(val => {
                    deliveryLater = val;
                });

                let deliveryTime = '';
                this.cartService.deliveryTimeObservable.subscribe(val => {
                    deliveryTime = val;
                });


                let deliveryDate: any = false;
                this.cartService.deliveryDateObservable.subscribe(val => {
                    deliveryDate = val;
                    this.delivery_date = val;
                });


                let deliveryDatetime = deliveryDate;
                deliveryDatetime.setHours(deliveryTime.substring(0, 2));
                deliveryDatetime.setMinutes(deliveryTime.substring(3, 5));
                deliveryDatetime.setSeconds(0);
                deliveryDatetime.setMilliseconds(0);


                const endOfDay = this.dateService.newDate();
                endOfDay.setHours(23);
                endOfDay.setMinutes(59);
                endOfDay.setSeconds(59);
                endOfDay.setMilliseconds(0);


                if (deliveryDatetime < endOfDay) {

                    this.interval = timer(0, 5000).subscribe(
                        /*this.interval = timer(0, 5000).subscribe(*/
                        val => {

                            deliveryDatetime = deliveryDate;
                            deliveryDatetime.setHours(deliveryTime.substring(0, 2));
                            deliveryDatetime.setMinutes(deliveryTime.substring(3, 5));
                            deliveryDatetime.setSeconds(0);
                            deliveryDatetime.setMilliseconds(0);

                            const now = this.dateService.newDate();

                            let countdownTo: any = false;

                            // console.warn('========== Öffnungszeiten prüfen ==========');

                            for (const openHoursIndex of data) {

                                const open_to = this.dateService.newDate();
                                open_to.setHours(openHoursIndex.time_to.substring(0, 2));
                                open_to.setMinutes(openHoursIndex.time_to.substring(3, 5));
                                open_to.setSeconds(0);
                                open_to.setMilliseconds(0);

                                const open_from = this.dateService.newDate();
                                open_from.setHours(openHoursIndex.time_from.substring(0, 2));
                                open_from.setMinutes(openHoursIndex.time_from.substring(3, 5));
                                open_from.setSeconds(0);
                                open_from.setMilliseconds(0);

                                if (deliveryLater === false) {
                                    if (now >= open_from && now <= open_to) {
                                        countdownTo = open_to;
                                    }
                                } else {
                                    if (deliveryDatetime >= open_from && deliveryDatetime <= open_to) {
                                        countdownTo = open_to;
                                    }
                                }

                            }

                            if (countdownTo !== false) {

                                const diff = Math.round(((countdownTo.getTime() - now.getTime()) / 1000) / 60);
                                /**
                                 * Anzeigen wenn <= 30Minuten bis Laden schließung
                                 */
                                if (diff >= 0 && diff <= 30) {
                                    this.shop_close_countdown_show = true;
                                    this.shop_close_countdown = Math.round(((countdownTo.getTime() - now.getTime()) / 1000) / 60);
                                } else if (diff < 0) {
                                    this.shop_close_countdown_show = false;
                                    this.shop_closed = true;
                                }

                            } else {
                                this.shop_close_countdown_show = false;
                                if (
                                    !this.router.url.match('\/impressum') &&
                                    !this.router.url.match('\/jobs') &&
                                    !this.router.url.match('\/newsletter') &&
                                    !this.router.url.match('\/ueberuns') &&
                                    !this.router.url.match('\/agb') &&
                                    !this.router.url.match('\/allergene') &&
                                    !this.router.url.match('\/restaurant') &&
                                    !this.router.url.match('\/datenschutz')
                                ) {
                                    this.shop_closed = true;
                                }

                            }


                        });
                }

            }));

        // set shop open or closed for dialog in frontend
        // this.subscriptions.push(this.frontend_service.getOpeningHoursNow().subscribe(
        //     (data: any) => {
        //         this.openingHours = data;
        //         // this.checkHappyHourOrLunch();
        //         // const curr_date = this.dateService.newDate();
        //         // const current_time = curr_date.toLocaleTimeString();
        //         // for (const oh of this.openingHours) {


        //         //     if (current_time >= oh.time_from && current_time < oh.time_to) {
        //         //         this.shop_closed = false;
        //         //         break;
        //         //     } else {
        //         //         this.shop_closed = true;
        //         //     }
        //         // }
        //     }));

        // get opening hours for opening type "standard week day"
        this.subscriptions.push(this.frontend_service.getOpeningHours(1).subscribe(
            (data: any[]) => {
                this.opening_hours_array = data;
            }));

        // get opening hours for special opening dates
        this.subscriptions.push(this.frontend_service.getOpeningHours(2).subscribe(
            (data: any[]) => {
                this.special_opening_hours_array = data;
            }));

        this.subscriptions.push(this.cartService.showSessionExpiredPopupObservable.subscribe(
            data => { this.showSessionExpiredPopup = data; }));

        this.subscriptions.push(this.cartService.relocateDeliveryTimePopupObservable.subscribe(
            data => { this.relocateDeliveryTimePopup = data; }));

        this.master_cat_name = this.route.snapshot.paramMap.get('category');

        // load session storage (cart items) on refresh
        // this.cart_service.loadCartSessionStorage();
        // this.user_service.loadUserSessionStorage();
        // this.cartPageComponent.doSomething();
        const source = interval(10000);
        this.subscription = source.subscribe(val => this.cartService.checkSession());
        
        if (typeof sessionStorage.getItem('deliveryTypeSelected') !== 'string' && !this.isStaticSite()) {
            this.router.navigate(['willkommen', 'start']);
        }
    }

    isStaticSite() {

        if (
            !this.router.url.match('\/impressum') &&
            !this.router.url.match('\/jobs') &&
            !this.router.url.match('\/newsletter') &&
            !this.router.url.match('\/ueberuns') &&
            !this.router.url.match('\/agb') &&
            !this.router.url.match('\/allergene') &&
            !this.router.url.match('\/restaurant') &&
            !this.router.url.match('\/datenschutz')
        ) {
            return false;
        } else {
            return true;
        }

    }


    ngOnDestroy() {


        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
        this.subscription.unsubscribe();

        if (this.interval) {
            this.interval.unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {
        // console.log(this);
    }

    /* redirect to welcome step 1 and reset all session data */
    resetPage() {
        sessionStorage.clear();
        this.router.navigate(['willkommen', 'start']);
        this.showSessionExpiredPopup = false;
        setTimeout(function () {
            location.reload();
        }, 500);
    }

    onToggle() {
        this.is_open = !this.is_open;
    }

    closeRelocateDeliveryTimePopup() {
        this.relocateDeliveryTimePopup = false;
        this.cartService.closeRelocateDeliveryTimePopup();
    }

    initUser() {

    }

    toTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        // TODO: fix this, does not work as expected; scrolls only one pixel
        // this.pageScrollService.scroll({
        //     document: this.document,
        //     scrollTarget: '.header',
        //   });
    }

    recalcDateTime()
    {
        this.cartService.checkShopOpenOrClosed().then(() => {
            this.cartService.setDeliveryDateAndTime(this.delivery_date, this.delivery_time);
        });
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

}
