import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Router, NavigationEnd} from '@angular/router';
import { CartPageComponent } from './wrapper-components/2-content/cart-page/cart-page.component';
import {CartService} from './services/cart.service';
import {HttpClient} from '@angular/common/http';
import {DateService} from './services/date.service';
// import { trigger, animate, style, group, animateChild, query, stagger, transition, state } from '@angular/animations';
declare let gtag: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   trigger('routerTransition', [
  //     transition('* <=> *', [
  //       query(':enter, :leave', style({ position: 'fixed', opacity: 1 })),
  //       group([
  //         query(':enter', [
  //           style({ opacity: 0 }),
  //           animate('1200ms ease-in-out', style({ opacity: 1 }))
  //         ]),
  //         query(':leave', [
  //           style({ opacity: 1 }),
  //           animate('1200ms ease-in-out', style({ opacity: 0 }))]),
  //       ])
  //     ])
  //   ])
  // ]
})


export class AppComponent implements OnInit {
    title = 'app';



    constructor(
        public router: Router,
        public cartService: CartService,
        private http: HttpClient,
        private dateService: DateService
    ) {

        this.router.events.subscribe(event => {

                if (event instanceof NavigationEnd) {

                    // gtag('config', 'UA-114751372-2',
                    //     {
                    //         'page_path': event.urlAfterRedirects
                    //     }
                    // );

                    this.cartService.preCheck();
                }
            }
        );
    }
  ngOnInit() {
    this.dateService.load(true);
  }
}
