import {
    Component,
    OnDestroy,
    OnInit,
    Input,
    HostListener,
    Renderer2,
    Inject,
    AfterViewInit,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { CartService } from '../../../services/cart.service';
import { ArticleGroup } from '../../../../_classes/ArticleGroup';
import { MainService } from '../../../services/main.service';
import { FrontendService } from '../../../services/frontend.service';
import { User } from '../../../../_classes/User';
import { UserService } from '../../../services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { disableDebugTools } from '@angular/platform-browser';
import { registerLocaleData, DOCUMENT } from '@angular/common';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import localeDe from '@angular/common/locales/de';
import { HttpClient } from '@angular/common/http';
import { CouponsService } from '../../../services/coupons.service';

import { Anmeldung } from '../../welcome/step-5/welcomenDaten';
import { SessionService } from '../../../services/session.service';

declare var PayEngineWidget: any;
declare var jQuery: any;
declare var paypal: any;
declare var gtag: any;


import { MatRadioChange, MatStepper, MatHorizontalStepper } from '@angular/material';
import { FormGroup, FormControl, NgControl } from '@angular/forms';
import { Prices } from 'src/_classes/Prices';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AddressItem } from '../adress-page/adress-page.component';
import { DateService } from '../../../services/date.service';
import { PaypalService } from '../../../services/paypal.service';
import { PaymentsService } from '../../../services/payments.service';

// Decorators
@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss'],
})

/** ==================================================================================================================
 *  Component Logic
 *  ================================================================================================================ */
export class CartPageComponent implements OnInit, OnDestroy, AfterViewInit {

    /** ==================================================================================================================
     *  Component Variables
     *  ================================================================================================================ */
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('adress_form') adress_form: any;

    // TODO: check all price vars and filter obsolete ones; due to new feature requests and extensions there should be a simpler solution with less variables
    on_pickup: boolean;
    cart_items: ArticleGroup[] = [];
    prices_regular: number[] = [];
    prices_pickup: number[] = [];
    prices_happy: number[] = [];
    prices_lunch: number[] = [];
    prices_mega_deal: number[] = [];
    prices_regular_plus_free_addables: number[] = [];
    prices_pickup_plus_free_addables: number[] = [];
    transaction_id: string = '';
    price_for_min_val = 0;
    price_for_min_val_happy = 0;
    min_val: any = null;
    image_url: string;
    comment: string = '';
    cupon_code: string = '';
    no_marketing: boolean = false;
    payment_type: number;
    on_happy_hour: boolean;
    onLunch: boolean;
    later_on_happy_hour: boolean;
    laterOnLunch: boolean;
    send_later = false;
    deliveryDate = new Date();
    currDateDeliv: boolean = false;
    deliveryTime: string;
    show_deliveryDate: string = '';
    read_agb: boolean = false;
    read_datenschutz: boolean = false;
    discount_percent: number = 0;
    discount_val: number = 0;
    min_quan_sur: number = 0;
    addMinQuantitySurcharge: boolean;
    later_min_quan_sur: number = 0;
    // the total sum of cart and tip and surcharge
    // totalSum = 0;
    // final_price_pickup = 0;
    finalPriceDigitalPayment: number;
    // date borders for date picker
    min_date = new Date();
    max_date = new Date();
    valid_time: boolean = false;
    shopIsOpen: boolean = false;
    user: User;
    refactor_adress: string[] = [];
    control_mail: string;
    b_cookie: boolean = false;
    b_create_account: boolean = false;
    b_deliver_to_firma: boolean = false;
    has_account: boolean = false;
    user_password: string = '';
    current_time: any;
    show_visa_info: boolean = false;
    show_sepa_info: boolean = false;
    show_dialog: boolean = false;
    payment_error: boolean = false;
    futureDate = false;

    completedStep1 = false;
    completedStep2 = false;

    show_calc_address: boolean = false;
    disableTipInputCheck = true;
    disableTipInput = false;

    // Coupon error handling
    errorCodeIsEmpty = false;
    errorCodeNotValid = false;
    errorCouponNoItemsInCart = false;
    errorCouponsAlreadyInCart = false;
    errorRedeemCountInvalid = false;
    errorMinorderValue = false;
    singleCoupon = false;
    couponsInCart = false;
    tipNotValid = false;

    couponForm = new FormGroup({
        couponCode: new FormControl(''),
    });

    showSuccess: boolean = false;
    showCancel: boolean = false;
    showError: boolean = false;
    ready = false;
    amazonWidgetType = 'button';

    // respone from order submit, also error if something went wrong
    orderResponse: any = '';
    winorderTrackingStatus: number = -1;
    order_submitted: boolean = false;

    megaDealCounter: any;
    couponData: any;
    tip: number | string;
    tipFixedPercentage: number = -1;
    showAmazonButton: boolean;

    /* if shop is open or closed */
    shopClosed: boolean;
    shopClosedUntilTomorrow: boolean;

    couponCounter: number[] = [];
    // private vars
    private subscriptions: Subscription[] = [];
    private opening_hours: any;

    AgbDsEvent: EventEmitter<boolean> = new EventEmitter();


    // create new reactive formgroup for form
    // https://angular.io/guide/reactive-forms
    tipFormGroup = new FormGroup({
        tipFixedPercentage: new FormControl(null),
        tipValue: new FormControl({ value: '0,00', disabled: true })
    });

    amazonPayConfirmOrderResponse = {
        authorize: {
            ResponseStatus: ''
        }
    };

    /* price values */
    priceCart: number;
    priceTotal: number;

    searchPlzFormControl = new FormControl();
    filteredPlz: any;
    isLoadingPlz = false;

    // Street
    searchStreetFormControl = new FormControl();
    filteredStreet: any;
    isLoadingStreet = false;

    plzDataObject: any;
    checkRelocateDeliveryTimeInterval: any;
    agbDsValid: boolean = false;
    routeNavigation: any;

    AgbDsEventSubscription: any;
    couponRemovedSubscription: any;
    couponRemoved: any = false;

    addressIdValid: boolean = false;
    PayPalLibLoaded = false;

    payments: any = null;


    storeData: any;

    sizeSmall = true;
    placeholder = false;

    anmeldung: Anmeldung;

    // HeaderBilder
    // default für die Fenstergröße max-width: 576px
    headerbild_MP = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Dankeschoen.jpg";

    headerbild_MP_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Dankeschoen.jpg";

    sizeSmalll = true;

    plzPopUp = false;

    onlineBonus = 0;

    molliesumm: any;
    molliedomain: any;
    molliebackend: any;
    mollieserviceurl: any;
    mollieinprocess = false;
    mollieHash = '';
    molliewindow: any;
    mollieurl: '';
    interval: any;
    priceModifications = [];
    couponsArr: any;
    couponErrorStr = '';
    couponCodeNum = '';
    couponSuccessStr = '';
    storageLoaded = false;

    checkCartPrice: any;
    showPriceDiffPopup = false;
    priceCartTemp: any;
    disableButtonBuy = false;
    showOrderDouble = false;
    confirmDoubleOrder = false;
    doubleOrder = 'false';
    addressId: any;
    plzMessage = '';


    // constructor
    constructor(
        private cart_service: CartService,
        private frontend_service: FrontendService,
        private main_service: MainService,
        private user_service: UserService,
        private router: Router,
        private auth_service: AuthService,
        private http: HttpClient,
        private coupons: CouponsService,
        private dateService: DateService,
        private paypalService: PaypalService,
        private paymentsService: PaymentsService,

        public sessionService: SessionService
    ) {


        this.min_date = this.dateService.newDate();
        this.max_date = this.dateService.newDate();
        this.deliveryDate = this.dateService.newDate();

    }


    /** ==================================================================================================================
     *  Component Lifecycle
     *  ================================================================================================================ */
    ngOnInit() {
        this.dateService.getServerDateTime();
        this.cart_service.checkRelocateDeliveryTime();
        this.getAnmeldung();
        this.sessionService.setEdit();

        this.setHeaderbild();

        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        if (this.router.url === '/shop/bestellen/warenkorb-index') {
            this.router.navigate(['/shop/bestellen/warenkorb']);
        }



        this.cart_service.checkInstore();
        registerLocaleData(localeDe, 'de');

        this.cart_service.loadCartSessionStorage();
        // Artikelliste erfassen
        this.subscriptions.push(this.cart_service.article_group_list_observable.subscribe(
            (data: any[]) => {
                this.cart_items = data;

                this.initPrices();
                this.ready = true;
            },
            err => console.log('Observer got a error notification:' + err),
            () => console.log('Observer got a complete notification'),
        ));

        this.subscriptions.push(this.cart_service.priceCartObservable.subscribe(
            data => {
                this.priceCart = data;
            },
            err => console.log('Observer got a error notification:' + err),
            () => {

            }
        ));

        this.subscriptions.push(this.cart_service.priceTotalObservable.subscribe(
            data => {
                this.priceTotal = data;
            },
            err => console.log('Observer got a error notification:' + err),
            () => {

            }
        ));

        // abholung oder lieferung
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            data => {
                this.on_pickup = data;
                if (this.on_pickup) {
                    this.cart_service.setMinVal('0');
                }
                // this.on_pickup_select = data ? true : false;
            }));

        this.subscriptions.push(this.cart_service.on_happy_hour_observable.subscribe(
            data => {
                this.on_happy_hour = data;
            }));

        this.subscriptions.push(this.cart_service.onLunchObservable.subscribe(
            data => {
                this.onLunch = data;
            }));


        /* get delivery date from service */
        this.subscriptions.push(this.cart_service.deliveryDateObservable.subscribe(data => {
            this.deliveryDate = data;
        }));
        /* get delivery time form service */
        this.subscriptions.push(this.cart_service.deliveryTimeObservable.subscribe(data => {
            this.deliveryTime = data;
        }));
        /* get true if shop is currently closed, false when open (default) */
        this.subscriptions.push(this.cart_service.shopClosedObservable.subscribe(data => {
            this.shopClosed = data;
        }));
        this.subscriptions.push(this.cart_service.shopClosedUntilTomorrowObservable.subscribe(data => {
            this.shopClosedUntilTomorrow = data;
        }));
        this.subscriptions.push(this.cart_service.min_val_observable.subscribe(
            data => {
                this.min_val = data;
            }));

        this.subscriptions.push(this.cart_service.min_quan_sur_observable.subscribe(
            data => {
                this.min_quan_sur = data;
            }));

        this.subscriptions.push(this.cart_service.addMinQuantitySurchargeObservable.subscribe(
            data => {
                this.addMinQuantitySurcharge = data;
            }));

        this.subscriptions.push(this.user_service.user_observable.subscribe(
            data => {
                this.user = data;
                if (this.user.adress.plz && this.user.adress.plz !== '') {
                    this.searchPlzFormControl.setValue({ plz: this.user.adress.plz });
                    this.plzDataObject = {
                        plz: this.user.adress.plz
                    };
                }

                if (this.user.adress.street && this.user.adress.street !== '') {
                    this.searchStreetFormControl.setValue({ street: this.user.adress.street });

                }
                sessionStorage.setItem('tempUser', JSON.stringify(this.user));
            }));

        this.frontend_service.getOrderSuccessful().subscribe(data => {
            this.orderResponse = data;
        });

        this.frontend_service.getWinorderTrackingStatus().subscribe(data => {
            this.winorderTrackingStatus = data;
        });

        this.frontend_service.getMollieUrl().subscribe(data => {
            this.mollieurl = data;
        });

        this.frontend_service.getAmazonPayConfirmOrderResponse().subscribe(data => {
            this.amazonPayConfirmOrderResponse = data;
        });

        this.subscriptions.push(this.cart_service.megaDealCounterObservable.subscribe(
            data => {
                this.megaDealCounter = data;
            }));

        this.subscriptions.push(this.cart_service.tipObservable.subscribe(
            data => {

                this.tip = data;
            }));

        this.subscriptions.push(this.cart_service.priceModificationsObservable.subscribe(
            data => {

                this.priceModifications = data;
            }));

        this.subscriptions.push(this.cart_service.couponsArrObservable.subscribe(
            data => {

                this.couponsArr = data;
                // this.couponSuccessStr = '';
                this.couponErrorStr = '';
                this.getCouponCode();
                if (this.couponCodeNum == '') {
                    this.couponForm.get('couponCode').reset();
                }
                // this.getCouponErrorStr();

            }));

        this.subscriptions.push(this.cart_service.tipFixedPercentageObservable.subscribe(
            data => {
                this.tipFixedPercentage = data;
                if (this.tipFixedPercentage === 0) {
                    this.tipFormGroup.get('tipValue').enable();
                }
            }));

        this.subscriptions.push(this.cart_service.deliveryLaterObservable.subscribe(
            data => {
                this.send_later = data;
                // this.ready = true;
            }));

        this.subscriptions.push(this.cart_service.storageLoadedObservable.subscribe(
            data => {
                this.storageLoaded = data;
                // this.ready = true;
            }));

        this.subscriptions.push(this.cart_service.showPriceDifferencesPopupObservable.subscribe(
            data => {
                this.showPriceDiffPopup = data;
                // this.ready = true;
            }));

        this.subscriptions.push(this.cart_service.priceCartTempObservable.subscribe(
            data => {
                this.priceCartTemp = data;
                // this.ready = true;
            }));


        this.image_url = this.image_url = this.main_service.image_url;

        this.checkIfCouponInCart();
        this.checkIfSingleCouponInCart();

        // max erlaubtes vorbestellerdatum definieren
        let preorder_span = this.main_service.preorder_span;
        this.max_date.setDate(this.max_date.getDate() + preorder_span);

        const curr_date = this.dateService.newDate();
        // get current states from cart service
        this.control_mail = this.cart_service.mail_control;
        // cookie haken anmachen, falls schon cookie vorhanden
        this.b_cookie = this.user_service.user_set_cookie;

        if (this.b_cookie) {
            this.cart_service.mail_control = this.user.mail;
            this.control_mail = this.user.mail;
        }

        if (this.user_service.user_has_account) {
            this.has_account = true;
            this.cart_service.mail_control = this.user.mail;
            this.control_mail = this.user.mail;
        }

        // get current day opening hours on init
        let dateQueryString = curr_date.getFullYear() + '-' + (curr_date.getMonth() + 1) + '-' + curr_date.getDate();
        let deliveryDateStr = this.deliveryDate.getFullYear() + '-' + (this.deliveryDate.getMonth() + 1) + '-' + this.deliveryDate.getDate();
        let deliveryTimeStr = this.deliveryDate.getHours() + ':' + this.deliveryDate.getMinutes();
        if (dateQueryString == deliveryDateStr && this.cart_service.next_delivery_later_time == deliveryTimeStr) {
            this.currDateDeliv = true;
        }

        this.tipFormGroup.get('tipFixedPercentage').setValue(sessionStorage.getItem('tipFixedPercentage'));
        this.tipFormGroup.get('tipValue').setValue(Math.abs(parseFloat(sessionStorage.getItem('tip'))));
        const tipInit = Math.abs(parseFloat(sessionStorage.getItem('tip')));
        this.cart_service.setTip((isNaN(tipInit) ? 0 : tipInit));

        // preselect user title
        this.user.title = 'Herr';

        if (sessionStorage.getItem('pickup') === '0') {
            // this.searchPlzFormControl.disable();
            // this.searchStreetFormControl.disable();
            // this.searchPlzFormControl.setValue(this.user.adress);
            // this.searchStreetFormControl.setValue(this.user.adress);
        }

        this.checkRelocateDeliveryTimeInterval = setInterval(data => {
            this.cart_service.checkRelocateDeliveryTime();
        }, 60000);

        this.checkCartPrice = setInterval(data => {
            // this.cart_service.recalculatePrices(true);
            this.cart_service.loadCartSessionStorage(false, true);
        }, 60000);


        this.couponRemovedSubscription = this.coupons.couponRemoved.subscribe(data => {
            this.couponRemoved = data;
        });


        this.paymentsService.load().then(payments => {
            this.payments = payments;
        });

        this.updateOnlineBonus();
        if (sessionStorage.getItem('confirm_double_order') && sessionStorage.getItem('confirm_double_order') == '1') {
            this.confirmDoubleOrder = true;
        }
        this.checkDeliveryArea();

        this.read_agb = true;
        this.read_datenschutz = true;
        this.agbDsValid = true;
        this.AgbDsEvent.emit(true);
    }


    // Details Attribut entfernen
    setOpen() {
        // let details = document.getElementById("det");
        // let small = window.matchMedia('(max-width: 991px)').matches; //false oder true
        // if (!small) return;
        // else {
        //     details.removeAttribute("open");
        // }

    }

    ngAfterViewInit() {

        this.coupons.checkCouponValidity();
        this.initPrices();
        this.autocompletePlz();
        this.autocompleteStreet();
        this.updateOnlineBonus();


    }

    ClearCouponRemoved() {
        this.couponRemoved = false;
    }

    changePayment() {

        if (this.AgbDsEventSubscription) {
            this.AgbDsEventSubscription.unsubscribe();
        }

        sessionStorage.setItem('payment_type',  '' + this.payment_type);
            this.cart_service.recalculatePrices();

        if (this.payment_type == 2) {
            const that = this;
            setTimeout(function () {

                that.paypalService.loadLib().then(() => {
                    that.initPayPalBuyButton();
                });

            }, 0);
        }
        if (this.payments && this.payment_type == this.payments.mollie.id) {
            this.molliesumm = this.priceTotal - this.onlineBonus;
            this.molliedomain = this.storeData.domain;
            if (this.molliedomain.toLowerCase().indexOf("awag-it.de") >= 0) {
                if (this.molliedomain.toLowerCase().indexOf("stage") >= 0) {
                    this.molliebackend = 'https://stage-system-mp.awag-it.de';
                } else {
                    this.molliebackend = 'https://dev-pizzaback.awag-it.de';
                }

            } else {
                this.molliebackend = 'https://system.meisterpizza.de';
            }
        }

    }

    closeChild() {
        this.molliewindow.close();
        this.molliewindow = '';
        clearInterval(this.interval);
        this.mollieinprocess = false;
    }

    getmolliehref() {
        // var cartItems = this.getPayPalCartItems();
        // cartItems.push({deliveryDate: this.deliveryDate});
        if (this.cart_items.length < 1) {
            return false;
        }

        if (this.order_submitted === true) {
            return false;
        }

        clearInterval(this.checkRelocateDeliveryTimeInterval);

        this.disableButtonBuy = true;
        this.order_submitted = true;
        this.placeholder = true;
        let data: any;
        let checkoutArr: any = this.getCheckout();
        // data = JSON.stringify(checkoutArr);

        this.frontend_service.onSendData(checkoutArr);

        this.frontend_service.getOrderSuccessful().subscribe(
            response => {
                if (response.status == true || response.status == 'Saving successful') {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.cart_items = [];
                    this.resetUserData();
                    this.cart_service.resetCartData();
                    clearInterval(this.checkRelocateDeliveryTimeInterval);
                    window.scrollTo(0, 0);

                } else {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.payment_error = true;
                    window.scrollTo(0, 0);
                    this.checkRelocateDeliveryTimeInterval = setInterval(data => {
                        this.cart_service.checkRelocateDeliveryTime();
                    }, 60000);
                }
                this.order_submitted = false;
            }
        );
        this.placeholder = false;

        return;




        // var hash = this.hashCode(checkout);
        // this.mollieHash = hash;
        // this.molliesumm = this.priceTotal - this.onlineBonus;
        // // this.molliewindow = window.open(this.molliebackend + '/api/payments/mollie?sum=' + this.molliesumm + '&domain=' + this.molliedomain + '&hash=' + hash + '&checkout' + checkout, '_blank', 'location=yes,scrollbars=yes,status=yes');
        // this.mollieserviceurl = this.molliebackend + '/api/payments/mollie?sum=' + this.molliesumm + '&domain=' + this.molliedomain + '&hash=' + hash + '&checkout' + checkout;


        // var checkoutForm = document.createElement("form");
        // checkoutForm.target = "_blank";
        // checkoutForm.method = "POST";
        // checkoutForm.action =  this.mollieserviceurl;
        // // Create an input
        // var mapInput = document.createElement("input");
        // mapInput.type = "text";
        // mapInput.name = "checkout";
        // mapInput.value = checkout;
        // // Add the input to the form
        // checkoutForm.appendChild(mapInput);
        // // Add the form to dom
        // document.body.appendChild(checkoutForm);
        // // Just submit
        // checkoutForm.submit();






        // this.mollieinprocess = true;
        // // if (window.focus) {
        // //     this.molliewindow.focus();
        // // }
        // var that = this;

        // this.resetUserData();
        // this.cart_service.resetCartData();

        // var interval = setInterval(function() {
        //         var resp = that.frontend_service.checkMolliePayment(hash);
        //         if (that.frontend_service.mollieresp == 'success') {
        //             clearInterval(interval);
        //             that.onSubmitMollie();
        //             // that.mollieinprocess = false;
        //         } else if (that.frontend_service.mollieresp == 'failed') {
        //             // that.molliewindow.close();
        //             // that.mollieinprocess = false;
        //             clearInterval(interval);
        //         }
        //     // }
        // }, 1000);
        // this.interval = interval;
        // this.scrollTop();
    }

    onSubmitMollie() {
        if (this.order_submitted === true) {
            return false;
        }
        this.order_submitted = true;
        this.placeholder = true;
        let data: any;

        if (this.b_cookie) {
            this.user_service.saveUserToCookie();
        }

        if (this.b_create_account) {
            this.auth_service.signupUser(this.user.mail, this.user_password, this.user.title, this.user.fir_name, this.user.fam_name,
                this.user.adress.plz, this.user.adress.city, this.user.adress.district, this.user.adress.street, this.user.adress.street_number, this.user.adress.etage, this.user.phone);
        }

        this.frontend_service.onSendData(this.mollieHash);

        this.frontend_service.getOrderSuccessful().subscribe(
            response => {
                if (response.status == true || response.status == 'Saving successful') {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.cart_items = [];
                    // this.resetUserData();
                    // this.cart_service.resetCartData();
                    clearInterval(this.checkRelocateDeliveryTimeInterval);
                    window.scrollTo(0, 0);

                } else {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.payment_error = true;
                    window.scrollTo(0, 0);
                }
                this.order_submitted = false;
            }
        );
        this.placeholder = false;

    }

    hashCode(s){
        return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
    }

    paymentAvailable(name) {

        if (!this.payments) {
            return false;
        }
        return (name in this.payments) ? true : false;
    }

    checkValidation() {

        const is_valid = (this.read_datenschutz && this.read_agb) ? true : false;
        this.agbDsValid = is_valid;
        this.AgbDsEvent.emit(is_valid);

    }

    getPayPalCartItems() {

        // const cart_items = [];

        // for (const cart_item in this.cart_items) {
        //     cart_items.push({
        //         name: this.cart_items[cart_item].article.name,
        //         quantity: this.cart_items[cart_item].amount,
        //         category: 'PHYSICAL_GOODS',
        //         unit_amount: {
        //             currency_code: 'EUR',
        //             value: this.cart_items[cart_item].prices.real_price_total / this.cart_items[cart_item].amount,
        //         },
        //     });
        // }

        // if (this.min_quan_sur > 0) {
        //     cart_items.push({
        //         name: 'Mindermengenaufschlag',
        //         quantity: 1,
        //         category: 'PHYSICAL_GOODS',
        //         unit_amount: {
        //             currency_code: 'EUR',
        //             value: this.min_quan_sur
        //         },
        //     });
        // }

        // if (this.tip > 0) {
        //     cart_items.push({
        //         name: 'Trinkgeld',
        //         quantity: 1,
        //         category: 'PHYSICAL_GOODS',
        //         unit_amount: {
        //             currency_code: 'EUR',
        //             value: this.tip
        //         },
        //     });
        // }

        // return cart_items;

    }

    initPayPalBuyButton() {

        return;
        const priceTotal = Math.round(((this.priceTotal - this.onlineBonus) + Number.EPSILON) * 100) / 100;
        const that = this;
        const cart_items = this.getPayPalCartItems();


        jQuery('#paypal-button-container1 > #pp1').remove();
        jQuery('#paypal-button-container1').html('<div id="pp1"></div>');
        paypal.Buttons({
                onApprove: function (data, actions) {

                    that.transaction_id = data.orderID;
                    that.onSubmit();

                },
                onError: function (err) {

                },
                onInit: function (data, actions) {

                    actions.disable();

                    that.AgbDsEventSubscription = that.AgbDsEvent.subscribe(
                        is_valid => {
                            if (is_valid === true) {
                                actions.enable();
                            } else {
                                actions.disable();
                            }
                            ;
                        }
                    );
                    that.checkValidation();

                },
                onClick: function (data, actions) {

                },
                createOrder: function (data, actions) {

                    return actions.order.create({
                        intent: 'CAPTURE',
                        purchase_units: [{
                            amount: {
                                currency_code: 'EUR',
                                value: priceTotal,
                                breakdown: {
                                    item_total: {
                                        currency_code: 'EUR',
                                        value: priceTotal
                                    }
                                }
                            },
                            items: cart_items
                        }],
                    });
                },
                fundingSource: paypal.FUNDING.PAYPAL,
                style: {
                    label: 'buynow',
                    shape: 'pill'
                }
            }
        ).render('#pp1');

        this.checkValidation();

    }


    ngOnDestroy() {

        clearInterval(this.checkRelocateDeliveryTimeInterval);
        clearInterval(this.checkCartPrice);
        this.frontend_service.resetWinorderTrackingStatus();

        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }

        this.couponRemovedSubscription.unsubscribe();

    }

    // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmalll) return;
        this.sizeSmalll = small;
        if (small === false) {
            this.headerbild_MP = this.headerbild_MP_Desktop;
        }
        else {
        }
    }

    /** ==================================================================================================================
     *  Public Functions
     *  ================================================================================================================ */
    scrollTop() {
      /* if (!this.adress_form.form.valid){console.log("this.adress_form.form.valid", this.adress_form.form.valid)} return;
        console.log("this.adress_form.form.valid ist falsch");*/
       /* if( this.on_pickup && (  !this.searchPlzFormControl.valid || !this.searchStreetFormControl.valid)) return;
        if ((this.addMinQuantitySurcharge == false && !this.on_pickup) && (this.min_val > this.priceCart)) return;*/
        window.scrollTo(0, 0);
        // !adress_form.form.valid  ||  ( on_pickup && (  !searchPlzFormControl.valid || !searchStreetFormControl.valid)) || ((addMinQuantitySurcharge == false && !on_pickup) && (min_val > priceCart))
    }

    checkIfSingleCouponInCart() {
        for (const cartItem of this.cart_items) {
            if (cartItem.article.is_coupon_product) {
                if (typeof cartItem.article.is_only_coupon_in_cart !== 'undefined' && cartItem.article.is_only_coupon_in_cart) {
                    this.singleCoupon = true;
                    break;
                } else {
                    this.singleCoupon = false;
                }
            }
        }
    }

    /**
     * TODO: Function Description
     */
    checkIfCouponInCart() {
        // let i = 0;
        for (const cartItem of this.cart_items) {
            if (cartItem.article.is_coupon_product) {
                this.couponCounter[cartItem.article.id] = cartItem.amount;
                this.couponsInCart = true;
                break;
            } else {
                this.couponCounter[cartItem.article.id] = -1;
                this.couponsInCart = false;
            }
            // i++;
        }
        this.errorRedeemCountInvalid = false;
    }

    /**
     * TODO: Function Description
     */
    onSubmitCouponForm() {
        const code = this.couponForm.get('couponCode').value;
        if (code !== '') {
            this.frontend_service.checkCouponCode(code).subscribe((data) => {
                    this.couponData = data;
                },
                (err) => console.error(err),
                () => {
                    if (this.couponData) {
                        if (this.couponData.coupon_type == 1) {  // product coupon
                            let issetSameProduct = this.cart_service.checkCouponProductInCart(this.couponData);
                            if (this.couponData.errorString && this.couponData.errorString != '') {
                                this.couponSuccessStr = '';
                                this.couponErrorStr = this.couponData.errorString;
                                return;
                            } else if (issetSameProduct) {
                                this.couponSuccessStr = '';
                                this.couponErrorStr = 'Es wurde bereits ein Coupon eingelöst!';
                                return;
                            } else if (this.priceTotal < this.couponData.min_order_value_on_pickup) {
                                this.couponSuccessStr = '';
                                this.couponErrorStr = 'Der Mindestbestellwert (' + this.couponData.min_order_value_on_pickup + '€) für diesen Code nicht erreicht.';
                            } else {
                                this.couponErrorStr = '';
                                this.redirectToActionProduct(this.couponData.coupon_content, this.couponData.only_coupon_in_cart);
                            }
                        } else { // discount coupon
                            // check coupon data
                            if (this.couponData.errorString && this.couponData.errorString != '') {
                                this.couponSuccessStr = '';
                                this.couponErrorStr = this.couponData.errorString;
                                return;
                            }
                            // calculate cart
                            let couponsInSession: any;
                            let couponsNew: any;
                            couponsInSession = [];
                            couponsNew = [];
                            if (sessionStorage.getItem('coupons') != 'undefined' && sessionStorage.getItem('coupons') != null && sessionStorage.getItem('coupons') != 'null') {
                                couponsInSession = JSON.parse(sessionStorage.getItem('coupons'));
                            }
                            sessionStorage.setItem('coupons', JSON.stringify(this.couponData));

                            this.cart_service.loadCartSessionStorage(true).then(() => {
                                if (sessionStorage.getItem('coupons') != 'undefined' && sessionStorage.getItem('coupons') != null && sessionStorage.getItem('coupons') != 'null') {
                                    couponsNew = JSON.parse(sessionStorage.getItem('coupons'));
                                }

                                if ((!couponsNew.errorString || couponsNew.errorString == '')
                                    && couponsInSession.coupon_content !== couponsNew.coupon_content)
                                { // new coupon code applied
                                    // sessionStorage.setItem('coupons', JSON.stringify(couponsNew));
                                    this.couponSuccessStr = 'Dein Code wurde eingelöst';
                                    this.couponErrorStr = '';
                                }
                                else if ((couponsNew.errorString && couponsNew.errorString != '')
                                        && couponsInSession.coupon_content != couponsNew.coupon_content)
                                { // new coupon code with error
                                    this.couponSuccessStr = '';
                                    this.couponErrorStr = couponsNew.errorString;
                                    sessionStorage.setItem('coupons', JSON.stringify(couponsInSession));
                                }
                                else if (couponsInSession.coupon_content == couponsNew.coupon_content
                                    && (!couponsNew.errorString || couponsNew.errorString == ''))
                                { // old coupon code alrady applied early
                                    this.couponSuccessStr = '';
                                    this.couponErrorStr = '';
                                }
                                else if (couponsInSession.coupon_content == couponsNew.coupon_content
                                        && couponsNew.errorString
                                        && couponsNew.errorString != '')
                                { // old coupon code alrady applied early but with error now
                                    this.couponSuccessStr = '';
                                    this.couponErrorStr = couponsNew.errorString;
                                    sessionStorage.setItem('coupons', 'null');
                                }
                                // this.getCouponErrorStr();
                                this.getCouponCode();

                            });
                        }
                    }
                }
            );
        }
        return;








        this.errorCodeNotValid = false;
        this.errorCodeIsEmpty = false;
        this.errorCouponNoItemsInCart = false;
        this.errorCouponsAlreadyInCart = false;
        this.errorRedeemCountInvalid = false;

        // check if coupon items in cart or single coupon in cart
        if (!(this.cart_items.length > 0)) {
            this.couponsInCart = false;
            this.singleCoupon = false;
        }
        // check if not on init (eg page reload) and if something has changed
        for (const cartItem of this.cart_items) {
            if (cartItem.article.is_coupon_product) {
                this.couponsInCart = true;
                this.couponCounter[cartItem.article.id] = cartItem.amount;
                if (typeof cartItem.article.is_only_coupon_in_cart !== 'undefined' && cartItem.article.is_only_coupon_in_cart) {
                    this.singleCoupon = true;
                } else {
                    this.singleCoupon = false;
                }
            } else {
                this.couponsInCart = false;
                this.couponCounter[cartItem.article.id] = cartItem.amount;
                this.singleCoupon = false;
            }
            // i++;
        }

        if (code !== '') {
            this.frontend_service.checkCouponCode(code).subscribe((data) => {
                    this.couponData = data;
                },
                (err) => console.error(err),
                () => {
                    if (this.couponData && this.couponData.is_active) {
                        // check if current coupon should be only coupon in cart and coupon is already in cart
                        if (this.couponData.min_order_value_on_pickup >= this.priceTotal && this.couponData.min_order_value_on_pickup > 0) {
                            this.errorMinorderValue = true;
                        } else {
                            if (this.singleCoupon === false) {
                                if (this.couponData.only_coupon_in_cart && this.couponsInCart) {
                                    this.errorCouponsAlreadyInCart = true;
                                } else {

                                    if (this.coupons.checkRedeemCount(this.couponData)) {
                                        this.addCouponToCart();
                                        this.coupons.setCoupon(this.couponData);
                                    } else {
                                        this.errorRedeemCountInvalid = true;
                                    }
                                }
                            }
                        }
                    } else {
                        this.errorCodeNotValid = true;
                    }
                }
            );
        } else {
            this.errorCodeIsEmpty = true;
        }
    }

    /**
     * TODO: Function Description
     */
    addCouponToCart() {
        return;
        // coupon is of type product
        const couponsInSession = JSON.parse(sessionStorage.getItem('coupons')) || [];
        couponsInSession.push(this.couponData);
        sessionStorage.setItem('coupons', JSON.stringify(couponsInSession));

        if (this.couponData.coupon_type === 1) {
            if (this.couponData.product.expandable) {
                this.router.navigate(['shop/bestellen', 10, this.couponData.product.category.replace(/ /g, '-'),
                    this.couponData.product.id, this.couponData.product.name.replace(/ /g, '-'), this.couponData.product.price[0].size.replace(/ /g, '_')]);
            } else {
                // add coupon product to cart
                if (this.couponData.only_coupon_in_cart === 1) {
                    this.singleCoupon = true;
                }
                // write this on the fly
                this.couponData.product.is_only_coupon_in_cart = this.couponData.only_coupon_in_cart;
                const couponPrices = new Prices(
                    this.couponData.product.price[0].price,
                    this.couponData.product.price[0].happy_hour_price,
                    this.couponData.product.price[0].lunch_price,
                    this.couponData.product.price[0].price - this.couponData.product.price[0].pickup_saving,
                    0,
                    this.couponData.product.price[0].mega_deal_price,
                    0);
                const article_group: ArticleGroup = new ArticleGroup(this.couponData.product, couponPrices, 0, this.couponData.product.category_id, []);
                this.cart_service.addNewSingleArticleGroup(article_group);
                this.couponForm.get('couponCode').reset();
                this.couponsInCart = true;
            }
            // coupon is of type fixed
        } else if (this.couponData.coupon_type === 2) {
            if (this.cart_items.length > 0 && !this.couponsInCart) {
                if (this.couponData.coupon_value_fixed < this.priceCart) {
                    this.priceCart = this.priceCart - this.couponData.coupon_value_fixed;
                    this.cart_service.recalculatePrices();
                    this.updateOnlineBonus();
                    this.couponsInCart = true;
                }
            } else {
                this.errorCouponNoItemsInCart = true;
            }
            // coupon is of type
        } else if (this.couponData.coupon_type === 3) {
            if (this.cart_items.length > 0 && !this.couponsInCart) {
                const priceMinusPercent = parseFloat(((this.couponData.coupon_value_percent / 100) * this.priceCart).toFixed(2));
                this.priceCart = priceMinusPercent;
                this.cart_service.recalculatePrices();
                this.updateOnlineBonus();
                this.couponsInCart = true;
            } else {
                this.errorCouponNoItemsInCart = true;
            }
        }
    }

    /**
     * TODO: Function Description
     */
    resetErrors() {
        this.errorCouponNoItemsInCart = false;
        this.errorCodeNotValid = false;
        this.errorCodeIsEmpty = false;
        this.errorCodeNotValid = false;
        this.errorCouponsAlreadyInCart = false;
    }

    /**
     * TODO: Function Description
     */
    resetUserData() {
        this.user.adress.etage = '';
        this.user.adress.firma = '';
        this.user.adress.comment = '';
        this.showOrderDouble = false;
        this.confirmDoubleOrder = false;
        sessionStorage.setItem('confirm_double_order', '0');
        sessionStorage.setItem('payment_type', '');
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));
    }


    /**
     * Update the session storage user object
     */
    updateUserData() {
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));
        this.doubleOrder = sessionStorage.getItem('order_doubled');
        if (this.doubleOrder == '1' && !this.confirmDoubleOrder) {
            this.showOrderDouble = true;
            return;
        }
        this.complete();
    }

    complete() {
        this.stepper.next();
        // this.stepper.selectedIndex = 1;
        if (this.stepper._getStepLabelId(2)) {
            this.setOpen();
        }
    }

    Step2IsValid = () => {
        // return true;
        if ((typeof this.adress_form !== 'undefined' && !this.adress_form.form.valid) || (!this.searchPlzFormControl.valid || !this.searchStreetFormControl.valid) || (this.addMinQuantitySurcharge == false && !this.on_pickup)) {
            return false;
        } else {
            return true;
        }
    }

    onLog() {

    }

    onAdd(index: number) {
        this.cart_service.increaseAmountDoubled(index);
        // if (this.tipFormGroup.get('tipFixedPercentage').value) {
        //     this.cart_service.calcTipPercentage(this.tipFormGroup.get('tipFixedPercentage').value);
        // }
        // this.cart_service.recalculatePrices();
        this.updateOnlineBonus();
        // this.coupons.checkCouponValidity();

        /*26 August*/
        /*this.scrollTop();*/
    }

    onSub(index: number) {
        this.cart_service.decreseAmount(index);
        this.couponErrorStr = '';
        // if (this.tipFormGroup.get('tipFixedPercentage').value) {
        //     this.cart_service.calcTipPercentage(this.tipFormGroup.get('tipFixedPercentage').value);
        // }
        // this.cart_service.recalculatePrices();
        this.updateOnlineBonus();
        // check coupons
        // this.checkIfCouponInCart();
        // this.checkIfSingleCouponInCart();

        // if (!(this.cart_items.length > 0)) {
        //     this.couponsInCart = false;
        //     this.singleCoupon = false;
        // }
        // this.coupons.checkCouponValidity();

        if (!(this.cart_items.length == 0)) {
            this.scrollTop();
        }
    }

    /**
     * TODO: Function Description
     */
    onEdit(index: number) {
        this.cart_service.setEditIndex(index);


        if (this.cart_items[index].article['valid_to'] != undefined) {
            // aktionsartikel
            let article = this.cart_items[index].article;
            this.router.navigate(['shop/bestellen', 'aktion',
                article.id, article.name.replace(/ /g, '-'), article.price[this.cart_items[index]['size_index']].size.replace(/ /g, '_')]);
        } else {
            // regulärer Artikel
            let article = this.cart_items[index].article;
            this.router.navigate(['shop/bestellen', article.category_id, article.category.replace(/ /g, '-'),
                article.id, article.name.replace(/ /g, '-'), article.price[this.cart_items[index]['size_index']].size.replace(/ /g, '_')]);
        }
    }


    onBlurTip(event: any) {

        if (event.target.value === '') {
            sessionStorage.setItem('tip', '0');
            sessionStorage.setItem('tipFixedPercentage', '0');
            this.cart_service.recalculatePrices();

            // this.cart_service.setTip(0);
            // this.cart_service.loadCartSessionStorage();
        }
    }

    /**
     * TODO:  change tip in cart page
     */
    onChangeTip(event: any) {
        if (isNaN(Math.abs(parseFloat(event.target.value)))) {
            this.tipFormGroup.get('tipValue').reset();
            this.tipNotValid = true;
            sessionStorage.setItem('tip', '0');
        } else {
            const tip_string = event.target.value.replace(',', '.');
            const tip_string_split = tip_string.split('.');
            const tip_string_concat = tip_string_split[0] + '.' + ((tip_string_split.length === 2) ? tip_string_split[1].substring(0, 2) : '0');
            this.tipFormGroup.get('tipValue').setValue(tip_string_concat.replace('.', ','));
            this.tipNotValid = false;
            sessionStorage.setItem('tip', tip_string_concat);
        }
        sessionStorage.setItem('tipFixedPercentage', '0');
        this.cart_service.recalculatePrices();

        // if (isNaN(Math.abs(parseFloat(event.target.value)))) {
        //     this.tipFormGroup.get('tipValue').reset();
        //     this.cart_service.setTip(0);
        //     this.tipNotValid = true;
        // } else {

        //     const tip_string = event.target.value.replace(',', '.');
        //     const tip_string_split = tip_string.split('.');
        //     const tip_string_concat = tip_string_split[0] + '.' + ((tip_string_split.length === 2) ? tip_string_split[1].substring(0, 2) : '0');
        //     const tip = Math.abs(parseFloat(tip_string_concat));
        //     this.tipFormGroup.get('tipValue').setValue(tip_string_concat.replace('.', ','));

        //     this.cart_service.setTip(tip);


        //     this.tipNotValid = false;
        // }
        // this.cart_service.recalculatePrices();
        // this.updateOnlineBonus();
        // sessionStorage.setItem('tipFixedPercentage', '0');
        // this.cart_service.loadCartSessionStorage();
    }

    onCheckTip(event: any) {


    }

    changeTipPercentage(percentage: any) {
        if (parseFloat(percentage) !== 0) {
            sessionStorage.setItem('tip', '0');
            sessionStorage.setItem('tipFixedPercentage', percentage);
            this.cart_service.recalculatePrices();
        } else {
            this.tipFormGroup.get('tipValue').enable();
            sessionStorage.setItem('tip', '0');
            sessionStorage.setItem('tipFixedPercentage', '0');
            this.tipFormGroup.get('tipValue').setValue('');

            jQuery('.tip-input input').focus();
            jQuery('.tip-input input').focus(function () {
                jQuery(this).select();
            });
        }



        /* tip has fixed percent value: else get user input value */
        // if (parseFloat(percentage) !== 0) {
        //     sessionStorage.setItem('tipFixedPercentage', this.tipFormGroup.get('tipFixedPercentage').value);
        //     this.cart_service.calcTipPercentage(percentage);
        //     this.tipFormGroup.get('tipValue').disable();
        // } else {

        //     this.tipFormGroup.get('tipValue').enable();
        //     this.cart_service.setTip(0);
        //     this.tipFormGroup.get('tipValue').setValue('');

        //     this.tip = '';

        //     jQuery('.tip-input input').focus();
        //     jQuery('.tip-input input').focus(function () {
        //         jQuery(this).select();
        //     });

        //     sessionStorage.setItem('tipFixedPercentage', '0');


        // }
        // this.cart_service.loadCartSessionStorage();
    }

    changeEmail() {

        this.user.mail = this.user.mail.trim();
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));
    }

    // build a data-object and send it to the server
    onSubmit() {

        if (this.cart_items.length < 1) {
         return false;
        }

        if (this.order_submitted === true) {
            return false;
        }

        clearInterval(this.checkRelocateDeliveryTimeInterval);

        this.disableButtonBuy = true;
        this.order_submitted = true;
        this.placeholder = true;
        let data: any;
        let deliveryDateSQLValid: string;
        // const price = this.on_pickup ? this.final_price_pickup : this.totalSum;
        const later = this.send_later ? this.later_on_happy_hour : this.on_happy_hour;

        // Date conversion for SQL
        const year = this.min_date.getFullYear();
        const month = this.min_date.getMonth() + 1;
        const day = this.min_date.getDate();
        const date_today = year + '-' + month + '-' + day;

        if (typeof this.deliveryDate === 'object' && this.deliveryDate !== null) {
            deliveryDateSQLValid = this.deliveryDate.getFullYear() + '-' + (this.deliveryDate.getMonth() + 1) + '-' + this.deliveryDate.getDate();
        }


        // check cookie validation
        if (this.b_cookie) {
            this.user_service.saveUserToCookie();
        }
        // create user account on confirmation
        if (this.b_create_account) {
            this.auth_service.signupUser(this.user.mail, this.user_password, this.user.title, this.user.fir_name, this.user.fam_name,
                this.user.adress.plz, this.user.adress.city, this.user.adress.district, this.user.adress.street, this.user.adress.street_number, this.user.adress.etage, this.user.phone);
        }

        let coupPers = '';
        let coupVal = '';
        let coupName = '';
        if (this.couponsArr && this.couponsArr.coupon_content) {
            coupPers = this.couponsArr.coupon_value_percent;
            coupVal = this.couponsArr.coupon_value_fixed;
            coupName = this.couponsArr.coupon_content;
        }

        // write order data object for post to server
        if (sessionStorage.getItem('Schnell') && sessionStorage.getItem('Schnell') == '1') {
            this.comment = this.comment + '    Achtung! ' + sessionStorage.getItem('Schnell_text') + '!!!';
        }
        data = ({
            'sum': this.priceTotal - this.onlineBonus,
            'pickup': this.on_pickup,
            'happy_hour': later,
            'send_later': this.send_later,
            'transaction_id': this.transaction_id,
            // TODO: fetch delivery date for send now and send later
            // correct time format: $datetime = date('Y-m-d H:i:s'); // 2018-10-19 21:18:39
            'deliver_date': this.deliveryDate === undefined ? date_today : deliveryDateSQLValid,
            'deliver_time': this.deliveryTime === undefined ? this.current_time : this.deliveryTime,
            'deliver_datetime': (this.deliveryDate === undefined ? date_today : deliveryDateSQLValid) + ' ' + this.deliveryTime,
            'add_info': {
                'comment': this.comment,
                'discount_percent': coupPers,
                'discount_name': coupName,
                'discount_val': coupVal,
                'payment_type': this.payment_type,
                'tip': this.tip,
                'min_quan_sur': this.min_quan_sur,
                'min_order_value': this.min_val
            },
            'newsletter': this.no_marketing === false ? 0 : 1,
            // Stored in order_json
            'article': this.cart_items,
            'mollieHash': this.mollieHash,
            'base_sum': this.priceCart,
            'user': this.user,
            'modifications': this.priceModifications,
            'debug': {
                'onLunch': this.onLunch,
                'localDate': new Date().getHours() + ':' + new Date().getMinutes(),
                'date': this.dateService.newDate().getHours() + ':' + this.dateService.newDate().getMinutes(),
            }

        });

        this.frontend_service.onSendData(data);

        this.frontend_service.getOrderSuccessful().subscribe(
            response => {
                if (response.status == true || response.status == 'Saving successful') {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.cart_items = [];
                    this.resetUserData();
                    this.cart_service.resetCartData();
                    clearInterval(this.checkRelocateDeliveryTimeInterval);
                    window.scrollTo(0, 0);

                } else {
                    this.mollieinprocess = false;
                    this.show_dialog = true;
                    this.payment_error = true;
                    window.scrollTo(0, 0);
                    this.checkRelocateDeliveryTimeInterval = setInterval(data => {
                        this.cart_service.checkRelocateDeliveryTime();
                    }, 60000);
                }
                this.order_submitted = false;
            }
        );
        this.placeholder = false;
    }

    onAddMinQuantitySurcharge() {
        this.addMinQuantitySurcharge = !this.addMinQuantitySurcharge;
        this.cart_service.setAddMinQuantitySurcharge(this.addMinQuantitySurcharge);
        this.cart_service.recalculatePrices();
        this.updateOnlineBonus();
    }

    private initPrices() {
        this.price_for_min_val = 0;

        if (this.cart_items.length > 0) {
            for (let i = 0; i < this.cart_items.length; i++) {
                const item = this.cart_items[i];
                this.prices_regular[i] = item.prices.regular_price * 1 + item.prices.price_for_added_ingredients;
                this.prices_pickup[i] = item.prices.pickup_price * 1 + item.prices.price_for_added_ingredients;

                // this.prices_regular_with_added_free_ingredients[i] =

                if (item.prices) {
                    if (item.prices.happy_hour_price) {
                        this.prices_happy[i] = item.prices.happy_hour_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    if (item.prices.lunch_price) {
                        this.prices_lunch[i] = item.prices.lunch_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    // init prices for mega deal (10 or more pizzas)
                    if (item.prices.mega_deal_price) {
                        this.prices_mega_deal[i] = item.prices.mega_deal_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    if (item.for_min_val == 1) {
                        this.price_for_min_val = this.price_for_min_val + (item.prices.regular_price * 1 + item.prices.price_for_added_ingredients) * item.amount;
                        this.price_for_min_val_happy = this.price_for_min_val_happy + (item.prices.happy_hour_price * 1 + item.prices.price_for_added_ingredients) * item.amount;
                    }
                    if (item.prices.happy_hour_price) {
                        this.prices_happy[i] = item.prices.happy_hour_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    if (item.prices.lunch_price) {
                        this.prices_lunch[i] = item.prices.lunch_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    // init prices for mega deal (10 or more pizzas)
                    if (item.prices.mega_deal_price) {
                        this.prices_mega_deal[i] = item.prices.mega_deal_price * 1 + item.prices.price_for_added_ingredients;
                    }

                    if (item.prices.price_for_added_free_addables) {
                        this.prices_regular_plus_free_addables[i] = item.prices.regular_price * 1 + item.prices.price_for_added_free_addables + item.prices.price_for_added_ingredients;
                    }

                    if (item.prices.price_for_added_free_addables) {
                        this.prices_pickup_plus_free_addables[i] = item.prices.pickup_price * 1 + item.prices.price_for_added_free_addables + item.prices.price_for_added_ingredients;
                    }
                }
            }
        }

        // this.min_quan_sur = this.min_val - this.priceCart < 0 ? 0 : this.min_val - this.priceCart;
        // this.cart_service.recalculatePrices();
        this.updateOnlineBonus();

    }

    createConcardisOrder() {

        return this.http.post(this.main_service.adress_url + '/PayEngineCreateOrder', { amount: this.priceTotal - this.onlineBonus });


    }

    InitConcardisPayment() {

return;
        const optionalParameters = {
            'language': 'de',
            'colorTheme': 'light',
            'deviceIdent': null,
            'redirectUser': false,
            'mobileView': true,
            'transactionType': 'DEBIT',
            'paymentDetails': {
                'creditCard': {
                    'forceChallenge': true
                }
            },
            'optionalFields': {
                'creditCard': {
                    'cardHolder': true,
                    'cvcNeeded': true,
                    'cofContract': {
                        'type': 'ONECLICK',
                        'recurringExpiry': '2020-08-01',
                        'recurringFrequency': 30
                    }
                },
            },
            'edcc': {}


        };

        const that = this;


        this.createConcardisOrder().subscribe((data: any) => {

            this.paymentsService.load().then(payments => {
                PayEngineWidget.initAsModal(
                    payments.concardis.settings.merchantId,
                    data.orderId,
                    optionalParameters,
                    function (error, results) {
                        if (error === null && results !== null) {
                            that.transaction_id = data.orderId;
                            that.onSubmit();
                        }
                    }
                );
            });


        });


    }

    getPlzData(value) {
        if (typeof value !== 'undefined' && value && value.length >= 2) {
            let force = '';
            if (this.on_pickup) { force = 'force=1&'; }
            return this.http.get(this.main_service.adress_url + '/get-address-data?' + force + 'field=plz&plz=' + value + '&street=' + '' + '&district=')
                .pipe(
                    finalize(() => {
                        this.isLoadingPlz = false;
                    }),
                );

        } else {
            this.isLoadingPlz = false;
            return [];
        }
    }

    /**
     * Zip calculation
     */
    autocompletePlz() {
        this.searchPlzFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.filteredPlz = [];
                    this.isLoadingPlz = true;
                    this.plzMessage = '';
                }),
                switchMap(value => this.getPlzData(value)
                )
            )
            .subscribe(data => {
                if (data === undefined || (Array.isArray(data) && data.length == 0)) {
                    this.filteredPlz = [];
                    if (!this.on_pickup) {
                        this.plzMessage = 'Die PLZ liegt nicht im Liefergebiet';
                    } else {
                        this.plzMessage = 'PLZ nicht gefunden';
                    }
                } else {
                    this.filteredPlz = data;
                }

            });
    }

    displayFnPlz(address): string {
        if (address) {
            return address.plz;
        } else {
            return '';
        }

    }

    plzClick(event: any) {
        this.user.adress.plz = event.option.value.plz;
        this.user.adress.city = event.option.value.city;
        this.user.adress.district = event.option.value.district;
        this.plzDataObject = event.option.value;
        this.searchPlzFormControl.setValue(event.option.value);

        this.user.adress.street = '';
        this.user.adress.street_number = '';
        this.searchStreetFormControl.setValue(null);
        if (!this.on_pickup) {
            if (typeof this.user.adress.plz != 'undefined' && this.user.adress.plz && typeof this.user.adress.district != 'undefined' && this.user.adress.district) {
                this.frontend_service.getMinVal(this.user.adress.plz, this.user.adress.district).subscribe(
                    (data: string) => {
                        this.addMinQuantitySurcharge = false;
                        this.cart_service.setAddMinQuantitySurcharge(this.addMinQuantitySurcharge);
                        this.cart_service.setMinVal(data);
                    });
            } else {
                this.resetUserData();
            }
        } else {
            this.cart_service.setMinVal('0');
        }
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));
    }


    displayFnStreet(address): string {
        if (address) {
            return address.street;
        } else {
            return '';
        }

    }

    // on select autocomplete value
    streetClick(event: any) {

        if (event.option.value) {
            this.user.adress.street = event.option.value.street;

            this.user.adress.plz = event.option.value.plz;
            this.user.adress.city = event.option.value.city;
            this.user.adress.district = event.option.value.district;
            this.plzDataObject = event.option.value;
            this.searchPlzFormControl.setValue(event.option.value);
            if (!this.on_pickup) {
                if (typeof this.user.adress.plz != 'undefined' && this.user.adress.plz && typeof this.user.adress.district != 'undefined' && this.user.adress.district) {
                    this.frontend_service.getMinVal(this.user.adress.plz, this.user.adress.district).subscribe(
                        (data: string) => {
                            this.addMinQuantitySurcharge = false;
                            this.cart_service.setAddMinQuantitySurcharge(this.addMinQuantitySurcharge);
                            this.cart_service.setMinVal(data);
                        });
                } else {
                    this.resetUserData();
                }
            } else {
                this.cart_service.setMinVal('0');
            }
            sessionStorage.setItem('tempUser', JSON.stringify(this.user));
            this.addressId = event.option.value.id;
            sessionStorage.setItem('user_address_id', this.addressId);
            this.cart_service.recalculatePrices();
            this.searchStreetFormControl.setValue(event.option.value);
        }

    }

    plzBlur() {

        if (typeof this.searchPlzFormControl.value === 'string') {
            this.searchPlzFormControl.setValue(this.plzDataObject);
        }


    }

    changePlz() {


    }

    // validate on blurf
    checkStreet() {

    }

    // validate on blur
    checkStreetNumber() {

    }

    getStreetData(value) {
        if (/* this.plzDataObject && */ typeof value !== 'undefined' && value && value.length >= 1) {

            let force = '';
            let plz = '';
            if (typeof this.user.adress.plz !== 'undefined') {
                plz = this.user.adress.plz;
            }
            if (this.on_pickup) { force = 'force=1&'; }
            return this.http.get(this.main_service.adress_url + '/get-address-data?' + force + 'field=street&plz=' + plz + '&street=' + value + '&district=' + this.user.adress.district)
                .pipe(
                    finalize(() => {
                        this.isLoadingStreet = false;
                    }),
                );
        } else {
            this.isLoadingStreet = false;
            return [];
        }
    }

    autocompleteStreet() {

        this.searchStreetFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {

                    if (typeof this.searchStreetFormControl.value === 'string') {
                        this.user.adress.street = this.searchStreetFormControl.value;
                    }


                    this.filteredStreet = [];
                    this.isLoadingStreet = true;
                }),
                switchMap(value => this.getStreetData(value)
                )
            )
            .subscribe(data => {
                if (data === undefined) {
                    this.filteredStreet = [];

                } else {
                    this.filteredStreet = data;
                }

            });
    }


    getAnmeldung(): void {
        this.sessionService.getAnmeldung().subscribe(
            objTmp => this.updateAnmeldung(objTmp)
        )
    }

    updateAnmeldung(obj: Anmeldung) {
        this.anmeldung = obj;
    }

    updateOnlineBonus() {

        // Disabled. Moved on backend
        this.onlineBonus = 0;
        return;

        if (this.storeData.onlinebonusmittags != 1 && this.onLunch) {
            this.onlineBonus = 0;
            return;
        }

        if (this.on_pickup) {
            var onlineBonusLoc = this.storeData.onlinebonus;
        } else {
            var onlineBonusLoc = this.storeData.onlinebonusdeliv;
        }

        if (onlineBonusLoc == 0) {
            this.onlineBonus = 0;
            return;
        }

        if (onlineBonusLoc == '') {
            this.onlineBonus = 0;
            return;
        }

        if (this.storeData.onlinebonustype == 'pers') {
            this.onlineBonus = parseFloat((this.priceCart * (onlineBonusLoc / 100)).toFixed(2));
        } else {
            if (onlineBonusLoc >= this.priceTotal) {
                this.onlineBonus = 0;
                return;
            }
            if (this.storeData.onlinebonusminsumm >= this.priceTotal) {
                this.onlineBonus = 0;
                return;
            }
            this.onlineBonus = onlineBonusLoc;
        }

    }

    getCouponErrorStr() {
        if (this.couponsArr && this.couponsArr.errorString && this.couponsArr.errorString != '') {
            this.couponErrorStr = this.couponsArr.errorString;
        } else {
            this.couponErrorStr = '';
        }
    }

    getCouponCode() {
        if (this.couponsArr && this.couponsArr.coupon_content) {
            this.couponCodeNum = this.couponsArr.coupon_content;
        } else {
            this.couponCodeNum = '';
        }
    }

    redirectToActionProduct (code = '', onlyCoupon = 0) {
        if (this.couponData.product.expandable) {
            if (code) {
                this.router.navigate(['shop/bestellen', 'coupon', this.couponData.product.category.replace(/ /g, '-'),
                    this.couponData.product.id, this.couponData.product.name.replace(/ /g, '-'), this.couponData.product.price[0].size.replace(/ /g, '_')],
                    { queryParams: { pc: code, onlyCoupon: onlyCoupon} }
                );
            } else {
                this.router.navigate(['shop/bestellen', 'coupon', this.couponData.product.category.replace(/ /g, '-'),
                    this.couponData.product.id, this.couponData.product.name.replace(/ /g, '-'), this.couponData.product.price[0].size.replace(/ /g, '_')]
                );
            }

        } else {
            // add coupon product to cart
            // if (this.couponData.only_coupon_in_cart === 1) {
            //     this.singleCoupon = true;
            // }
            // write this on the fly
            // this.couponData.product.is_only_coupon_in_cart = this.couponData.only_coupon_in_cart;
            const couponPrices = new Prices(
                this.couponData.product.price[0].price,
                this.couponData.product.price[0].happy_hour_price,
                this.couponData.product.price[0].lunch_price,
                this.couponData.product.price[0].price - this.couponData.product.price[0].pickup_saving,
                0,
                this.couponData.product.price[0].mega_deal_price,
                0);
            const article_group: ArticleGroup = new ArticleGroup(this.couponData.product, couponPrices, 0, this.couponData.product.category_id, [], code);
            this.cart_service.addNewSingleArticleGroup(article_group);
            this.couponForm.get('couponCode').reset();
            // this.couponsInCart = true;
        }
    }

    getCheckout() {
        let loadedCartItems = [];
        if (sessionStorage.getItem('cartItems') != 'undefined' && sessionStorage.getItem('cartItems') != null && sessionStorage.getItem('cartItems') != 'null') {
            loadedCartItems = JSON.parse(sessionStorage.getItem('cartItems'));
        }
        let couponsInSession: any;
        couponsInSession = [];
        if (sessionStorage.getItem('coupons') != 'undefined' && sessionStorage.getItem('coupons') != null && sessionStorage.getItem('coupons') != 'null') {
            couponsInSession = JSON.parse(sessionStorage.getItem('coupons'));
        }
        const tp = sessionStorage.getItem('tip');
        const tpr = sessionStorage.getItem('tipFixedPercentage');

        const later = this.send_later ? this.later_on_happy_hour : this.on_happy_hour;
        let coupPers = '';
        let coupVal = '';
        let coupName = '';
        if (this.couponsArr && this.couponsArr.coupon_content) {
            coupPers = this.couponsArr.coupon_value_percent;
            coupVal = this.couponsArr.coupon_value_fixed;
            coupName = this.couponsArr.coupon_content;
        }
        if (sessionStorage.getItem('Schnell') && sessionStorage.getItem('Schnell') == '1') {
            this.comment = this.comment + '    Achtung! ' + sessionStorage.getItem('Schnell_text') + '!!!';
        }
        const data = ({
            'sum': this.priceTotal,
            'pickup': this.on_pickup,
            'happy_hour': later,
            'send_later': this.send_later,
            'transaction_id': this.transaction_id,
            // TODO: fetch delivery date for send now and send later
            // correct time format: $datetime = date('Y-m-d H:i:s'); // 2018-10-19 21:18:39
            'deliver_date': this.deliveryDate.getFullYear() + '-' + (this.deliveryDate.getMonth() + 1) + '-' + this.deliveryDate.getDate(),
            'deliver_time': this.deliveryTime === undefined ? this.current_time : this.deliveryTime,
            'deliver_datetime': this.deliveryDate.getFullYear() + '-' + (this.deliveryDate.getMonth() + 1) + '-' + this.deliveryDate.getDate() + ' ' + this.deliveryTime,
            'add_info': {
                'comment': this.comment,
                'discount_percent': coupPers,
                'discount_name': coupName,
                'discount_val': coupVal,
                'payment_type': this.payment_type,
                'tip': this.tip,
                'min_quan_sur': this.min_quan_sur,
                'min_order_value': this.min_val
            },
            'newsletter': this.no_marketing === false ? 0 : 1,
            // Stored in order_json
            'article': loadedCartItems,
            'mollieHash': this.mollieHash,
            'user': this.user,
            'base_sum': this.priceCart,
            'modifications': this.priceModifications,
            'debug': {
                'onLunch': this.onLunch,
                'localDate': new Date().getHours() + ':' + new Date().getMinutes(),
                'date': this.dateService.newDate().getHours() + ':' + this.dateService.newDate().getMinutes(),
            }

        });

        return data;


        // const checkout = ({
        //     'article':loadedCartItems,
        //     'deliveryType': this.on_pickup ? 'pickup' : 'delivery',
        //     'deliveryDate': this.deliveryDate,
        //     'tip': (tp == 'NaN' || tp == 'null' || tp == 'undefined') ? '0' : tp,
        //     'tipPercentage': (tpr == 'NaN' || tpr == 'null' || tpr == 'undefined') ? '0' : tpr,
        //     'minValAdd': this.min_quan_sur,
        //     'minVal': this.min_val,
        //     'minValIsAdded': this.addMinQuantitySurcharge,
        //     'couponCode': couponsInSession});
        // return checkout;
    }

    getPaymentUrl() {
        if (sessionStorage.getItem('paymentUrl')) {
            return sessionStorage.getItem('paymentUrl');
        }
        return '';
    }

    hidePriceDiff() {
        this.showPriceDiffPopup = false;
    }

    hideDobleOrderPopup(type) {
        this.showOrderDouble = false;
        if (type) {
            sessionStorage.setItem('confirm_double_order', '1');
            this.confirmDoubleOrder = true;
            sessionStorage.setItem('tempUser', JSON.stringify(this.user));
            this.complete();
            // this.updateUserData();
        }
    }

    checkDeliveryArea () {
        if (this.on_pickup) { return; }
        if (!this.user.adress.plz) {
            this.user_service.clearAdressCurrentUser();
            this.user_service.clearAdressCurrentUser();
            this.plzDataObject = null;
            this.searchPlzFormControl.setValue(null);
            this.searchStreetFormControl.setValue(null);
            this.cart_service.setMinVal('0');
            return;
        }
        this.http.get(this.main_service.adress_url + '/get-address-data?field=plz&plz=' + this.user.adress.plz + '&street=' + '' + '&district=')
                .pipe(
                    finalize(() => {
                    }),
                ).subscribe(data => {
                    if (!data || Object.keys(data).length == 0) {
                        this.user_service.clearAdressCurrentUser();
                        this.plzDataObject = null;
                        this.searchPlzFormControl.setValue(null);
                        this.searchStreetFormControl.setValue(null);
                        this.cart_service.setMinVal('0');
                        // this.user.adress.plz = '';
                        // this.user.adress.street = '';
                        // this.user.adress.city = '';
                        // this.user.adress.district = ''
                        // this.user.adress.street_number = '';
                    } else {
                        if (typeof this.user.adress.plz != 'undefined' && this.user.adress.plz && typeof this.user.adress.district != 'undefined' && this.user.adress.district) {
                            this.frontend_service.getMinVal(this.user.adress.plz, this.user.adress.district).subscribe(
                                (data: string) => {
                                    this.cart_service.setMinVal(data);
                                });
                        } else {
                            this.resetUserData();
                        }
                    }

                });
    }
}
