/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "../../elements/slider/slider.component.ngfactory";
import * as i5 from "../../elements/slider/slider.component";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../services/main.service";
import * as i8 from "../../elements/categories/categories.component.ngfactory";
import * as i9 from "../../elements/categories/categories.component";
import * as i10 from "@angular/common";
import * as i11 from "./dashboard.component";
import * as i12 from "../../../services/frontend.service";
import * as i13 from "../../../services/cart.service";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "text-center color-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svg-icon", [["src", "/assets/icons/icon-loading.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService], { src: [0, "src"], svgStyle: [1, "svgStyle"] }, null), i1.ɵpod(4, { "fill": 0 })], function (_ck, _v) { var currVal_0 = "/assets/icons/icon-loading.svg"; var currVal_1 = _ck(_v, 4, 0, "currentColor"); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container-full home-content-wrapper"], ["id", "home-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-element-slider", [["element", "sliderDashboard"]], null, null, null, i4.View_SliderComponent_0, i4.RenderType_SliderComponent)), i1.ɵdid(3, 8503296, null, 0, i5.SliderComponent, [i6.HttpClient, i7.MainService], { element: [0, "element"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-element-categories", [["element", "categoriesDashboard"]], null, null, null, i8.View_CategoriesComponent_0, i8.RenderType_CategoriesComponent)), i1.ɵdid(5, 114688, null, 0, i9.CategoriesComponent, [i6.HttpClient, i7.MainService], { element: [0, "element"] }, null)], function (_ck, _v) { var currVal_0 = "sliderDashboard"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "categoriesDashboard"; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { carouselNext: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.ready; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.ready; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 12828672, null, 0, i11.DashboardComponent, [i12.FrontendService, i7.MainService, i13.CartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i11.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
