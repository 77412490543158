<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12">
            <div class="header-wrapper-small">
                <h1 class="static-page-title">AGB</h1>
            </div>
        </div>

    </div>

    <ng-container>
        <div class="row">

            <div class="col-md-12">

                <div class="center-content">
                    <h2 class="text-center">Allgemeine Geschäftsbedingungen der Meisterpizza Inh. Markus Hoffmann </h2>

                    <ul>
                        <li>
                            <h3>§ 1 Geltungsbereich</h3>
                            <ol>
                                <li>Diese Allgemeinen Geschäftsbedingungen (nachfolgend AGB) gelten für alle zwischen
                                    der Meisterpizza,
                                    Inh. Markus Hoffmann (im Folgenden Meisterpizza) und dem Kunden geschlossenen
                                    Verträgen. Mit
                                    Vertragsschluss erkennt der Kunde diese AGB verbindlich an, unabhängig davon, ob
                                    dieser sie
                                    tatsächlich zur Kenntnis nimmt. Die AGB haben Vorrang vor etwaigen
                                    Geschäftsbedingungen des Kunden.
                                    Entgegenstehende Geschäftsbedingungen des Kunden werden auch ohne ausdrücklichen
                                    Widerspruch nicht
                                    Vertragsbestandteil, es sei denn, die Meisterpizza stimmt diesen im Einzelfall
                                    schriftlich zu.
                                </li>
                                <li>Die Regelungen gelten unabhängig davon, ob es sich bei dem Kunden um einen
                                    Verbraucher nach § 13 BGB
                                    oder einen Unternehmer nach § 14 BGB handelt.
                                </li>
                                <li>Es gelten die allgemeinen Geschäftsbedingungen in der im Zeitpunkt des
                                    Vertragsschlusses gültigen
                                    Fassung.
                                </li>
                                <li>Ergänzungen oder Änderungen dieser AGB bedürfen zu ihrer Rechtswirksamkeit der
                                    Schriftform nach §
                                    126b BGB.
                                </li>
                                <li>Der Kunde versichert bei Vertragsschluss uneingeschränkt geschäftsfähig zu sein oder
                                    die
                                    Einwilligung des gesetzlichen Vertreters zu haben.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 2 Vertragsschluss</h3>
                            <ol>
                                <li>Die Angebote der Meisterpizza auf Flyern oder auf Website
                                    <a  href="https://{{ storeData?.domain}}" target="_blank">{{ storeData?.domain}}</a>
                                    (im Folgenden nur Website) stellen kein
                                    verbindliches
                                    Angebot zum Abschluss eines Vertrages dar. Der Kunde wird durch die beworbenen
                                    Produkte wie z.B.
                                    Getränke, Gerichte, Zubehör, Dessert (im Folgenden Produkte) der Meisterpizza
                                    aufgefordert, durch eine
                                    Bestellung ein Angebot auf Abschluss eines Vertrages an die Meisterpizza abzugeben.
                                </li>
                                <li>Der Kunde kann das Angebot zum Abschluss eines Vertrages über die Website,
                                    telefonisch oder
                                    persönlich in der Betriebsstätte der Meisterpizza abgeben.
                                </li>
                                <li>Die Bestellung des Kunden über die sich im Warenkorb befindenden Produkte stellt ein
                                    verbindliches
                                    Angebot dar. Die Annahme des Angebots erfolgt
                                    <ol type="a">
                                        <li>im Fall der Onlinebestellung mit der Versendung der zweiten E-Mail durch die
                                            Meisterpizza;
                                        </li>
                                        <li>im Fall einer telefonischen oder persönlichen Bestellung durch eine
                                            ausdrückliche Annahme des
                                            Angebotes.
                                        </li>
                                        <li>m Fall einer Bestellung über einen Drittanbieter wie z.B. Lieferando, wenn
                                            nach Eingang der
                                            automatischen Bestellbestätigung durch den Drittanbieter nicht binnen von 15
                                            Minuten dem Angebot
                                            des Kunden durch die Meisterpizza widersprochen wird.
                                        </li>
                                    </ol>
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass die Meisterpizza nach Abgabe der Bestellung
                                    über die Website
                                    zwei E-Mails an den Kunden versendet. Bei der ersten E-Mail durch die Meisterpizza
                                    wird der Eingang
                                    der Bestellung lediglich bestätigt und führt noch nicht zu einem Vertragsschluss.
                                    Gleiches gilt für
                                    das Versenden einer automatischen Bestellbestätigung durch einen Drittanbieter wie
                                    z.B. Lieferando.
                                </li>
                                <li>Ein Vertrag über ein Produkt, insbesondere über ein Aktionsangebot ist nur möglich,
                                    sofern sämtliche
                                    Zutaten vorrätig sind. Dauerhaft beworbene Produkte, Aktionsangebote und –waren
                                    können begrenzt sein.
                                    Sofern ein bestelltes Produkt nicht vorrätig ist, besteht kein Anspruch auf
                                    Lieferung oder Beschaffung
                                    des Produktes. Die Meisterpizza wird den Kunden unverzüglich informieren, sofern ein
                                    bestelltes
                                    Produkt nicht mehr vorrätig ist.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass eine Änderung der bestellten Produkte nur
                                    möglich ist,
                                    sofern noch nicht mit der Zubereitung des zu verändernden Produktes begonnen wurde.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 3 Lieferung und Abholung</h3>
                            <ol>
                                <li>Die Meisterpizza wird dem Kunden nach Abgabe der Bestellung einen Zeitrahmen nennen,
                                    in der die
                                    Bestellung geliefert werden soll. Sämtliche Zeitangaben der Meisterpizza sind
                                    unverbindlich, sofern
                                    nicht ein verbindlicher Lieferzeitpunkt ausdrücklich zugesagt wurde.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass auch die Bestellung zu einem bestimmten
                                    Zeitpunkt über die
                                    Website nicht automatisch eine Verbindlichkeit hinsichtlich des Lieferzeitpunktes
                                    begründet. Ist eine
                                    verbindliche Lieferzeit gewünscht, ist dies im Feld „Anmerkungen zur Bestellung“ auf
                                    der Website
                                    ausdrücklich anzugeben. Sollte der verbindliche Terminwunsch nicht eingehalten
                                    werden können, wird die
                                    Meisterpizza den Kunden hierüber unverzüglich informieren, soweit es ihm möglich und
                                    zumutbar ist.
                                </li>
                                <li>Ist der Kunde zum vereinbarten oder voraussichtlichen Liefertermin nicht
                                    anzutreffen, behält sich
                                    die Meisterpizza vor, eine Aufwandsentschädigung zu berechnen, die der Höhe des
                                    Brutto-Bestellwerts
                                    entspricht. Dies gilt insbesondere auch, wenn Türen nicht zu finden sind, die
                                    Klingel defekt ist oder
                                    der Kunde das Klingeln überhört. Die Regelungen dieses Absatzes gelten unabhängig
                                    davon, ob der Kunde
                                    an dem vereinbarten Lieferort anwesend ist oder nicht.
                                </li>
                                <li>Sollte die Lieferzeit aufgrund von Beschädigungen oder Untergang der Ware während
                                    des Transportes
                                    nicht eingehalten werden können, wird die Meisterpizza den Kunden umgehend
                                    informieren, sofern es ihr
                                    möglich und zumutbar ist.
                                </li>
                                <li>Der Kunde wird ausdrücklich darauf hingewiesen, dass Lieferungen nur in dem auf der
                                    Website
                                    genannten Liefergebiet möglich sind. Sollte sich der Kunde außerhalb des
                                    Liefergebietes befinden, hat
                                    er die Ware in der Betriebsstätte der Meisterpizza abzuholen. Der Kunde hat
                                    eigenständig darüber zu
                                    prüfen, ob sein Standort zum Liefergebiet gehört.
                                </li>
                                <li>Die Lieferung erfolgt grundsätzlich durch die Mitarbeiter der Meisterpizza, sofern
                                    über die Website,
                                    telefonisch oder persönlich in der Betriebsstätte bestellt wurde. Bestellt der Kunde
                                    über einen
                                    Drittanbieter wie z.B. Lieferando, kann die Lieferung durch einen Mitarbeiter des
                                    Drittanbieters
                                    erfolgen.
                                </li>
                                <li>Sofern der Kunde die Produkte in der Betriebsstätte der Meisterpizza abholt, wird
                                    die Meisterpizza
                                    ihn informieren, wann die Bestellung zur Abholung bereit ist. Der Kunde wird darauf
                                    hingewiesen, dass
                                    der genannte Zeitpunkt ebenfalls unverbindlich ist, sofern nichts anderes vereinbart
                                    ist.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass der Verzehr in der Betriebsstätte der
                                    Meisterpizza in
                                    {{ storeData?.city }} nicht möglich ist.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 4 Eigentumsvorbehalt</h3>
                            <ol>
                                <li>Die Meisterpizza behält sich das Eigentum an den bestellten Produkten bis zur
                                    vollständigen
                                    Kaufpreiszahlung vor.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 5 Preise, Zahlungsbedingungen</h3>
                            <ol>
                                <li>Bei den auf Flyern, der Speisekarte und auf der Website genannten Preisen handelt es
                                    sich um
                                    Bruttopreise inklusive Serviceleistungen. Im Fall einer Bestellung über einen
                                    Drittanbieter wie z.B.
                                    Lieferando können zusätzliche Lieferkosten entstehen.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass Rabattcoupons nur zu bestimmten Zeiten und
                                    unter bestimmten
                                    Bedingungen verwendbar sind. Die Verwendungsmodalitäten sind auf den jeweiligen
                                    Coupons geregelt.
                                </li>
                                <li>Die Bezahlung der Produkte hat unmittelbar bei Abholung in der Betriebsstätte oder
                                    bei Lieferung am
                                    vereinbarten Lieferort zu erfolgen, sofern nichts anderes vereinbart ist. Wird die
                                    Zahlung nicht
                                    erbracht, befindet sich der Kunde automatisch in Verzug.
                                </li>
                                <li>Die Bezahlung erfolgt
                                    <ol type="a">
                                        <li>im Fall der telefonischen Bestellung sowie der Bestellung über die Website
                                            in bar, per Master
                                            Card, Visa Card, American Express, Discover Card, JCB Card, Diners Club
                                            International Card oder
                                            Paypal, sofern nichts anderes vereinbart ist;
                                        </li>
                                        <li>im Fall der Bestellung in der Betriebsstätte der Meisterpizza in bar, per
                                            Master Card, Visa
                                            Card, American Express, Discover Card, JCB Card, Diners Club International
                                            Card oder EC-Karte,
                                            sofern nichts anderes vereinbart ist.
                                        </li>
                                    </ol>
                                    Es obliegt dem Kunden zu gewährleisten, dass die zur Zahlung eingesetzten Geldkarten
                                    lesbar sind. Der
                                    Kunde wird darauf hingewiesen, dass im Falle der Bestellung über einen Drittanbieter
                                    wie z.B.
                                    Lieferando die Zahlungsarten abweichen können.
                                </li>
                                <li>Bestellt der Kunde über einen Drittanbieter und wird die Bestellung von diesem
                                    ausgeliefert, hat der
                                    Kunde die Rechnung gegenüber dem Drittanbieter zu begleichen. Erfolgte die Bezahlung
                                    gegenüber dem
                                    Drittanbieter, gilt der Anspruch der Meisterpizza gegenüber dem Kunden als erfüllt.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass die bestellten Produkte nicht an ihn
                                    ausgehändigt werden,
                                    sofern er die Leistung nicht bezahlt oder bezahlen kann. Der Kunde hat die bestellte
                                    Ware in diesem
                                    Fall in der Betriebsstätte innerhalb der Öffnungszeiten des gleichen Tages
                                    abzuholen. Kommt er dieser
                                    Verpflichtung nicht nach, bleibt der Anspruch der Meisterpizza gegenüber dem Kunden
                                    auf Bezahlung
                                    bestehen.
                                </li>
                                <li>Dem Kunden steht kein Zurückbehaltungsrecht zu.</li>
                                <li>Im Falle des Verzugs des Kunden gelten insbesondere hinsichtlich der
                                    Zinsvorschriften die
                                    gesetzlichen Bestimmungen.
                                </li>
                                <li>Die Meisterpizza ist berechtigt im Einzelfall eine angemessene Vorauszahlung zu
                                    verlangen.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 6 Mängelgewährleistung</h3>
                            <ol>
                                <li>Der Kunde hat bei Lieferung die Produkte unverzüglich auf Beschädigungen oder
                                    offensichtliche Fehler
                                    wie z.B. Falschlieferung zu untersuchen und diese umgehend entweder dem Lieferanten
                                    oder der
                                    Meisterpizza anzuzeigen, sofern es ihm möglich und zumutbar ist. Ist dem Kunden eine
                                    unverzügliche
                                    Untersuchung und Mängelanzeige nicht möglich, hat er diese nachzuholen, sobald das
                                    jeweilige Hindernis
                                    der Benachrichtigung beseitigt ist. Kommt der Kunde dem nicht nach, gilt die
                                    Leistung als
                                    vertragsgemäß erbracht.
                                </li>
                                <li>Ist die gelieferte Ware mangelhaft, kann der Kunde Nacherfüllung in Form der
                                    Nachlieferung
                                    verlangen. Sollte die Nachlieferung fehlschlagen, kann der Kunde vom Vertrag
                                    zurücktreten, den Preis
                                    mindern oder Schadensersatzansprüche geltend machen. Der Kunde wird darauf
                                    hingewiesen, dass er im
                                    Rahmen eines Schadensersatzes dazu verpflichtet ist, der Meisterpizza den Schaden
                                    nachzuweisen.
                                </li>
                                <li>Die Regelung des § 6 Abs. 2 gilt auch für die Fälle, in denen eine Nachlieferung
                                    unmöglich oder
                                    unzumutbar ist.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass er im Falle einer Nachlieferung die
                                    mangelhaften Produkte an
                                    die Meisterpizza herauszugeben hat, sofern nichts anderes vereinbart ist.
                                </li>
                                <li>Der Nacherfüllungsanspruch erlischt, wenn der Kunde die mangelhaften Produkte ganz
                                    oder insoweit
                                    teilweise verzehrt hat, sodas von einer Genehmigung des Mangels auszugehen ist.
                                </li>
                                <li>Die Gewährleistung ist ausgeschlossen, sofern ein zubereitetes Produkt nicht dem
                                    Geschmack des
                                    Kunden entspricht.
                                </li>
                                <li>Eine zusätzliche Garantie wird nicht gewährleistet.</li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 7 Haftung</h3>
                            <ol>
                                <li>Der Kunde wird darauf hingewiesen, dass die zu verarbeitenden Lebensmittel in Größe,
                                    Aussehen,
                                    Gewicht, Konsistenz, Geschmack, Geruch oder in sonstigen Beschaffenheitsmerkmalen
                                    voneinander
                                    abweichen können. Sofern diese Abweichungen im zumutbaren Bereich liegen, übernimmt
                                    die Meisterpizza
                                    keine Haftung.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass die angebenden Maße der Produkte Richtwerte
                                    sind. Die
                                    Zutaten werden vor der Zubereitung abgewogen, sodass es zu Abweichungen hinsichtlich
                                    der Größe der
                                    zubereiteten Produkte kommen kann. Für derartige Abweichungen wird keine Haftung
                                    übernommen.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass die auf Flyern, der Website oder für
                                    sonstige
                                    Produktdarstellungen verwendeten Bilder Symbolbilder und Serviervorschläge sind.
                                    Abweichungen der
                                    Bestellungen in Gestaltung, Farbe oder Ausführungen werden vorbehalten und es wird
                                    keine Haftung
                                    übernommen.
                                </li>
                                <li>Soweit ein Schaden des Kunden leicht fahrlässig verursacht wurde, haftet die
                                    Meisterpizza
                                    beschränkt: Die Haftung besteht insoweit nur bei Verletzung vertragswesentlicher
                                    Pflichten und ist der
                                    Höhe nach auf die bei Vertragsabschluss vorhersehbaren typischen Schäden begrenzt.
                                    Vertragswesentliche
                                    Pflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
                                    überhaupt erst
                                    ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und
                                    vertrauen darf.
                                </li>
                                <li>Befindet sich der Kunde im Annahmeverzug, haftet die Meisterpizza im Fall der
                                    Verschlechterung oder
                                    Untergang des Auftragsgegenstandes nur für grobe Fahrlässigkeit und Verschulden.
                                </li>
                                <li>Die Meisterpizza haftet nicht für die während des Verzuges durch Zufall eintretende
                                    Unmöglichkeit,
                                    es sei denn, dass der Schaden auch bei rechtzeitiger Leistung eingetreten wäre.
                                </li>
                                <li>Kann die Meisterpizza einen verbindlichen Liefertermin aufgrund von höherer Gewalt,
                                    Streik, Aufruhr
                                    oder unverschuldeter Betriebsstörung oder Betriebsschließung nicht einhalten,
                                    entstehen keine
                                    Schadensersatzansprüche.
                                </li>
                                <li>Die Haftungsbeschränkung ist ausgeschlossen, wenn die Meisterpizza einen Mangel
                                    arglistig
                                    verschweigt, es sich um eine Verletzung des Lebens, des Körpers oder der Gesundheit
                                    handelt, soweit
                                    diese Verletzung vorsätzlich oder grob fahrlässig herbeigeführt wurde, sowie bei
                                    Haftungen aus dem
                                    Produkthaftungsgesetz.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass die gelieferten Waren heiß sein können. Der
                                    Kunde hat
                                    eigenständig darauf zu achten, sich nicht zu verbrennen. Eine Haftung hierfür wird
                                    ausgeschlossen.
                                </li>
                                <li>Die Meisterpizza haftet nicht für auftretende Lebensmittelunverträglichkeiten des
                                    Kunden. Auf die
                                    Hinweise zu den <a routerLink="/shop/allergene" target="_blank">Allergenen und
                                        Zusatzstoffen</a> wird
                                    hingewiesen.
                                </li>
                                <li>Die Haftung für den Verlust von Geld, Wertpapieren (einschließlich Sparbüchern,
                                    Scheckheften,
                                    Scheck- und Kreditkarten), Kostbarkeiten oder anderen Wertsachen in der
                                    Betriebsstätte der
                                    Meisterpizza, die nicht ausdrücklich in Verwahrung genommen sind, ist -außer bei
                                    Vorsatz und grober
                                    Fahrlässigkeit- ausgeschlossen.
                                </li>
                                <li>Die Meisterpizza versichert, nur Zutaten und Produkte zu verwenden, deren
                                    Haltbarkeit noch nicht
                                    abgelaufen ist und die noch verzehrbar sind. Die Meisterpizza übernimmt keine
                                    Haftung für die
                                    natürliche Veränderung der Lebensmittel nach Übergabe an den Kunden.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 8 Vorzeitige Beendigung des Vertrages</h3>
                            <ol>
                                <li>Der Kunde kann bis zur Zubereitung der Produkte jederzeit den Vertrag kündigen. Die
                                    Meisterpizza ist
                                    berechtigt, die vereinbarte Vergütung abzüglich der ersparten Aufwendungen zu
                                    verlangen. Die Kündigung
                                    aus wichtigem Grund bleibt hiervon unberührt.
                                </li>
                                <li>Ist der Kunde Verbraucher und steht diesem ein Widerrufsrecht zu, kann er den
                                    Vertrag innerhalb von
                                    14 Tagen nach Vertragsschluss ohne die Angabe von Gründen widerrufen. Auf die
                                    Widerrufsbelehrung im
                                    Anhang wird verwiesen.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 9 Bestellung über Lieferando</h3>
                            <ol>
                                <li>Der Kunde wird darauf hingewiesen, dass bei der Nutzung des Portals die AGB von
                                    Lieferando
                                    zusätzlich zu den AGB der Meisterpizza gelten. Entsprechend Ziffer 3 der AGB von
                                    Lieferando gelten
                                    ihre AGB nur für den von Lieferando angebotenen Service. Die AGB von Lieferando
                                    können unter folgendem
                                    Link eingesehen werden: <a href="http://www.lieferando.de/agb"
                                                               target="_blank">www.lieferando.de/agb</a></li>
                                <li>Der Kunde wird darauf hingewiesen, dass der Vertrag bei einer Bestellung über
                                    Lieferando zwischen
                                    ihm und der Meisterpizza zustande kommt. Ein Vertrag mit Lieferando kommt nicht
                                    zustande.
                                </li>
                                <li>Der Kunde wird darauf hingewiesen, dass Lieferando die Kundendaten an die
                                    Meisterpizza zum Zwecke
                                    der Vertragsverarbeitung und -erfüllung weiterleitet. Die Daten werden von der
                                    Meisterpizza
                                    entsprechend der <a routerLink="/shop/datenschutz" target="_blank">Datenschutzvereinbarung</a>
                                    verarbeitet.
                                </li>
                                <li>Auf die Datenschutzbestimmungen von Lieferando werden hingewiesen. Diese sind unter
                                    <a
                                            href="http://www.lieferando.de/privacy-statement"
                                            target="_blank">www.lieferando.de/privacy-statement </a> einsehbar.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 10 Erfüllungsort</h3>
                            <ol>
                                <li>Im Falle der Selbstabholung in der Betriebsstätte der Meisterpizza ist der
                                    Erfüllungsort die
                                    Betriebsstätte der Meisterpizza.
                                </li>
                                <li>Im Falle einer Lieferung an den Kunden durch die Meisterpizza liegt der
                                    Erfüllungsort beim Kunden.
                                </li>
                                <li>Im Falle einer Bestellung über einen Drittanbieter wie z.B. Lieferando und einer
                                    Auslieferung durch
                                    einen Mitarbeiter von diesem Drittanbieter ist der Erfüllungsort die Betriebsstätte
                                    der Meisterpizza.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 11 Schlussbestimmungen; Salvatorische Klausel</h3>
                            <ol>
                                <li>Auf Vertrags- und sonstigen Geschäftsbeziehungen findet ausschließlich das Recht der
                                    Bundesrepublik
                                    Deutschland Anwendung.
                                </li>
                                <li>Sollten einzelne Bestimmungen der Allgemeinen Geschäftsbedingung oder individuelle
                                    Vereinbarungen
                                    des Vertrages unwirksam sein, wird die Wirksamkeit der übrigen Bestimmungen nicht
                                    berührt. Anstelle
                                    der unwirksamen Bestimmungen treten die gesetzlichen Regelungen.
                                </li>
                                <li>Ist der Vertragspartner Kaufmann, eine juristische Person des öffentlichen Rechts
                                    oder ein
                                    öffentlich-rechtliches Sondervermögen, ist Gerichtsstand für alle Streitigkeiten aus
                                    dem
                                    Vertragsverhältnis Dresden. Dasselbe gilt, wenn der Vertragspartner keinen
                                    allgemeinen Gerichtsstand
                                    in Deutschland oder einem EU-Mitgliedsstaat hat oder kein Wohnsitz oder gewöhnlicher
                                    Aufenthalt
                                    bekannt ist.
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <p>Dresden, den 08.07.2021</p>


                    <h2 class="text-center">Anhang:</h2>
                    <h3>Widerrufsbelehrung</h3>
                    <p>Widerrufsrecht</p>
                    <p>Ist der Kunde ein Verbraucher gem. § 13 BGB oder eine natürliche Person, die ein Rechtsgeschäft
                        zu einem
                        Zweck abschließt, der überwiegend weder seiner gewerblichen noch seiner selbständigen
                        beruflichen Tätigkeit
                        zugerechnet werden kann, steht ihm ein Widerrufsrecht gemäß § 312g i. V. m. § 355 BGB zu, wenn
                        die
                        Auftragserteilung außerhalb der Geschäftsräume des Auftragnehmers oder ausschließlich über
                        Fernabsatz
                        erfolgt.</p>
                    <p>Das Widerrufsrecht besteht nicht bei Verträgen hinsichtlich der Lieferung von Waren, die nicht
                        vorgefertigt
                        sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher
                        maßgeblich
                        ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind. Des
                        Weiteren
                        gilt das Widerrufsrecht nicht bei der Lieferung von Waren, die schnell verderben können; die aus
                        Gründen des
                        Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung
                        nach Lieferung
                        entfernt wurde; wenn die Waren nach Lieferung auf Grund ihrer Beschaffenheit untrennbar mit
                        anderen Gütern
                        vermischt wurden. Ansonsten gilt:</p>
                    <p>Widerrufsbelehrung</p>
                    <p>Sofern Ihnen ein Widerrufsrecht zusteht, können Sie den Vertrag ohne Angabe von Gründen
                        widerrufen. Die
                        Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht
                        auszuüben, müssen Sie
                        uns (Kontaktdaten siehe unten) mittels einer eindeutigen Erklärung (z.B. per Postversandt oder
                        per E-Mail)
                        über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Hierfür können Sie das
                        beigefügte
                        Muster-Widerrufsformular verwenden.</p>
                    <p>Zur Wahrung der Frist reicht es aus, wenn Sie die Mitteilung der Ausübung Ihres Widerrufsrechts
                        vor Ablauf
                        der o.g. Frist absenden.</p>
                    <p>Der Widerruf richtet sich an: </p>
                    <div class="row">
                        <div class="info1 col-sm-4 ">
                            <p>Im Fall eines schriftlichen Widerrufs</p>
                        </div>
                        <div class="info1 col-sm-4 ">
                            <p>Markus Hoffmann <br>Inh. Meisterpizza <br>{{ storeData?.street }}<br> {{ storeData?.zip }} {{ storeData?.city }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="info1 col-sm-4 ">
                            <p>Im Fall eines Widerrufs per E-Mail: </p>
                        </div>
                        <div class="info1 col-sm-4 "><a
                                href="mailto:{{ storeData?.email }}">{{ storeData?.email }}</a></div>
                    </div>
                    <div class="row">
                        <div class="info1 col-sm-4">
                            <p>Im Fall eines Widerrufs per E-Mail: </p>
                        </div>
                        <div class="info1 col-sm-4 "><a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a></div>
                    </div>

                    <p>Folgen des Widerrufs:</p>
                    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
                        haben,
                        einschließlich der Lieferkosten, unverzüglich und spätestens binnen 14 Tagen ab dem Tag
                        zurückzuzahlen, an
                        dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
                        Rückzahlung
                        verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt
                        haben, es sei
                        denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden Ihnen wegen
                        dieser
                        Rückzahlung Entgelte berechnet.</p>
                    <p>Haben Sie verlangt, dass die Leistung während der Widerrufsfrist beginnen soll, so haben Sie uns
                        einen
                        angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
                        Ausübung des
                        Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Leistungen im
                        Vergleich zum
                        Gesamtumfang der im Vertrag vorgesehenen Leistungen entspricht.
                    </p>


                    <div class="col-md-12">
                        <h3 class="text-center">Widerrufsformular</h3>
                        <p class="font-weight-bold">Wenn der bereits geschlossene Vertrag widerrufen werden soll, füllen
                            Sie bitte
                            dieses Formular aus und senden Sie es an uns zurück.</p>

                        <p class="info1 col-sm-4">Markus Hoffmann <br>Inh. Meisterpizza <br>{{ storeData?.street }}<br> {{ storeData?.zip }} {{ storeData?.city }}</p>

                        <p>Hiermit widerrufe(n) ich/wir dem von mir/uns abgeschlossenen Vertrag über die folgende
                            Bestellung:</p>
                        <div class="row">
                            <div class="info1 col-sm-3 ">
                                <p>bestellt am <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Vor- und Zuname <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Anschrift <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Telefonnummer <br>_____________________</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="info1 col-sm-3 ">
                                <p>Ort <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Datum <br>_____________________</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </ng-container>
</div>
