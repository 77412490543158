<div class="static-page-layout">
  <ng-container>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
             [style.backgroundImage]="'url(' + headerbild_MP + ')'">
          <h1 class="static-page-title">Newsletter</h1>
        </div>

        <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
             [style.backgroundImage]="'url(' + headerbild_PB + ')'">
          <h1 class="static-page-title">Newsletter</h1>
        </div>
      </div>

      <div class="col-md-12">
        <div class="center-content-narrow">
          <h2 class="mp-font-medium-yellow text-center mb-1">Wir bedauern, dass du uns verlässt.</h2>
          <p class="text-center font-wight-bold mb-0">
            Du hast deine Abmeldung bestätigt.
          </p>
          <p class=" mt-3 text-center font-wight-bold mb-0">Dein Meisterpizza Team</p>
        </div>
      </div>
    </div>
  </ng-container>


</div>
