/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newsletter-abmelden.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./newsletter-abmelden.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../services/main.service";
import * as i6 from "@angular/router";
var styles_NewsletterAbmeldenComponent = [i0.styles];
var RenderType_NewsletterAbmeldenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsletterAbmeldenComponent, data: {} });
export { RenderType_NewsletterAbmeldenComponent as RenderType_NewsletterAbmeldenComponent };
function View_NewsletterAbmeldenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper headerBild"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletter"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerbild_MP) + ")"); _ck(_v, 0, 0, currVal_0); }); }
function View_NewsletterAbmeldenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper headerBild"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletter"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerbild_PB) + ")"); _ck(_v, 0, 0, currVal_0); }); }
export function View_NewsletterAbmeldenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "static-page-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterAbmeldenComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterAbmeldenComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "center-content-narrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "mp-font-medium-yellow text-center mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wir bedauern, dass du uns verl\u00E4sst."])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "text-center font-wight-bold mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Du hast deine Abmeldung best\u00E4tigt. "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [["class", " mt-3 text-center font-wight-bold mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dein Meisterpizza Team"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.storeData == null) ? null : _co.storeData.master) === 1); _ck(_v, 5, 0, currVal_0); var currVal_1 = (((_co.storeData == null) ? null : _co.storeData.master) === 3); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_NewsletterAbmeldenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-newsletter-abmelden", [], null, null, null, View_NewsletterAbmeldenComponent_0, RenderType_NewsletterAbmeldenComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewsletterAbmeldenComponent, [i4.HttpClient, i5.MainService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterAbmeldenComponentNgFactory = i1.ɵccf("app-newsletter-abmelden", i3.NewsletterAbmeldenComponent, View_NewsletterAbmeldenComponent_Host_0, {}, {}, []);
export { NewsletterAbmeldenComponentNgFactory as NewsletterAbmeldenComponentNgFactory };
