import {Component, OnDestroy, OnInit, EventEmitter, Output, Inject, Input} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {FrontendService} from '../../../../services/frontend.service';
import {ArticleGroup} from '../../../../../_classes/ArticleGroup';
import {Prices} from '../../../../../_classes/Prices';
import {CartService} from '../../../../services/cart.service';
import {MainService} from '../../../../services/main.service';
import {MatSnackBar} from '@angular/material';
import { Article } from 'src/_classes/Article';
import {DOCUMENT} from '@angular/common';
import {PageScrollService} from 'ngx-page-scroll-core';

@Component({
    selector: 'app-add-article',
    templateUrl: './add-article.component.html',
    styleUrls: ['./add-article.component.scss']

})
export class AddArticleComponent implements OnInit, OnDestroy {
    // public stuff  - - - - - - - - - - - - - - - -
    men_article_list: any[] = [];
    show_tab: number = 0;
    cart_item_amount: any[] = [];
    image_url: string;

    @Input() addedArticles: any[];
    @Input() set artForMenu(artForMenu: any[]) { this.men_article_list = artForMenu; this.initCartItemAmount() };
    @Output() tempAddedArticlesEmitter = new EventEmitter<{article: Article, index: number}>();
    @Output() tempRemovedArticlesEmitter = new EventEmitter<{index: number, categoryID: number}>();

    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];
    private cart_items: ArticleGroup[] = [];        // all current articles in the cart

    /* show the info box for the ingredient */
    showInfoBox = false;
    /* holds the current infobox ingredient information */
    infoBoxIngredient: any;


    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private cart_service: CartService,
        private main_service: MainService,
        public snackBar: MatSnackBar,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {
        // this.initCartItemAmount();
        // if (typeof this.articlesForMenu != undefined) {
        //     this.men_article_list = this.articlesForMenu;
        //     this.initCartItemAmount();
        // } else {
        //     this.subscriptions.push(this.frontend_service.getArticleForMenu().subscribe(
        //         (data: any[]) => {
        //             this.men_article_list = data;
        //             this.initCartItemAmount();
        //         }));
        // }
        this.subscriptions.push(this.cart_service.article_group_list_observable.subscribe(
            (data: any[]) => {
                this.cart_items = data;
            }));
            this.subscriptions.push(this.cart_service.changedTempArticlesObservable.subscribe(
                () => {
                    this.initCartItemAmount();
                }));
        this.image_url = this.main_service.image_url;

        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;

        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767) {
        //     setTimeout(function () {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500);
        // }
    }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    onLog() {

    }

    onSub(index_cat: number, index_article: number) {
        // find the index
        let index: number = -1;

        for (let i = 0; i < this.cart_items.length; i++) {
            if (this.cart_items[i].article.id == this.men_article_list[index_cat].article[index_article].id) {
                index = i;
                break;
            }
        }
        if (index == -1 || index >= this.cart_items.length) {
            console.error('Article not Found.');
        }

        this.cart_service.decreseAmount(index);
        this.cart_item_amount[index_cat][index_article]--;

        // show overlay for item
        document.getElementById('item-removed-' + index_article).classList.remove('item-removed-hidden');

        setTimeout(() => {
            document.getElementById('item-removed-' + index_article).classList.add('item-removed-hidden')
        }, 1000);
    }

    onAdd(index_cat: number, index_article: number, index_size: number) {
        const pickupPrice = this.men_article_list[index_cat].article[index_article].price[index_size].price - this.men_article_list[index_cat].article[index_article].price[index_size].pickup_saving;
        const price: Prices = new Prices(this.men_article_list[index_cat].article[index_article].price[index_size].price,
            this.men_article_list[index_cat].article[index_article].price[index_size].happy_hour_price,
            this.men_article_list[index_cat].article[index_article].price[index_size].lunch_price,
            pickupPrice,
            0,
            this.men_article_list[index_cat].article[index_article].price[index_size].mega_deal_price,
            0);
        const new_group = new ArticleGroup(this.men_article_list[index_cat].article[index_article], price, index_size, -1, []);

        this.cart_service.addNewSingleArticleGroup(new_group);
        this.openSnackBar('in den Warenkorb gelegt: 1x ', this.men_article_list[index_cat].article[index_article].name);

        this.cart_item_amount[index_cat][index_article]++;

        // show overlay for item
        document.getElementById('item-added-' + index_article).classList.remove('item-added-hidden');

        setTimeout(() => {
            document.getElementById('item-added-' + index_article).classList.add('item-added-hidden')
        }, 1000);
    }

    onAddTempArticle(article: Article, index: number) {
        // emit values to parent component
        this.tempAddedArticlesEmitter.emit({article, index});
        this.initCartItemAmount();
        // show overlay for item
        document.getElementById('item-added-' + index).classList.remove('item-added-hidden');

        setTimeout(() => {
            document.getElementById('item-added-' + index).classList.add('item-added-hidden')
        }, 1000);

        // const scroller = this.pageScrollService;
        // const scrollerDoc = this.document;
        // /*let target = ".article-price-wrap";*/
        // let target = ".target-oben";
        // let offset = this.positionAbfrage();
        // if (window.screen.availWidth > 767) {
        //     setTimeout( function() {
        //         scroller.scroll({
        //             document: scrollerDoc,
        //             // scrollTarget: '.article-price-wrap',
        //             scrollTarget: target,
        //             scrollOffset: offset,
        //             duration: 600
        //         });
        //     }, 500 )
        // }
        
    }

    onRemoveTempArticle(index: number, categoryID: number) {
        // emit values to parent component
        this.tempRemovedArticlesEmitter.emit({index, categoryID});
        this.initCartItemAmount();
        // show overlay for item
        document.getElementById('item-removed-' + index).classList.remove('item-removed-hidden');

        setTimeout(() => {
            document.getElementById('item-removed-' + index).classList.add('item-removed-hidden')
        }, 1000);
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getProductByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxIngredient = data;
            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }

    private subIngIsDisabled(i_cat, i_ing) {


        return false;

    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

    private initCartItemAmount() {
        // delete all cartitems with added ings, cause they are not relevant
        // const cart_items = this.cart_items;
        const cart_items = this.addedArticles;

        // iterate through men_article categories
        let newList = [];
        for (let i_men_art_cat = 0; i_men_art_cat < this.men_article_list.length; i_men_art_cat++) {
            if (typeof this.men_article_list[i_men_art_cat].article === 'undefined' || !this.men_article_list[i_men_art_cat].article[0]) {
                continue;
            }
            // iterate through articles of article category
            this.cart_item_amount[i_men_art_cat] = [];
            for (let i_men_art = 0; i_men_art < this.men_article_list[i_men_art_cat].article.length; i_men_art++) {
                let no_match = true;
                // ind matching cart_item + save amount
                for (let i_cart_art = 0; i_cart_art < cart_items.length; i_cart_art++) {
                    const men_art_id = this.men_article_list[i_men_art_cat].article[i_men_art].id;
                    const cart_art_id = cart_items[i_cart_art].id;

                    if (men_art_id == cart_art_id) {
                        this.cart_item_amount[i_men_art_cat][i_men_art] += 1;
                        no_match = false;
                    }
                }

                // if no math -> amount = 0
                if (no_match) {
                    this.cart_item_amount[i_men_art_cat][i_men_art] = 0;
                }
            }
        }
    }

    private openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000
        });
    }


    positionAbfrage() {
        let header = this.document.getElementById('header');
        /*console.log("HEADER", header);*/
        let hoehe = header.clientHeight;
        return hoehe;
    }
}
