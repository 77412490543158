<!--<button class="log-button" (click)="onLog()">log</button>-->

<div class="added-ingredients-wrapper">

    <!-- <span *ngIf="amount[i_cat][i_ing]>0"> {{amount[i_cat][i_ing]}} x {{ing.name}}</span> -->
    <!-- <ng-container *ngIf="cart_item_amount[i_art_cat][i_art] > 0">{{cart_item_amount[i_art_cat][i_art]}}x</ng-container> -->

</div>

<h4 class="add-ingredient-headline text-center">Mach´s als Menü</h4>

<!--Tabs erstellen und anzeigen   Line 16 - btn-block btn-table-->

<div id="btn-table" class="btn-group" role="group" aria-label="Basic example">
    <ng-container *ngFor="let article_cat of men_article_list, let i_art_cat = index">
        <button id="btn-title" type="button" class="btn btn-block btn-table" [class.active]="show_tab == i_art_cat" (click)="show_tab = i_art_cat">
            {{article_cat.name}}
        </button>
    </ng-container>
</div>

<div class="ingredients-wrapper ingredients-wrapper-step-2 container-fluid">

    <div class="row bg-white">
        <ng-container *ngFor="let article_cat of men_article_list, let i_art_cat = index">
            <ng-container *ngFor="let article of article_cat.article, let i_art = index">
                <ng-container *ngIf="show_tab == i_art_cat">

                    <div class="ingredient-item col-6 col-md-4 col-lg-3 d-flex flex-column justify-content-between">
                        <div>
                            <div class="item-added item-added-hidden" id="item-added-{{i_art}}">
                                <p>hinzugefügt</p>
                            </div>
    
                            <div class="item-removed item-removed-hidden" id="item-removed-{{i_art}}">
                                <p>entfernt</p>
                            </div>
    
                            <div class="ingredient-title">
                                <span [innerHTML]="article?.name"></span>
                                <br>
                                <small *ngFor="let ing of article.ingredients, let last_ing = last">
                                    {{ing.name}}
                                    <ng-container *ngFor="let marking of ing.markings, let last = last">
                                        <sup>{{marking.abbreviation}}</sup>
                                        <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                                    </ng-container>
                                    <sup *ngFor="let allergene of ing.allergene, let last_all=last">{{allergene}}
                                        <ng-container *ngIf="!last_all">,</ng-container>
                                    </sup>
                                    <ng-container *ngIf="!last_ing">,</ng-container>
                                </small>
                                <span class="infobox-link" (click)="onOpenInfoBox(article.id)"></span>
                            </div>
                        </div>
                        <div>
                            <div class="ingredient-image d-flex flex-column justify-content-center  align-items-center">
                                <!-- <img *ngIf="article.image" src="{{image_url}}/admin/products/{{article.id}}/{{article.image.filename}}" alt="{{article.name}}" class="img-fluid" /> -->
                                <!-- <img *ngIf="!article.image" src="{{image_url}}/admin/default/Pizzen_600x600px_Spaghetti.png" alt="{{article.name}}" class="img-fluid" /> -->
                                <img  *ngIf="article.image && article.image.png" src="{{image_url}}{{article.image.png[5].image}}" alt="{{article.name}}" class="img-fluid"/>
    
                            </div>
    
                            <div class="ingredient-price menu my-2" *ngIf="onLunch">
                                {{article.price[0]?.lunch_price |number:'1.2-5'}}€
                            </div>
    
                            <div class="ingredient-price menu my-2" *ngIf="!onLunch">
                                {{article.price[0]?.price |number:'1.2-5'}}€
                            </div>
    
                            <div class="ingredient-add-remove">
                                <!-- buttons -->
                                <!-- <button type="button" class="mp-btn-minus" (click)="onSub(i_art_cat, i_art)"></button>
                                <button type="button" class="mp-btn-plus" (click)="onAdd(i_art_cat, i_art, 0)"></button> -->
                                <button type="button" class="mp-btn-minus"  [disabled]="cart_item_amount[i_art_cat][i_art]==0" (click)="onRemoveTempArticle(i_art, article.category_id)">
                                    <svg-icon src="assets/icons/icon_minus.svg" [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                </button>
                                <button type="button" class="mp-btn-plus" (click)="onAddTempArticle(article, i_art)">
                                    <svg-icon src="assets/icons/icon_plus.svg" [svgStyle]="{ 'width.px':30,'fill':'currentColor' }"></svg-icon>
                                </button>
                                <!-- /buttons -->
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- begin: popup -->
<ng-container *ngIf="showInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <div class="title-mp-dialog">
                Allergene & Zusatzstoffe
            </div>
            <div class="cont-mp-dialog">
                <h3 class="mp-font-large-yellow">{{ infoBoxIngredient.name }}</h3>
                <span [innerHTML]="infoBoxIngredient.description"></span>
                <ng-container *ngIf="infoBoxIngredient.description_addition" >
                    <span class="description-addition" [innerHTML]="infoBoxIngredient.description_addition"></span>
                </ng-container>
                <br><br>
                <h4>Zusatzstoffe:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxIngredient.markings">
                        <li *ngIf="marking.type === 'Zusatzstoff'" >{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>Allergene:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxIngredient.markings">
                        <li *ngIf="marking.type === 'Allergen'" >{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>BITTE BEACHTE:</h4>
                <p>Die Zusatzstoffe und Allergene können sich beim Hinzufügen oder Wechseln von Zutaten ändern.</p>
                <br>
                <div class="btn-mp-dialog text-center">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="showInfoBox = false">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup -->
