import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';

@Injectable()

export class PaymentsService {

    private payments: any;

    constructor(
        private http: HttpClient,
        private main_service: MainService
    ){

    }


    load() : Promise <any> {

        let typeDeliv = 'notSet';
        if (sessionStorage.getItem('pickup') ) {
            if (sessionStorage.getItem('pickup') == '1') {
                typeDeliv = 'pickup';
            } else if (sessionStorage.getItem('pickup') == '0') {
                typeDeliv = 'delivery';
            }
        }

        return this.http.get(this.main_service.getFrontendUrl() + '/payments?deliveryType=' + typeDeliv)
            .toPromise();


    }

}
