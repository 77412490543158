import { Inject, Injectable } from '@angular/core';
import { User } from "../../_classes/User";
import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { DOCUMENT } from "@angular/common";
import { FrontendService } from "./frontend.service";
import { AuthService } from "./auth.service";
import { CartService } from "./cart.service";
import {DateService} from './date.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {


    //variable stuff  - - - - - - - - - - - - - - - -

    // user observable stuff
    user: User = new User();
    user_subject = new ReplaySubject<User>(1);
    user_observable = this.user_subject.asObservable();

    // user observable stuff
    userLoadedFromCache = false;
    userLoadedFromCacheSubject = new ReplaySubject<boolean>(1);
    userLoadedFromCacheObservable = this.userLoadedFromCacheSubject.asObservable();

    // token observable stuff
    private token: string = '';
    private token_subject = new ReplaySubject<string>(1);
    token_observable = this.token_subject.asObservable();

    private addressValid = false;
    private addressValidSubject = new ReplaySubject<boolean>(1);
    addressValidObservable = this.addressValidSubject.asObservable();

    private streetValidSubject = new ReplaySubject<boolean>(1);
    streetValidObservable = this.streetValidSubject.asObservable();

    private zipValidSubject = new ReplaySubject<boolean>(1);
    zipValidObservable = this.zipValidSubject.asObservable();


    private cookie;

    user_set_cookie: boolean = false;
    user_has_account: boolean = false;
    cookie_jwt_state: number = 0;

    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        @Inject(DOCUMENT) private document: any,
        private frontend_service: FrontendService,
        private cart_service: CartService,
        private dateService: DateService
    ) {
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        //init functions
        // this.initUserData();
        this.user_subject.next(this.user);
        this.userLoadedFromCacheSubject.next(this.userLoadedFromCache);
        this.token_subject.next(this.token);

        this.loadUserSessionStorage();
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    // load cart session storage
    loadUserSessionStorage() {
        if (sessionStorage.getItem('tempUser')) {
            const loadedtempUser = JSON.parse(sessionStorage.getItem('tempUser'));
            this.user = loadedtempUser;
            this.user_subject.next(this.user);
            this.userLoadedFromCache = true;
            this.userLoadedFromCacheSubject.next(this.userLoadedFromCache);
        }
    }

    setUserAdressData(plz: string, city: string, district: string, street: string, street_number: string) {
        // this.user.setAdress(plz, city, district, street, street_number);
        this.user.adress.plz = plz;
        this.user.adress.city = city;
        this.user.adress.district = district;
        this.user.adress.street = street;
        this.user.adress.street_number = street_number;
        this.user_subject.next(this.user);

    }

    setUserData(title: string, fam_name: string, fir_name: string, no_marketing: boolean, phone: string, mail: string, ) {
        this.user.title = title;
        this.user.fam_name = fam_name;
        this.user.fir_name = fir_name;
        this.user.no_marketing = no_marketing;
        this.user.phone = phone;
        this.user.mail = mail;
        this.user.has_acc = true;
        this.setMinVal();
    }

    //function to print adress in a pretty way
    userAdressPrettyPrint() {
        let adress: string[] = [];
        adress.push(this.user.title + ' ' + this.user.fir_name + ' ' + this.user.fam_name);
        if (this.user.adress.firma != '') {
            adress.push('Firma: ' + this.user.adress.firma + '/' + this.user.adress.department);
        }
        adress.push(this.user.adress.street.replace('%', ' ') + ' ' + this.user.adress.street_number);
        if (this.user.adress.district == '') {
            adress.push(this.user.adress.plz + ' ' + this.user.adress.city.replace('%', ' '));
        } else {
            adress.push(this.user.adress.plz + ' ' + this.user.adress.city.replace('%', ' ') + ' Ortsteil: ' + this.user.adress.district);
        }

        return adress;
    }

    clearCurrentUser() {
        this.user.fir_name = '';
        this.user.fam_name = '';
        this.user.adress.city = '';
        this.user.adress.district = '';
        this.user.adress.street = '';
        this.user.adress.street_number = '';
        this.user.adress.plz = '';
        this.user.adress.etage = '';
        this.user.title = '';
        this.user.mail = '';
        this.user.phone = '';
        this.cart_service.setMinVal(null);
    }

    clearAdressCurrentUser() {
        this.user.adress.city = '';
        this.user.adress.district = '';
        this.user.adress.street = '';
        this.user.adress.street_number = '';
        this.user.adress.plz = '';
        this.user.adress.etage = '';
        this.cart_service.setMinVal(null);
        this.user_subject.next(this.user);
    }

    onReInitUserData() {
        this.initUserData();
    }

    setMinVal() {
        this.frontend_service.getMinVal(this.user.adress.plz, this.user.adress.district).subscribe(
            (data: string) => {
                this.cart_service.setMinVal(data);
            });
    }

    setAddressValid(addressValid: boolean) {
        this.addressValidSubject.next(addressValid);
    }

    getAddressValid() {
        return this.addressValidSubject;
    }

    setStreetValid(streetValid: boolean) {
        this.streetValidSubject.next(streetValid);
    }

    getStreetValid() {
        return this.streetValidSubject;
    }

    setZipValid(zipValid: boolean) {
        this.zipValidSubject.next(zipValid);
    }

    getZipValid() {
        return this.zipValidSubject;
    }

    getEMail() {
        return this.user.mail;
    }

    changeMail(new_mail) {
        this.user.mail = new_mail;
        this.user_subject.next(this.user);
    }

    //JWT funktions - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    deleteToken() {
        this.updateToken('');
        this.createCookieEntry('jwt', '');
    }

    updateToken(token: string) {
        this.token = token;
        this.createCookieEntry('jwt', token);
        this.token_subject.next(this.token);
    }

    //cookie funktions - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //saves all necessary infos in the coookie - should only called when user allowed
    saveUserToCookie() {
        // ACHTUNG: Nutzerdaten im Cookie speichern ist jetz nich wirklich clever. Das muss unbedingt noch umgebaut
        // werden! Cookies können gestohlen werden! Beispielumsetzung:
        // https://www.php-einfach.de/experte/php-codebeispiele/loginscript/angemeldet-bleiben/
        this.createCookieEntry('f_name', this.user.fir_name);
        this.createCookieEntry('l_name', this.user.fam_name);
        this.createCookieEntry('city', this.user.adress.city);
        this.createCookieEntry('distr', this.user.adress.district);
        this.createCookieEntry('street', this.user.adress.street);
        this.createCookieEntry('hnr', this.user.adress.street_number);
        this.createCookieEntry('plz', this.user.adress.plz);
        this.createCookieEntry('floor', this.user.adress.etage);
        this.createCookieEntry('title', this.user.title);
        this.createCookieEntry('mail', this.user.mail);
        this.createCookieEntry('phone', this.user.phone);
        this.createCookieEntry('ud_set', 'true');
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    private initUserData() {
        // check if token is set
        let jwt = this.getCookieEntry('jwt');

        if (jwt != false) {
            // fetch user data from BE + init user object with request data
            this.frontend_service.fetchAdressData(jwt)
                .subscribe(data => {
                    if (data != 0) {
                        this.token = jwt;
                        this.token_subject.next(this.token);
                        this.user_has_account = true;
                        this.loadUserFromResponse(data);
                        this.setMinVal();
                    } else {
                        //token löschen
                        this.deleteToken();
                    }
                })
        }

        if (!jwt) {
            // if not: check if user data is set in the cookie
            if (this.getCookieEntry('ud_set') != false) {
                // init user object with cookie data
                this.loadUserFromCookie();
                this.user_set_cookie = true;
                this.setMinVal();
            }
        }
    }


    private loadUserFromCookie() {
        this.user.fir_name = this.getCookieEntry('f_name');
        this.user.fam_name = this.getCookieEntry('l_name');
        this.user.adress.city = this.getCookieEntry('city');
        this.user.adress.district = this.getCookieEntry('distr');
        this.user.adress.street = this.getCookieEntry('street');
        this.user.adress.street_number = this.getCookieEntry('hnr');
        this.user.adress.plz = this.getCookieEntry('plz');
        this.user.adress.etage = this.getCookieEntry('floor');
        this.user.title = this.getCookieEntry('title');
        this.user.mail = this.getCookieEntry('mail');
        this.user.phone = this.getCookieEntry('phone');

    }

    private loadUserFromResponse(response: any) {
        this.user.fir_name = response.first_name;
        this.user.fam_name = response.last_name;
        this.user.adress.city = response.city;
        this.user.adress.district = response.district;
        this.user.adress.street = response.street;
        this.user.adress.street_number = response.house_no;
        this.user.adress.plz = response.zip;
        this.user.adress.etage = response.add_adress;
        this.user.title = response.title;
        this.user.mail = response['e-mail'];
        this.user.phone = response.phone_number;
        this.setMinVal();

    }

    private createCookieEntry(name: string, val: string) {
        let date = this.dateService.newDate();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        let delete_cookie = "expires=" + date.toUTCString();
        this.document.cookie = name + '=' + val + ';' + delete_cookie;
    }

    private getCookieEntry(entry: string) {
        let cookie_array = this.document.cookie.split(';');
        let searched_entry = entry + '=';

        //iterate through the cookie-fields
        for (let i = 0; i < cookie_array.length; i++) {
            let cookie_elem = cookie_array[i];

            //delete unneccessary spaces
            while (cookie_elem.charAt(0) === ' ') {
                cookie_elem = cookie_elem.substring(1);
            }
            //get data substring
            if (cookie_elem.indexOf(searched_entry) === 0) {
                return cookie_elem.substring(searched_entry.length, cookie_elem.length);
            }
        }
        return false;

    }


}
